import "./style.css"
import { faPrint, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { Input } from "../Input"
import { convertDateToBr, dateNow, getHourMin } from "../../utils/dateProvider"
// import { api } from "../../services/api"
import { LoadAdm } from "../LoadAdm"
import { getApiClient } from "../../utils/getApiClient"

type IProps = {
  closeModal: () => void
  empresaSelected: string
}

export function ModalControleRelVendas({ closeModal, empresaSelected }: IProps) {

  const [dataIni, setDataIni] = useState(convertDateToBr(dateNow()));
  const [dataFin, setDataFin] = useState(convertDateToBr(dateNow()));

  const [horaIni, setHoraIni] = useState(getHourMin(dateNow()));
  const [horaFin, setHoraFin] = useState(getHourMin(dateNow()));

  const [loading, setLoading] = useState(false);

  const handleImprimir = async () => {
    setLoading(true);

    try {

      const formData = new FormData();
      formData.append("data_ini", dataIni);
      formData.append("data_fin", dataFin);

      formData.append("hora_ini", horaIni);
      formData.append("hora_fin", horaFin);

      formData.append("tipo", "A4");

      const response = await getApiClient(empresaSelected).post(`${empresaSelected}/rel-vendas`, formData, {
        responseType: "arraybuffer",
        headers: { Accept: "application/pdf" }
      });

      const file = new Blob([response.data], { type: 'application/pdf' })

      const fileURL = URL.createObjectURL(file)

      const pdfWindow = window.open()

      if (pdfWindow !== null)
        pdfWindow.location.href = fileURL

    } catch (err) {
      console.log(err);
      alert("Erro ao gerar relatório. Tente novamente mais tarde.")
    }

    setLoading(false);
  }

  return (
    <div id="mdControleRelVendas">
      <div className="header">
        <h3>Relatório de Vendas</h3>
        <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer" }} onClick={closeModal} />
      </div>

      {loading && (
        <LoadAdm txtLoading="Gerando Relatório..." options={{ height: 150, width: 150 }} />
      )}

      <div className="content">

        {!loading && (
          <>

            <div>
              <span>
                <p style={{ fontWeight: "600" }}>Data Inicial:</p>
                <Input
                  mask="date"
                  value={dataIni}
                  inputMaskChange={setDataIni}
                  placeholder="dd/mm/aaaa"
                  maxLength={10}
                />
              </span>
              <span>
                <p style={{ fontWeight: "600" }}>Hora Inicial:</p>
                <Input
                  mask="clock"
                  value={horaIni}
                  inputMaskChange={setHoraIni}
                  placeholder="hh:mm"
                  maxLength={5}
                />
              </span>
            </div>

            <div>
              <span>
                <p style={{ fontWeight: "600" }}>Data Final:</p>
                <Input
                  mask="date"
                  value={dataFin}
                  inputMaskChange={setDataFin}
                  placeholder="dd/mm/aaaa"
                  maxLength={10}
                />
              </span>
              <span>
                <p style={{ fontWeight: "600" }}>Hora Final:</p>
                <Input
                  mask="clock"
                  value={horaFin}
                  inputMaskChange={setHoraFin}
                  placeholder="hh:mm"
                  maxLength={5}
                />
              </span>
            </div>

            <button type="button" onClick={() => handleImprimir()}>
              <FontAwesomeIcon icon={faPrint} />
              IMPRIMIR
            </button>
          </>
        )}

      </div>

    </div>

  )
}