import { useState, useEffect, KeyboardEvent } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faAngleDoubleRight, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

import { useCache } from "../../hooks/useCache";

import { Load } from "../../components/Load";
import { Input } from "../../components/Input";

import "./style.css";
// import { api } from "../../services/api";
import { EmpresaParams, ClienteProps } from "../../global/types";
import { maskPhone } from "../../utils/masks";
import { getApiClient } from "../../utils/getApiClient";

type ParamsProps = {
  closeModal: () => void;
  abreModalPedidos: (idCliente?: number, telefone?: string, nome?: string) => void;
};

export function ModalLogin({ closeModal, abreModalPedidos }: ParamsProps) {
  const { configCores, cliente, gravaCliente, clearCliente } = useCache();
  const { codCliente } = useParams() as EmpresaParams;

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [mostraTelefone, setMostraTelefone] = useState(false);
  const [mostraSenha, setMostraSenha] = useState(false);
  const [mostraCadSenha, setMostraCadSenha] = useState(false);

  const [tempCliente, setTempCliente] = useState<ClienteProps>({} as ClienteProps);

  const [phone, setPhone] = useState("");
  const [senha, setSenha] = useState("");

  const [newSenha, setNewSenha] = useState("");
  const [confirmSenha, setConfirmSenha] = useState("");

  const handleGravaCliente = () => {
    abreModalPedidos(tempCliente.id, maskPhone(`${tempCliente.telefone}`), tempCliente.nome);
  }

  const handleKeyPhone = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter")
      handleEnviarTelefone();
  }

  const handleKeySenha = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter")
      handleVerificaSenha();
  }

  const handleEnviarTelefone = async (autoPhone?: string) => {
    setLoading(true);
    setTxtLoading("Verificando cadastro...");

    const tempPhone = autoPhone ? autoPhone : phone;

    if (tempPhone.length < 14)
      return alert("Preencha corretamente o campo Celular/Telefone");

    const phoneNumber = tempPhone.replace(/[^0-9]/g, "");

    await getApiClient(codCliente)
      .get(`${codCliente}/clientes?telefone=${phoneNumber}`)
      .then((res) => {
        if (!res.data)
          throw new Error("Servidor indisponível");

        const resClientes = res.data as ClienteProps[];

        if (resClientes.length <= 0)
          return alert("Cliente não cadastrado em nosso banco de dados. Faça seu primeiro pedido para se cadastrar.");

        const rsCliente = resClientes[0];
        setTempCliente(rsCliente);

        if (!rsCliente.nome || rsCliente.nome.length <= 0) {
          alert("Cliente não cadastrado em nosso banco de dados. Faça seu primeiro pedido para se cadastrar.");
        } else {
          setMostraTelefone(false);

          if (!rsCliente.senha || rsCliente.senha.length <= 0) {
            setMostraSenha(false);
            setMostraCadSenha(true);
          } else {
            setMostraSenha(true);
            setMostraCadSenha(false);
          }
        }
      })
      .catch((err) => {
        alert("Erro ao recuperar dados do cliente. Tente novamente mais tarde");
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
      });
  }

  const handleCadastraSenha = async () => {
    if (newSenha !== confirmSenha)
      return alert("As senha não conferem.");

    setLoading(true);
    setTxtLoading("Cadastrando senha...");

    let liberado = false;

    await getApiClient(codCliente)
      .post(`${codCliente}/app_add_cliente`, {
        nome: tempCliente.nome,
        senha: newSenha,
        telefone: tempCliente.telefone
      })
      .then((res) => {
        if (!res.data)
          throw new Error("Servidor indisponível");

        liberado = true;
      })
      .catch(() => {
        alert("Erro ao cadastrar senha. Tente novamente mais tarde.");
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");

        if (liberado)
          handleGravaCliente();
      });
  }

  const handleVerificaSenha = () => {
    if (senha !== tempCliente.senha)
      return alert("Senha inválida");

    handleGravaCliente();
  }

  const handleSair = () => {
    clearCliente();
    setMostraSenha(false);
    setMostraCadSenha(false);
    setMostraTelefone(true);
  };

  useEffect(() => {
    let mounted = true

    if (mounted) {
      if (!cliente.telefone || cliente.telefone.length <= 0) {
        setMostraTelefone(true);
        setMostraSenha(false);
      } else {
        setPhone(cliente.telefone);
        handleEnviarTelefone(cliente.telefone);
      }
    }

    return function cleanup() {
      mounted = false;
    }
  }, [cliente]);

  return (
    <div id="modal-login">
      <div className="header" style={{ backgroundColor: configCores?.cor_1, color: configCores?.cor_fonte }}>
        <h3>Login</h3>
        <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer" }} onClick={closeModal} />
      </div>

      <div className="content">

        {loading && (
          <Load txtLoading={txtLoading} options={{ width: 120, height: 120 }} />
        )}

        {!loading && (
          <>
            {!mostraSenha && mostraTelefone && (
              <>
                <p>Telefone:</p>
                <Input
                  value={phone}
                  mask="phone"
                  maxLength={15}
                  placeholder="Digite seu telefone"
                  inputMaskChange={setPhone}
                  onKeyUp={handleKeyPhone}
                  type="tel"
                />

                <button type="button" onClick={() => { handleEnviarTelefone() }}>
                  <FontAwesomeIcon icon={faAngleDoubleRight} />
                  AVANÇAR
                </button>
              </>
            )}

            {!mostraTelefone && mostraSenha && (
              <>
                <p style={{ fontWeight: "500", fontSize: "16px" }}>
                  Cliente: {tempCliente.nome}
                  <FontAwesomeIcon icon={faSignOutAlt} style={{ marginLeft: "5px", cursor: "pointer" }} onClick={handleSair} />
                </p>

                <p>Senha:</p>
                <Input
                  value={senha}
                  mask="none"
                  maxLength={100}
                  placeholder="Digite sua senha"
                  inputMaskChange={setSenha}
                  type="password"
                  onKeyUp={handleKeySenha}
                />

                <button type="button" onClick={handleVerificaSenha}>
                  <FontAwesomeIcon icon={faAngleDoubleRight} />
                  AVANÇAR
                </button>
              </>
            )}

            {!mostraTelefone && mostraCadSenha && (
              <>
                <p style={{ fontWeight: "500", fontSize: "16px" }}>
                  Cliente: {tempCliente.nome}
                  <FontAwesomeIcon icon={faSignOutAlt} style={{ marginLeft: "5px", cursor: "pointer" }} onClick={handleSair} />
                </p>

                <p>Senha:</p>
                <Input
                  value={newSenha}
                  mask="none"
                  maxLength={100}
                  placeholder="Digite sua senha"
                  inputMaskChange={setNewSenha}
                  type="password"
                />

                <p>Confirme sua senha:</p>
                <Input
                  value={confirmSenha}
                  mask="none"
                  maxLength={100}
                  placeholder="Digite sua senha novamente"
                  inputMaskChange={setConfirmSenha}
                  type="password"
                />

                <button type="button" onClick={handleCadastraSenha}>
                  <FontAwesomeIcon icon={faAngleDoubleRight} />
                  CADASTRAR
                </button>
              </>
            )}

          </>
        )}
      </div>

    </ div>
  );
}