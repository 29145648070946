import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import { useCache } from "../../hooks/useCache";

import { DeliveryProps } from "../../global/types";
import { currencyFormatter } from "../../utils/currencyFormatter";
import { maskPhone } from "../../utils/masks";

import "./style.css";

type ParamsProps = {
  closeModal: () => void;
  delivery: DeliveryProps;
}

const status = ["Pedido Realizado", "Em Produção", "Aguardando Entregador", "Entregando/Retirada", "Finalizado", "Cancelado"];

const formaPgto = {
  "-1": "Desconto",
  1: "Dinheiro",
  2: "Cartão de Crédito",
  3: "Cartão de Débito",
  4: "Crediário",
  5: "Pix",
};

export function ModalDetalhes({ closeModal, delivery }: ParamsProps) {
  const { configCores } = useCache();

  return (
    <div id="modal-detalhes">
      <div className="header" style={{ backgroundColor: configCores?.cor_1, color: configCores?.cor_fonte }}>
        <h3>Detalhes do Pedido {delivery.id}</h3>
        <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer" }} onClick={closeModal} />
      </div>

      <div className="content">
        <p><b>Status:</b> {status[delivery.status]}</p>
        <p><b>Data / Hora:</b> {delivery.data_hora}</p>
        <p><b>Cliente:</b> {delivery.cliente}</p>
        <p><b>Telefone:</b> {maskPhone(`${delivery.telefone}`)}</p>
        <p><b>Endereço:</b> {delivery.endereco}</p>
        <p><b>Pagamento:</b> {formaPgto[delivery.tipo_pgto]}</p>

        <div className="headerList" style={{ backgroundColor: configCores?.cor_1, color: configCores?.cor_fonte }}>
          <p>PEDIDO</p>
        </div>

        <div className="contentList" style={{ borderColor: configCores?.cor_1 }}>
          {delivery.gp_pedidos?.map((gpPedido) => {
            return gpPedido.pedidos.map((item, i) => {
              let nomeProduto = `${item.produto} ${item.tamanho}`;

              const ObsAdcGeralProduto = item.observacoes;
              if (item.has_comb) {
                const grupoTamanho = item.produto.split("::")[0];
                const Produtos = item.produto.split("::")[1].split("||");
                const Adicionais = item.adc_individual.split("||");
                const Observacoes = item.obs_individual.split("||");

                nomeProduto = `${grupoTamanho}\n`;

                for (let i = 0; i < Produtos.length; i++) {
                  nomeProduto += `${Produtos[i]}\n    Adicionais: ${Adicionais[i]}\n    Observações: ${Observacoes[i]}`;
                  if (i != Produtos.length - 1) nomeProduto += `\n\n`;
                }
              }

              if (ObsAdcGeralProduto.length > 3) {
                nomeProduto += `\n    Adc/Obs: ${ObsAdcGeralProduto}`
              }

              let txtGpEspec = "";
              item.gp_espec?.map((item) => {
                txtGpEspec += `\n    ${item.nome}:\n`;
                let txtProdGpEspec = "";
                item.produtos.map((itemProd) => {
                  txtProdGpEspec += txtProdGpEspec.length > 0 ? ", " : "    ";
                  const qtd = itemProd.qtd ? itemProd.qtd : 0;

                  if (qtd > 1) {
                    txtProdGpEspec += `${qtd}x ${itemProd.nome}`;
                  } else {
                    txtProdGpEspec += `${itemProd.nome}`;
                  }

                });
                txtGpEspec += txtProdGpEspec;
              });

              nomeProduto += `\n ${txtGpEspec}`;

              return (
                <>
                  <div className="itemList" key={`${gpPedido.id_grupos}-${item.id}-${i}`}>
                    <p style={{ flex: 1, paddingRight: "15px" }}>{`${item.qtd / 1000}x `}</p>
                    <p>{nomeProduto}</p>
                  </div>
                  { }
                  <div className="separator" style={{ backgroundColor: configCores.cor_1 }} />
                </>
              );
            });
          })}
        </div>

        <div className="footer">
          <p><b>Consumo:</b> {currencyFormatter(delivery.total / 100)}</p>
          <p><b>Taxa de Entrega:</b> {currencyFormatter(delivery.taxa_entrega / 100)}</p>
          <p><b>Desconto:</b> {currencyFormatter(delivery.desconto / 100)}</p>
          <p><b>Total:</b> {currencyFormatter((parseInt(`${delivery.total}`) + parseInt(`${delivery.taxa_entrega}`) - parseInt(`${delivery.desconto}`)) / 100)}</p>
        </div>

      </div>

    </div>
  )
}