import { useEffect, useState, KeyboardEvent } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCaretRight, faPlus, faCreditCard, faCoins, faQrcode, faCheck, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";

import { NovoEndereco } from "../NovoEndereco";
import { ModalTroco } from "../ModalTroco";
import { currencyFormatter } from "../../utils/currencyFormatter";
import { useCache } from "../../hooks/useCache";
// import { api } from "../../services/api";
import { Load } from "../Load";
import { VerificaCaixaAberto, VerificaDeliveryWebOnline } from "../../utils/loadCache";

import { ItemCarrinho } from "../ItemCarrinho";
import { Input } from "../Input";

import "./style.css";
import { AddPedidoProps, ClienteProps, EnderecoClienteProps, EmpresaParams, AddDeliveryProps, SatConfigProps, BairroProps, CupomRequest, CupomCarrinhoRequest, CupomSelected, ConfigPixDto } from "../../global/types";
import { getApiClient } from "../../utils/getApiClient";
import { getMessageErro } from "../../utils/getMessageErro";
import { maskPhone } from "../../utils/masks";
import { log } from "console";

type ParamsProps = {
  closeModal: (limparCarrinho: boolean) => void;
  contextPhone?: string;
  caixaAberto?: boolean
}

const styleModal = {
  content: {
    padding: "0",
    width: window.innerWidth > 1120 ? 850 : window.innerWidth - 60,
    height: window.innerHeight - 150,
    borderRadius: "10px",
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)"
  }
};

export function Carrinho({ closeModal, contextPhone, caixaAberto = true }: ParamsProps) {
  const { configCores, pedidos, updatePedidos, empresa, cliente: clienteStorage, gravaCliente } = useCache();
  const { codCliente } = useParams() as EmpresaParams;

  const [firstRun, setFirstRun] = useState(true);

  const [txtLoading, setTxtLoading] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadCliente, setLoadCliente] = useState<boolean>(false);

  const [modalNewEndereco, setModalNewEndereco] = useState(false);
  const [modalTroco, setModalTroco] = useState(false);

  const [mostraInfoCliente, setMostraInfoCliente] = useState<boolean>(false);

  const [totalCarrinho, setTotalCarrinho] = useState<number>(0);

  const [phone, setPhone] = useState<string>(contextPhone ? contextPhone : "");
  const [disablePhone, setDisablePhone] = useState<boolean>(false);
  const [disableInputNome, setDisableInputNome] = useState<boolean>(false);

  const [cliente, setCliente] = useState<ClienteProps>({} as ClienteProps);
  const [nomeCliente, setNomeCliente] = useState<string>("");
  const [listaEnderecos, setListaEnderecos] = useState<EnderecoClienteProps[]>([]);
  const [endSelected, setEndSelected] = useState(-1);
  const [taxaEntrega, setTaxaEntrega] = useState(currencyFormatter(empresa.taxa_entrega / 100).replace("R$ ", ""));
  const [mostraPgtos, setMostraPgtos] = useState(false);

  const [cpfNota, setCpfNota] = useState(0);
  const [cpf, setCpf] = useState("");

  const [codCupom, setCodCupom] = useState("");

  const [cupomSelected, setCupomSelected] = useState<CupomSelected | null>(null);
  const [desconto, setDesconto] = useState(0);

  const ajustaDivInfoCliente = (altura?: number) => {
    const container = document.getElementsByClassName("container");
    const content = document.getElementById("finalizarContent");

    if (container && content) {
      if (altura) {
        content.style.height = `${altura}px`;
      } else {
        setTimeout(() => {
          const clienteHeight = container[0]?.clientHeight ? container[0].clientHeight : 0
          content.style.height = `${(clienteHeight + 35)}px`;
        }, 510);
      }
    }

  }

  const handleBtnMenosItem = (i: number) => {
    const tempList: AddPedidoProps[] = [];

    pedidos.forEach((rs, key) => {
      let excluir = false;
      let nQtd = rs.qtd / 1000;
      const valorUnit: number = rs.valor / nQtd;
      if (key === i) {
        if (nQtd === 1) {
          if (window.confirm("Deseja realmente excluir este item do carrinho?"))
            excluir = true;
        } else
          nQtd -= 1;
      }

      if (!excluir)
        tempList.push({ ...rs, qtd: nQtd * 1000, valor: nQtd * valorUnit });
    })

    updatePedidos(tempList);
  }

  const handleBtnMaisItem = (i: number) => {
    const tempList: AddPedidoProps[] = [];

    pedidos.forEach((rs, key) => {
      let nQtd = rs.qtd / 1000;
      const valorUnit: number = rs.valor / nQtd;
      if (key === i)
        nQtd += 1;

      tempList.push({ ...rs, qtd: nQtd * 1000, valor: nQtd * valorUnit });
    })

    updatePedidos(tempList);
  }

  const handleKeyPhone = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter")
      handlePesquisaCliente();
  }

  const handlePesquisaCliente = async (idNewEndereco: number = -1, autoPhone?: string) => {

    let tel = "";

    if (autoPhone) {
      tel = autoPhone.replace(/[^0-9]/g, "");
    } else {
      if (phone.length < 14)
        return alert("Preencha corretamente o campo Celular/Telefone");

      if (!clienteStorage.telefone || clienteStorage.telefone.length <= 0)
        gravaCliente(0, phone, "");

      tel = phone.replace(/[^0-9]/g, "");
    }

    setLoadCliente(true);
    setDisablePhone(true);
    setMostraInfoCliente(false);

    ajustaDivInfoCliente(300);

    await getApiClient(codCliente)
      .get(`${codCliente}/clientes?telefone=${tel}`)
      .then(async (res) => {
        if (!res.data)
          throw new Error("Servidor indisponível");

        const enderecos: EnderecoClienteProps[] = [];

        enderecos.push({
          id: 0,
          endereco: "Retirada no Estabelecimento",
          bairro: "",
          complemento: "",
          numero: 0,
          padrao: 0,
        });

        const listaCliente = res.data as ClienteProps[];

        if (listaCliente.length > 0) {
          setCliente(listaCliente[0]);

          if (listaCliente[0].nome.length > 0)
            setNomeCliente(listaCliente[0].nome);

          setDisableInputNome(listaCliente[0].nome.length > 3);

          if (empresa.delivery_modo == 0) {

            listaCliente[0].enderecos.forEach((item) => {
              enderecos.push({ ...item });
            });
          }

          if(listaCliente[0].ult_delivery > 0)
            alert(`\nATENÇÃO\n\nJá existe um pedido de delivery registrado em seu nome para hoje.\nClique em "Veja seus pedidos" para conferir se está tudo ok!`);

        } else {
          setDisableInputNome(false);

          await getApiClient(codCliente)
            .post(`${codCliente}/clientes`, {
              id: 0,
              nome: "",
              telefone: tel,
              enderecos: [],
            })
            .then((res) => {
              if (!res.data)
                throw new Error("Servidor indisponível");

              console.log(res.data);

              setNomeCliente("");
              setCliente({
                id: res.data.id,
                nome: nomeCliente,
                senha: "",
                cpf_cnpj: 0,
                enderecos: [],
                ult_delivery: 0,
                telefone: parseInt(tel),
              });

              setEndSelected(-1);
            })
            .catch(() => {
              setNomeCliente("");
              setCliente({} as ClienteProps);
              setEndSelected(-1);
            });
        }

        setListaEnderecos(enderecos);
        setMostraInfoCliente(true);
        ajustaDivInfoCliente();
      })
      .catch(() => {
        alert("Erro ao buscar o cliente. Tente novamente mais tarde.")
      })
      .finally(() => {
        setLoadCliente(false);
      })
  }

  const handleEndSelected = async (item: EnderecoClienteProps) => {
    if (item.id === 0) setTaxaEntrega("0.00");
    else if (item.id > 0) {
      let tempTaxa = empresa.taxa_entrega;

      if (empresa.dif_entrega_bairro === 1 && item.id_bairro && item.id_bairro > 0) {
        try {
          setLoadCliente(true)
          setMostraInfoCliente(false);
          const reqFindBairro = await getApiClient(codCliente).get(`${codCliente}/bairros?id=${item.id_bairro}`)

          if (!reqFindBairro.data)
            throw new Error("Servidor indisponível");

          const resBairro: BairroProps = reqFindBairro.data

          tempTaxa = resBairro.entrega
        } catch (err) {
        } finally {
          setLoadCliente(false)
          setMostraInfoCliente(true);
        }
      }

      if (empresa.entrega_gratis_valor > 0 && totalCarrinho >= (empresa.entrega_gratis_valor / 100))
        tempTaxa = 0;

      setTaxaEntrega(
        currencyFormatter(tempTaxa / 100).replace(
          "R$ ",
          ""
        )
      );
    }

    setEndSelected(item.id);
    setMostraPgtos(true);
    ajustaDivInfoCliente();
  };

  const handleUnselectCliente = () => {
    setMostraInfoCliente(false);
    setCliente({} as ClienteProps);
    setListaEnderecos([]);
    setEndSelected(-1);
    setDisablePhone(false);
    ajustaDivInfoCliente();
    ajustaDivInfoCliente(190);
    setMostraPgtos(false);
    setPhone("");
  }

  const handleCLoseModalNewEndereco = () => {
    setModalNewEndereco(false);
  }

  const handleOpenModalNewEndereco = () => {
    if (nomeCliente.length <= 0)
      return alert("Informe um nome válido");

    setModalNewEndereco(true);
  }

  const handleEditCliente = async () => {
    try {
      if (nomeCliente.length <= 0)
        return;

      if (clienteStorage.nome === "") {
        setLoading(true)
        setTxtLoading("Salvando nome do Cliente")

        await getApiClient(codCliente)
          .put(`${codCliente}/clientes`, {
            id: cliente.id,
            nome: nomeCliente,
            telefone: cliente.telefone,
            enderecos: cliente.enderecos,
          })

        setCliente({
          id: cliente.id,
          nome: nomeCliente,
          senha: cliente.senha,
          cpf_cnpj: cliente.cpf_cnpj,
          enderecos: cliente.enderecos,
          ult_delivery: cliente.ult_delivery,
          telefone: cliente.telefone,
        });
        gravaCliente(cliente.id, phone, nomeCliente);
      }

    } catch (error) {
      alert("Erro ao salvar informações do cliente")
    }
    finally {
      setLoading(false)
      setTxtLoading("")
      ajustaDivInfoCliente();
    }
  }

  const handleAddEndereco = (idNewEndereco: number) => {
    setModalNewEndereco(false);
    handlePesquisaCliente(idNewEndereco);
  };

  const loadConfigSat = async (): Promise<SatConfigProps> => {
    let config = {} as SatConfigProps;

    await getApiClient(codCliente)
      .get(`${codCliente}/config_sat`)
      .then(async (res) => {
        if (!res.data) throw new Error("Servidor indisponível");
        config = res.data;
      })
      .catch(() => {
        alert("Erro ao recuperar configuração fiscais da empresa. Tente novamente mais tarde");
      });

    return config;
  }

  const loadConfigPix = async (): Promise<ConfigPixDto> => {
    let configPix = {} as ConfigPixDto;

    try {
      const reqConfigPix = await getApiClient(codCliente).get(`${codCliente}/pix/get_config`)
      if (!reqConfigPix.data) throw new Error("Servidor indisponível");

      configPix = reqConfigPix.data;

    } catch (err: any) {
      alert("Erro ao recuperar configuração do pix");
    }

    return configPix;
  }

  const handleFinalizar = async (formaPgto: number, txtTroco?: string) => {

    if (nomeCliente.length <= 1)
      return alert("Informe seu nome");

    if (endSelected === -1)
      return alert("Endereço de entrega não informada");

    const troco: number = txtTroco ? parseInt(txtTroco.replace(/[^0-9]/g, "")) : 0;
    let total = 0;
    let fechaModal = false;
    let tEntrega = 0;

    if (taxaEntrega.length > 0) {
      tEntrega = taxaEntrega.includes(",")
        ? parseInt(taxaEntrega.replace(/[^0-9]/g, ""))
        : parseInt(taxaEntrega) * 100;
    }

    pedidos.forEach((rs) => { total += rs.valor; });

    if (endSelected > 0)
      total += empresa.taxa_entrega;

    total -= desconto;

    if (formaPgto === 1 && txtTroco && txtTroco.length > 0) {
      if (troco <= total)
        return alert("Informe o valor para troco maior do que o total do pedido.");
    }

    setModalTroco(false);
    setLoading(true);

    if (cliente.nome.length === 0) {
      if (nomeCliente.length === 0) {
        setLoading(false);
        return alert("Informe seu nome.")
      }

      setTxtLoading("Cadastrando cliente em nosso banco de dados.");
      const tel = phone.replace(/[^0-9]/g, "");
      await getApiClient(codCliente).put(`${codCliente}/clientes`, {
        id: cliente.id,
        nome: nomeCliente,
        telefone: tel,
      });
    }

    const newDelivery: AddDeliveryProps = {
      id_clientes: cliente.id ? cliente.id : 0,
      id_clientes_enderecos: endSelected,
      id_cliente_crediario: cliente.id,
      tipo_pgto: formaPgto,
      troco: troco,
      taxa_entrega: tEntrega,
      desconto,
      observacoes: "",
      print_qr_code_pix: formaPgto === 5,
      pedidos: pedidos,
      by_web: 1,
      id_cupom: cupomSelected !== null ? cupomSelected.idCupom : undefined
    };

    setTxtLoading("Verificando status do Estabelecimento");
    let caixaAberto = await VerificaCaixaAberto(codCliente);

    if (caixaAberto)
      caixaAberto = await VerificaDeliveryWebOnline(codCliente);

    if (!caixaAberto)
      return alert("O Estabelecimento já encerrou seu expediente.");

    setTxtLoading("Buscando configurações fiscais da empresa");
    const sat = await loadConfigSat();

    if (!sat.ambiente)
      return;

    let pix = {} as ConfigPixDto;
    if (formaPgto === 5) {
      setTxtLoading("Buscando oconfigurações do pix");
      pix = await loadConfigPix();
    }

    setTxtLoading("Finalizando pedido...");

    await getApiClient(codCliente)
      .post(`${codCliente}/delivery`, {
        ...newDelivery,
      })
      .then(async (res) => {
        if (!res.data)
          throw new Error("Servidor indisponível");

        let idDelivery = res.data.id_delivery;
        let autoSat = false;
        switch (formaPgto) {
          case 1: autoSat = sat.auto_dinheiro == 1; break;
          case 2: autoSat = sat.auto_credito == 1; break;
          case 5: autoSat = sat.auto_pix == 1; break;
          default: autoSat = false; break;
        }

        if (cpfNota > 0) {
          await getApiClient(codCliente).post(`${codCliente}/solicitar_sat`, {
            cpf: cpf.length >= 14 ? cpf.replace(/[^0-9]/g, "") : "",
            id_delivery: idDelivery,
            id_mesas: 0,
          });
        } else if (autoSat) {
          await getApiClient(codCliente).post(`${codCliente}/solicitar_sat`, {
            cpf: "",
            id_delivery: idDelivery,
            id_mesas: 0,
          });
        }

        let alertSuccess = `Pedido finalizado com sucesso. Dentro de ${endSelected > 0 ? configCores.tempo_entrega : configCores.tempo_retirada} seu pedido ${endSelected > 0 ? "chegará em sua casa" : "estará pronto para retirada"}`;

        if (formaPgto === 5 && pix.chave && pix.chave.length > 3) {
          let tipoPix = "";
          switch (pix.tipo_chave) {
            case 0: tipoPix = "CPF/CNPJ"; break;
            case 1: tipoPix = "E-mail"; break;
            case 2: tipoPix = "Celular"; break;
            case 3: tipoPix = "Aleatória"; break;
          }
          alertSuccess += `\n\nCaso preferir realizar o pagamento antecipado.\nTipo Chave: ${tipoPix}\nChave: ${tipoPix === "Celular" ? maskPhone(pix.chave) : pix.chave}`
        }

        alert(alertSuccess);
        fechaModal = true;
      })
      .catch((err) => {
        if (err.response.data.msg)
          alert(err.response.data.msg);
        else
          alert("Erro ao enviar delivery para o servidor. Tente novamente mais tarde");
      })
      .finally(() => {
        setLoading(false);
        if (fechaModal)
          closeModal(true);
      });
  }

  const handleCheckCupom = async () => {
    setLoading(true);
    setTxtLoading("Verificando cupom de desconto");

    const carrinho: CupomCarrinhoRequest[] = [];
    let total_venda = 0;

    pedidos.forEach(e => {
      total_venda += e.valor;
      carrinho.push({
        id_produto_tam: e.id_produtos_tam,
        qtd: e.qtd / 1000,
        valor_produto: e.valor / (e.qtd / 1000)
      });
    });

    const bodyRequest: CupomRequest = {
      id_cliente: cliente.id,
      cod_cupom: codCupom,
      local: 2,
      total_venda,
      carrinho
    }

    await getApiClient(codCliente)
      .post(`${codCliente}/verifica_cupom`, bodyRequest)
      .then((res) => {
        if (!res.data) throw new Error("Servidor indisponível");

        const { idCupom, totalDesconto } = res.data;

        if (window.confirm(`Cupom Válido!\nValor em descontos: ${currencyFormatter(totalDesconto / 100)}\nDeseja aplicar o cupom?`)) {
          setDesconto(totalDesconto);
          setCupomSelected({
            cupom: codCupom,
            idCupom
          });
        } else {
          setDesconto(0);
          setCupomSelected(null);
        }

      })
      .catch((err) => {
        alert(getMessageErro(err, "Erro ao verificar cupom. Tente novamente mais tarde"));
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
        ajustaDivInfoCliente();
      });

  }

  useEffect(() => {
    if (firstRun && phone.length >= 14)
      handlePesquisaCliente(-1, phone);

    setFirstRun(false)
  }, [phone])

  useEffect(() => {
    let t: number = 0;
    pedidos.forEach((rs) => {
      t += rs.valor / 100;
    });

    setTotalCarrinho(t);
    ajustaDivInfoCliente();

    if (listaEnderecos.length > 0 && endSelected >= 0)
      handleEndSelected(listaEnderecos.filter(e => e.id === endSelected)[0])

  }, [pedidos]);

  useEffect(() => {
    if (totalCarrinho > 0)
      if (listaEnderecos.length > 0 && endSelected >= 0)
        handleEndSelected(listaEnderecos.filter(e => e.id === endSelected)[0])
  }, [totalCarrinho])

  return (
    <div id="ModalCarrinho">
      <div className="header" style={{ backgroundColor: configCores?.cor_1, color: configCores?.cor_fonte }}>
        <h3>Carrinho</h3>
        <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer" }} onClick={() => closeModal(false)} />
      </div>

      <div style={{ height: window.innerHeight - 120, overflow: "auto" }}>
        {loading && (
          <Load txtLoading={txtLoading} />
        )}

        {!loading && caixaAberto && (
          <>
            <div className="content">
              {pedidos.map((rs, i) => (
                <ItemCarrinho key={`cp-${i}`} data={rs} btnMais={handleBtnMaisItem} btnMenos={handleBtnMenosItem} keyIndex={i} />
              ))}
            </div>

            <div className="list-footer">
              <h4>Valor total dos Produtos: {currencyFormatter(totalCarrinho)}</h4>
            </div>

            {pedidos.length > 0 && (
              <>
                <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", gap: "6px", marginTop: "8px" }}>
                  <button type="button" className="btnVerde" onClick={() => closeModal(false)}>
                    CONTINUAR COMPRANDO
                  </button>
                  <p style={{ fontSize: "14px", fontWeight: "500" }}>ou preencha as informações abaixo para finalizar seu pedido</p>
                </div>

                <div id="finalizarContent" style={{ borderColor: configCores?.cor_1 }}>
                  <h4 style={{ backgroundColor: configCores?.cor_1, color: configCores?.cor_fonte }}>FINALIZAR PEDIDO</h4>

                  <div className="container">
                    <span>Celular/Telefone:</span>
                    <div className="phoneContainer">
                      <Input
                        disabled={disablePhone}
                        mask="phone"
                        value={phone}
                        inputMaskChange={setPhone}
                        maxLength={15}
                        placeholder="Digite seu telefone"
                        onKeyUp={handleKeyPhone}
                        type="tel"
                      />
                      {!disablePhone && (
                        <button type="button" onClick={() => handlePesquisaCliente()}>
                          <FontAwesomeIcon icon={faCaretRight} style={{ fontSize: "20px" }} />
                        </button>
                      )}

                      {disablePhone && (
                        <button type="button" onClick={handleUnselectCliente} style={{ backgroundColor: "#a40000", borderColor: "#7e0101" }}>
                          <FontAwesomeIcon icon={faTimes} />
                        </button>
                      )}
                    </div>

                    {loadCliente && (
                      <Load txtLoading="Procurando informações em nosso banco de dados" options={{ width: 120, height: 120 }} />
                    )}

                    {mostraInfoCliente && (
                      <div className="infoCliente">
                        <span>Nome:</span>
                        <Input
                          mask="none"
                          disabled={disableInputNome}
                          value={nomeCliente}
                          inputMaskChange={setNomeCliente}
                          placeholder="Digite seu Nome"
                          onBlur={() => { handleEditCliente() }}
                        />

                        <div className="listaEnderecos">
                          {empresa.delivery_modo == 1 && (
                            <h5 style={{ textAlign: "center", marginTop: "10px", fontSize: "16px" }}>Endereços</h5>
                          )}

                          {empresa.delivery_modo == 0 && (
                            <>
                              <h5 style={{ textAlign: "center", marginTop: "10px", fontSize: "16px" }}>Endereços</h5>
                              <button type="button" onClick={() => handleOpenModalNewEndereco()}>
                                <FontAwesomeIcon icon={faPlus} />
                                NOVO ENDEREÇO
                              </button>
                            </>
                          )}
                          {listaEnderecos.map((rs) => {
                            return (
                              <label key={`e-${rs.id}`} className="ck-container">
                                {rs.id === 0 ? rs.endereco : `${rs.endereco}, nº ${rs.numero} - ${rs.bairro}`}
                                <input
                                  id={`end-${rs.id}`}
                                  type="radio"
                                  name="ck-endereco"
                                  value={rs.id}
                                  checked={rs.id === endSelected}
                                  onChange={() => { handleEndSelected(rs) }} />
                                <span className="checkmark" />
                              </label>
                            );
                          })}
                        </div>

                        {mostraPgtos && (
                          <div style={{ textAlign: "center" }}>
                            <h4>PAGAMENTO</h4>

                            {totalCarrinho < (configCores.pedido_minimo / 100) && endSelected > 0 && (
                              <>
                                <p style={{ fontWeight: "500" }}>Você não atingiu o valor mínimo de<br />{currencyFormatter(configCores.pedido_minimo / 100)}</p>

                                <button type="button" className="btnContinuarComprando" onClick={() => closeModal(false)}>
                                  CONTINUAR COMPRANDO
                                </button>

                              </>
                            )}

                            {(totalCarrinho >= (configCores.pedido_minimo / 100) || endSelected === 0) && (
                              <>
                                <div style={{ fontSize: "14px" }}>

                                  {endSelected > 0 && (
                                    <>
                                      {empresa.entrega_gratis_valor > 0 && (
                                        <p>Entrega grátis acima de: {currencyFormatter(empresa.entrega_gratis_valor / 100)}</p>
                                      )}
                                      <p>Produtos: {currencyFormatter(totalCarrinho)}</p>
                                      <p>Entrega: R$ {taxaEntrega}</p>
                                      <p>Desconto: R$ {currencyFormatter(desconto / 100)}</p>
                                      <p style={{ fontWeight: "600" }}>
                                        TOTAL: {
                                          currencyFormatter(
                                            totalCarrinho - (desconto / 100) + ((taxaEntrega.includes(",") ? parseInt(taxaEntrega.replace(/[^0-9]/g, "")) : parseInt(taxaEntrega) * 100) / 100)
                                          )
                                        }
                                      </p>
                                    </>
                                  )}

                                  {endSelected === 0 && (
                                    <>
                                      <p>Desconto: R$ {currencyFormatter(desconto / 100)}</p>
                                      <p style={{ fontWeight: "600" }}>TOTAL: {currencyFormatter(totalCarrinho - (desconto / 100))}</p>
                                    </>
                                  )}
                                </div>


                                <div className="optionsPgto">

                                  <div>
                                    <p>Cupom de desconto</p>
                                    <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                                      <Input
                                        mask="uppercase"
                                        value={codCupom}
                                        inputMaskChange={setCodCupom}
                                        maxLength={14}
                                        placeholder="Cupom de Descontos"
                                      />

                                      <button
                                        style={{
                                          padding: "0 14px",
                                          margin: "2px",
                                          background: "#2196F3",
                                          border: "none",
                                          color: "#fff",
                                          borderRadius: "5px"
                                        }}
                                        onClick={() => handleCheckCupom()}
                                      >
                                        <FontAwesomeIcon icon={faCheck} />
                                      </button>

                                    </div>
                                  </div>

                                  {configCores.ativar_cpf_nota > 0 && (
                                    <div className="CPFnota">
                                      <div style={{ width: "100%" }}>
                                        <p>CPF na Nota?</p>
                                        <select value={cpfNota} onChange={(e) => setCpfNota(parseInt(e.target.value))}>
                                          <option value={0}>Não</option>
                                          <option value={1}>Sim</option>
                                        </select>
                                      </div>

                                      <div style={{ width: "100%" }}>
                                        {cpfNota > 0 && (
                                          <>
                                            <p>CPF</p>
                                            <Input
                                              mask="cpf_cnpj"
                                              value={cpf}
                                              inputMaskChange={setCpf}
                                              maxLength={14}
                                              placeholder="Digite seu CPF"
                                            />
                                          </>
                                        )}
                                      </div>

                                    </div>
                                  )}
                                </div>

                                <div className="containerPgto">
                                  {configCores.btn_pgto_dinheiro > 0 && (
                                    <div onClick={() => { setModalTroco(true) }}>
                                      <FontAwesomeIcon icon={faCoins} style={{ fontSize: "40px" }} />
                                      DINHEIRO
                                    </div>
                                  )}

                                  {configCores.btn_pgto_cartao > 0 && (
                                    <div onClick={() => handleFinalizar(2)}>
                                      <FontAwesomeIcon icon={faCreditCard} style={{ fontSize: "40px" }} />
                                      C. CRÉDITO / DÉBITO
                                    </div>
                                  )}

                                  {configCores.btn_pgto_pix > 0 && (
                                    <div onClick={() => handleFinalizar(5)}>
                                      <FontAwesomeIcon icon={faQrcode} style={{ fontSize: "40px" }} />
                                      PIX
                                    </div>
                                  )}
                                  </div>
                              </>
                            )}

                          </div>
                        )}

                        {!mostraPgtos && (
                          <h3 style={{ textAlign: "center", marginTop: "10px" }}>SELECIONE UM ENDEREÇO OU ESCOLHA RETIRADA NO ESTABELECIMENTO</h3>
                        )}

                      </div>
                    )}

                  </div>
                </div>
              </>
            )}
          </>
        )}

        {!loading && !caixaAberto && (
          <div className="aviso">
            <FontAwesomeIcon icon={faExclamationCircle} style={{ fontSize: "60px", color: "#cd240e" }} />
            <div>
              <h3>Delivery online fechado!</h3>
              <p>Você poderá navegar normalmente, só não poderá finalizar o pedido.</p>
            </div>
          </div>
        )}
      </div>

      <Modal isOpen={modalNewEndereco} style={styleModal}>
        <NovoEndereco closeModal={handleCLoseModalNewEndereco} idCliente={cliente.id} confirma={handleAddEndereco} nomeCliente={nomeCliente} />
      </Modal>

      <Modal isOpen={modalTroco} style={{ content: { ...styleModal.content, width: 350, height: 285 }, overlay: { ...styleModal.overlay } }}>
        <ModalTroco
          closeModal={() => setModalTroco(false)}
          confirma={handleFinalizar}
          total={
            endSelected > 0
              ? currencyFormatter(totalCarrinho - (desconto / 100) + ((taxaEntrega.includes(",")
                ? parseInt(taxaEntrega.replace(/[^0-9]/g, ""))
                : parseInt(taxaEntrega) * 100) / 100))
              : currencyFormatter(totalCarrinho - (desconto / 100))
          }
        />
      </Modal>

    </div>
  );
}