import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CacheContextProvider } from "./contexts/CacheContext";

import { Home } from "./pages/Home";
import { Empresa } from "./pages/Empresa";
import { EmpresaHome } from "./pages/EmpresaHome";
import { ChamaGarcom } from "./pages/ChamaGarcom";
import { MesaCardapio } from "./pages/MesaCardapio";
import { AdmHome } from "./pages/AdmHome";
import { AdmLogin } from "./pages/AdmLogin";
import { ControleLogin } from "./pages/ControleLogin";
import { ControleHome } from "./pages/ControleHome";

function App() {
  return (
    <BrowserRouter>
      <CacheContextProvider>
        <Routes>
          <Route path="/" element={<Home />} />

          <Route path="/adm" element={<AdmHome />} />
          <Route path="/adm/login" element={<AdmLogin />} />

          <Route path="/controle" element={<ControleHome />} />
          <Route path="/controle/login" element={<ControleLogin />} />

          <Route path="/:codCliente" element={<EmpresaHome />} />
          <Route path="/novoPedido/:codCliente" element={<Empresa />} />

          <Route path="/mesas/:urlNrMesa/:codCliente" element={<ChamaGarcom />} />
          <Route path="/mesas/:urlNrMesa/:codCliente/cardapio" element={<MesaCardapio />} />

          <Route path="*" element={<h1 style={{ padding: "25px", textAlign: "center" }}>Not found!</h1>} />
        </Routes>
      </CacheContextProvider>
    </BrowserRouter>
  );
}

export default App;
