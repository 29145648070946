import { GrupoProps, IngredProdProps, ProdutoProps, TamProdProps } from "../../global/types";
import { useCache } from "../../hooks/useCache";
import { currencyFormatter } from "../../utils/currencyFormatter";

import "./style.css";

type ParamsProps = {
  produto: ProdutoProps;
  tamanho: TamProdProps;
  grupo: GrupoProps;
  handleClick: (tam: TamProdProps, prod: ProdutoProps, gp: GrupoProps) => void;
  bgColor?: string;
  mostrarGrupo?: boolean;
}

export function ItemProduto({ grupo, handleClick, produto, tamanho, bgColor, mostrarGrupo }: ParamsProps) {

  const { configCores } = useCache();

  const viewIngrediente = (item: IngredProdProps, i: number, tamanho: TamProdProps) => {
    const ingrediente = tamanho.qtdIngredientes.find(e => e.id_ingredientes === item.id_ingredientes)?.qtd_utilizada
    const txtQtd = ingrediente && ingrediente > 1000 ? `${ingrediente / 1000}x ` : '';
    return i > 0 ? `, ${txtQtd}${item.ingrediente}` : `${txtQtd}${item.ingrediente}`;
  }

  return (
    <div className="produto" style={{ background: bgColor ? bgColor : configCores?.cor_1 }} onClick={() => { handleClick(tamanho, produto, grupo) }}>
      <div className="img-expandido" style={{ backgroundImage: produto.img.length > 0 ? `url("${produto.img}")` : 'url("https://www.dcifood.com.br/api/logos/sem_imagem.jpg")' }} />
      <div className="content-produto">
        <h4>
          {mostrarGrupo && (`${grupo.nome}: `)}
          {produto.nome}
        </h4>
        <p>Tamanho: {tamanho.tamanho}</p>

        <span style={{ color: bgColor ? "#A40000" : configCores?.cor_2 }}>
          {tamanho.valorAntes && (
            <>
              <small style={{ textDecoration: "line-through", fontWeight: "normal" }}>{currencyFormatter(tamanho.valorAntes / 100)}</small><br />
            </>
          )}
          {currencyFormatter(tamanho.valor / 100)}
        </span>
        <p>
          {produto.ingredientes.map((rsIngr, i) => viewIngrediente(rsIngr, i, tamanho))}
        </p>
      </div>
    </div>
  );
}