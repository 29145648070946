import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faExclamationCircle, faShoppingBasket } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";

import { useCache } from "../../hooks/useCache";
import { VerificaCaixaAberto } from "../../utils/loadCache";
import { Load } from "../../components/Load";
import { GrupoProps, MesasProps, ProdutoProps, TamProdProps } from "../../global/types";

import "./style.css";
import { ItemProduto } from "../../components/ItemProduto";
import { ProdutoQtdModal } from "../../components/ProdutoQtdModal";
import { ProdutoDivisorModal } from "../../components/ProdutoDivisorModal";
import { CarrinhoMesa } from "../../components/CarrinhoMesa";
// import { api } from "../../services/api";
import { NrMesaDecript } from "../../utils/nrMesaDecript";
import { Input } from "../../components/Input";
import { getApiClient } from "../../utils/getApiClient";

Modal.setAppElement("#root");

const styleModal = {
  content: {
    padding: "0",
    width: window.innerWidth > 1120 ? 910 : window.innerWidth - 30,
    height: window.innerHeight - 50,
    borderRadius: "10px",
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: "0px"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)"
  }
};

type INavigateProps = {
  codCliente: string;
  urlNrMesa: string;
}

export function MesaCardapio() {
  const { codCliente, urlNrMesa } = useParams() as INavigateProps;
  const {
    buscaPromocoes,
    buscaProdutos,
    buscaEmpresa,
    buscaGrupos,
    promocoes,
    produtos,
    empresa,
    grupos,
    pedidos,
    clearPedidos,
    refreshPedidos,
    buscaConfigCores,
    configCores
  } = useCache();

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [listGrupos, setListGrupos] = useState<GrupoProps[]>([]);
  const [listProdutos, setListProdutos] = useState<ProdutoProps[]>([]);

  const [tamanhoClicado, setTamanhoClicado] = useState<TamProdProps>({} as TamProdProps);
  const [produtoClicado, setProdutoClicado] = useState<ProdutoProps>({} as ProdutoProps);
  const [grupoClicado, setGrupoClicado] = useState<GrupoProps>({} as GrupoProps);

  const [qtdCarrinho, setQtdCarrinho] = useState<number>(0);

  const [modalIsOpenProdQtd, setModalIsOpenProdQtd] = useState(false);
  const [modalIsOpenProdDivisor, setModalIsOpenProdDivisor] = useState(false);
  const [modalIsOpenCarrinho, setModalIsOpenCarrinho] = useState(false);

  const [listComandas, setListComandas] = useState<MesasProps[]>([]);

  const [caixaAberto, setCaixaAberto] = useState<boolean>(false);

  const [nrMesa, setNrMesa] = useState("0");
  const [pesquisa, setPesquisa] = useState("");

  const loadMesas = async () => {
    setLoading(true);
    setTxtLoading("Atualizando comandas...");

    await getApiClient(codCliente).get(`${codCliente}/mesas`)
      .then((result) => {
        //if (!result.data)
        //throw new Error("Não foi possível recuperar a lista de comandas da sua mesa. Tente novamente mais tarde.");

        const list = result.data.list as MesasProps[];
        const listNrMesa = list.filter(i => i.mesa === parseInt(nrMesa) && i.id > 0);

        setListComandas(listNrMesa);
      })
      .catch(() => {
        //alert("Erro ao recuperar a lista de comandas para sua mesa. Tente novamente mais tarde.");
      })
  }

  const firstRun = async () => {
    setLoading(true);

    setTxtLoading("Carregando informações da Empresa...");
    await buscaConfigCores(codCliente);

    setTimeout(async () => {
      setTxtLoading("Buscando lista de Grupos");
      await buscaGrupos(codCliente);
  
      setTxtLoading("Buscando lista de Promoções");
      await buscaPromocoes(codCliente);
  
      setTxtLoading("Buscando lista de Produtos");
      await buscaProdutos(codCliente);
  
      setTxtLoading("Verificando status do Estabelecimento")
      setCaixaAberto(await VerificaCaixaAberto(codCliente));
  
      setTxtLoading("Buscando configurações gerais do Estabelecimento");
      await buscaEmpresa(codCliente);
  
      setTxtLoading("Buscando carrinho salvo em cache");
      refreshPedidos(codCliente);
  
      setLoading(false);
      setTxtLoading("");
    }, 1000);
  }

  const AplicaPromocoes = () => {

    const tempListProdutos: ProdutoProps[] = [];

    produtos.forEach(item => {

      let newItem = { ...item };

      promocoes.filter(e => e.local === 0 || e.local === 1).forEach(promo => {

        if (promo.produtos.length > 0) {

          promo.produtos.forEach(prodPromo => {
            let tamProdFilter = newItem.tamanhos.filter(e => e.id == prodPromo.id_produto_tam);
            if (tamProdFilter.length > 0) {
              let tamProd = tamProdFilter[0];
              let desconto = 0;

              if (promo.tipo_desconto == 0) {
                const descPorc = promo.desconto / 100;
                const descProcValor = (tamProd.valor / 100) * descPorc;
                desconto = descProcValor * 100;
              }

              if (promo.tipo_desconto == 1) {
                const descValor = promo.desconto * 100;
                desconto = descValor >= tamProd.valor ? tamProd.valor : descValor;
              }

              if (promo.tipo_desconto == 2) {
                const descValor = promo.desconto * 100;
                desconto = tamProd.valor - descValor;
              }

              tamProd.valorAntes = tamProd.valor;
              tamProd.valor -= desconto;
            }
          })

        } else {

          newItem.tamanhos.forEach(tamProd => {
            let desconto = 0;

            if (promo.tipo_desconto == 0) {
              const descPorc = promo.desconto / 100;
              const descProcValor = (tamProd.valor / 100) * descPorc;
              desconto = descProcValor * 100;
            }

            if (promo.tipo_desconto == 1) {
              const descValor = promo.desconto * 100;
              desconto = descValor >= tamProd.valor ? tamProd.valor : descValor;
            }

            if (promo.tipo_desconto == 2) {
              const descValor = promo.desconto * 100;
              desconto = tamProd.valor - descValor;
            }

            tamProd.valor -= desconto;
          });

        }
      })

      tempListProdutos.push(newItem);
    });

    setListProdutos(tempListProdutos);
  }

  const handleGrupo = (item: GrupoProps) => {
    const tempList: GrupoProps[] = [];
    listGrupos.forEach((rs) => {
      if (rs.id === item.id) {
        const divList = document.getElementById(`list-${item.id}`);
        const divListContent = document.getElementById(`list-content-${item.id}`);
        const icone = document.getElementById(`icon-${item.id}`);

        if (divList && divListContent) {
          divList.style.height = !rs.mostra_prod ? `${(divListContent.clientHeight + 50)}px` : '0px';
          if (icone)
            icone.style.transform = !rs.mostra_prod ? "rotate(90deg)" : "rotate(0deg)";
        }

        rs.mostra_prod = rs.mostra_prod ? false : true;
      }

      tempList.push(rs);
    });
    setListGrupos(tempList);
  }

  const handleClickProduto = (tam: TamProdProps, prod: ProdutoProps, gp: GrupoProps) => {
    prod.ingredientes.forEach((rs) => {
      rs.selecionado = true;
    });
    setTamanhoClicado(tam);
    setProdutoClicado(prod);
    setGrupoClicado(gp);

    if (gp.tipo_selec_prod === 2)
      openModalProdDivisor();
    else
      openModalProdQtd();
  }

  const openModalProdQtd = () => {
    setModalIsOpenProdQtd(true);
  }

  const openModalProdDivisor = () => {
    setModalIsOpenProdDivisor(true);
  }

  const closeModalProdQtd = (mostrarFaixa?: boolean) => {
    setModalIsOpenProdQtd(false);

    if (mostrarFaixa)
      showFaixaCliqueAqui();
  }

  const closeModalProdDivisor = (mostrarFaixa?: boolean) => {
    setModalIsOpenProdDivisor(false);

    if (mostrarFaixa)
      showFaixaCliqueAqui();
  }

  const showFaixaCliqueAqui = () => {
    const div = document.getElementById("faixaCliqueAqui");

    if (div) {
      div.style.width = "340px";
      div.style.opacity = "1";

      setTimeout(() => {
        div.style.width = "0px";
        div.style.opacity = "0";
      }, 4000);

    }
  }

  const closeModalCarrinho = async (limparCarrinho: boolean = false) => {
    setModalIsOpenCarrinho(false);

    if (limparCarrinho) {
      clearPedidos(codCliente);
      await loadMesas();
      setLoading(false);
      setTxtLoading("");
    }
  }

  const handleCarrinho = async () => {
    await loadMesas();
    setLoading(false);
    setModalIsOpenCarrinho(true);
  }

  const handlePesqProdGrupo = (idGrupo: number, value: string) => {
    const newListGroup: GrupoProps[] = listGrupos.map(item => {
      if (idGrupo === item.id)
        return { ...item, pesquisa: value };
      else
        return item;
    })

    setListGrupos(newListGroup);

    setTimeout(() => {
      newListGroup.forEach((rs) => {
        if (rs.id === idGrupo) {
          const divList = document.getElementById(`list-${idGrupo}`);
          const divListContent = document.getElementById(`list-content-${idGrupo}`);

          if (divList && divListContent)
            divList.style.height = `${(divListContent.clientHeight + 50)}px`;
        }
      });
    }, 500);
  }

  useEffect(() => {
    firstRun();
  }, []);

  useEffect(() => {
    if (produtos.length > 0)
      AplicaPromocoes();
  }, [produtos]);

  useEffect(() => {
    if (grupos)
      setListGrupos(grupos);
  }, [grupos]);

  useEffect(() => {
    let newQtd = 0;
    pedidos.forEach((rs) => {
      newQtd += rs.qtd / 1000;
    })
    setQtdCarrinho(newQtd);
  }, [pedidos]);

  useEffect(() => {
    if (empresa.qtd_mesa && empresa.qtd_mesa > 0) {
      const decripted = NrMesaDecript(urlNrMesa, empresa.qtd_mesa);
      setNrMesa(`${decripted}`);
    }
  }, [empresa]);

  useEffect(() => {
    if (nrMesa !== "0")
      loadMesas();
  }, [nrMesa]);

  useEffect(() => {
    if(configCores?.site_novo == 1)
      window.location.href = `https://pedidos.dcifood.com.br/mesas/${urlNrMesa}/${codCliente}`;
  }, [configCores]);

  return (
    <div id="page-mesa-cardapio">
      {loading && (
        <Load txtLoading={txtLoading} />
      )}

      {!loading && (
        <>
          <header>
            {empresa?.logo?.substring(empresa.logo.length - 14) !== "sem_imagem.jpg" && (
              <img src={empresa.logo} width={150} title="logo" alt="logo" />
            )}
          </header>

          <main>

            {!caixaAberto && (
              <div className="aviso">
                <FontAwesomeIcon icon={faExclamationCircle} style={{ fontSize: "60px", color: "#cd240e" }} />
                <div>
                  <h3>Delivery online fechado!</h3>
                  <p>Você poderá navegar normalmente, só não poderá finalizar o pedido.</p>
                </div>
              </div>
            )}

            <Input
              mask="none"
              value={pesquisa}
              inputMaskChange={setPesquisa}
              placeholder="Pesquise o produto..." />

            {pesquisa.length > 0 && (
              listProdutos?.filter(i => i.nome.toLowerCase().includes(pesquisa.toLowerCase())).map((rsProd) => {
                const tempGrupo = grupos.find(i => i.id === rsProd.id_grupos);
                if (tempGrupo?.in_app_mesa === 1) {
                  return (
                    rsProd.tamanhos.map((rsTamanho) => {
                      return (
                        <ItemProduto
                          key={`p-${rsProd.id}-${rsTamanho.id}`}
                          grupo={tempGrupo}
                          handleClick={(t, p, g) => handleClickProduto(t, p, g)}
                          produto={rsProd}
                          tamanho={rsTamanho}
                          bgColor="#c0c0c0"
                          mostrarGrupo
                        />
                      );
                    })
                  );
                }
              })
            )}

            {pesquisa.length <= 0 && (
              listGrupos?.map((rs) => {
                if (rs.in_app_mesa === 1) {
                  return (
                    <div key={`g-${rs.id}`}>
                      <div className="faixa-grupo" onClick={() => { handleGrupo(rs) }}>
                        <FontAwesomeIcon id={`icon-${rs.id}`} icon={faChevronRight} className="icone" />
                        <h3>{rs.nome}</h3>
                      </div>

                      <div className="lista-produtos" id={`list-${rs.id}`}>

                        <input
                          value={rs.pesquisa ?? ""}
                          className="customInput"
                          onChange={(e) => handlePesqProdGrupo(rs.id, e.target.value)}
                          placeholder={`Pesquise o produto em ${rs.nome}...`} />

                        <div className="list-produtos-content" id={`list-content-${rs.id}`}>
                          {listProdutos?.filter(i => i.id_grupos === rs.id && i.nome.toLowerCase().includes(rs.pesquisa ? rs.pesquisa.toLowerCase() : "")).map((rsProd) => {
                            return (
                              rsProd.tamanhos.map((rsTamanho) => {
                                return (
                                  <ItemProduto
                                    key={`p-${rsProd.id}-${rsTamanho.id}`}
                                    grupo={rs}
                                    handleClick={(t, p, g) => handleClickProduto(t, p, g)}
                                    produto={rsProd}
                                    tamanho={rsTamanho}
                                    bgColor="#c0c0c0"
                                  />
                                );
                              })
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  );
                }
              })
            )}
          </main>

          {!modalIsOpenCarrinho && caixaAberto && empresa.qr_code_cliente_pedido === 1 && (
            <>
              <div id="faixaCliqueAqui" onClick={() => handleCarrinho()}>
                <p>CLIQUE AQUI PARA FINALIZAR O PEDIDO</p>
              </div>
              <div className="carrinho" onClick={() => handleCarrinho()}>
                <p>{qtdCarrinho}</p>
                <FontAwesomeIcon icon={faShoppingBasket} color="#FFF" style={{ fontSize: "35px" }} />
              </div>
            </>
          )}

          <Modal isOpen={modalIsOpenProdQtd} style={styleModal}>
            <ProdutoQtdModal
              tamanho={tamanhoClicado}
              produto={produtoClicado}
              closeModal={(e) => closeModalProdQtd(e)}
              grupo={grupoClicado}
              showBtnComprar={empresa.qr_code_cliente_pedido === 1}
            />
          </Modal>

          <Modal isOpen={modalIsOpenProdDivisor} style={styleModal}>
            <ProdutoDivisorModal
              tamanho={tamanhoClicado}
              produto={produtoClicado}
              closeModal={(e) => closeModalProdDivisor(e)}
              grupo={grupoClicado}
              showBtnComprar={empresa.qr_code_cliente_pedido === 1}
            />
          </Modal>

          <Modal isOpen={modalIsOpenCarrinho} style={styleModal}>
            <CarrinhoMesa closeModal={(e) => closeModalCarrinho(e)} listComandas={listComandas} nrMesa={nrMesa} />
          </Modal>
        </>
      )}

    </div>
  );
}