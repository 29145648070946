import { ProdutoProps, GrupoProps, ConfigCoresProps, EmpresaProps, CaixaProps, CupomDto } from "../global/types";
import { getApiClient } from "./getApiClient";
// import { api } from "../services/api";

export const loadProdutos = async (codigoCliente: string): Promise<ProdutoProps[]> => {
  let produtos: ProdutoProps[] = [];

  await getApiClient(codigoCliente)
    .get(`${codigoCliente}/produtos`)
    .then(async (res) => {
      if (!res.data) throw new Error("Servidor indisponível");
      produtos = res.data;
    })
    .catch(() => {
      alert("Erro ao recuperar a lista de produtos. Tente novamente mais tarde.");
    });

  return produtos;
}

export const loadGrupos = async (codigoCliente: string): Promise<GrupoProps[]> => {
  let grupos: GrupoProps[] = [];

  await getApiClient(codigoCliente)
    .get(`${codigoCliente}/grupos`)
    .then(async (res) => {
      if (!res.data) throw new Error("Servidor indisponível");
      grupos = res.data;
    })
    .catch(() => {
      alert("Erro ao recuperar lista de grupos. Tente novamente mais tarde");
    });
  return grupos;
};

export const loadConfigCores = async (codigoCliente: string): Promise<ConfigCoresProps> => {
  let configCores: ConfigCoresProps = {} as ConfigCoresProps;

  await getApiClient(codigoCliente)
    .get(`apps/app_config_cod_cliente?codigo_cliente=${codigoCliente}`)
    .then(async (res) => {
      if (!res.data) throw new Error("Servidor indisponível");
      configCores = res.data;
    })
    .catch((err) => {
      if (err?.response?.data?.msg)
        if (err.response.data.msg !== "Empresa não encontrada")
          alert(err.response?.data?.msg);
        else
          alert("Erro ao recuperar as configurações de layout da Empresa");
    });

  return configCores;
}

export const VerificaCaixaAberto = async (codigo_cliente: string): Promise<boolean> => {
  let aberto: boolean = false;

  await getApiClient(codigo_cliente)
    .get(`${codigo_cliente}/verifica_caixa_aberto`)
    .then((res) => {
      if (!res.data)
        throw new Error("Servidor indisponível");

      aberto = res.data;
    })
    .catch(() => {
      aberto = false;
    })

  return aberto;
}

export const VerificaDeliveryWebOnline = async (codigo_cliente: string): Promise<boolean> => {
  try {
    const reqCaixa = await getApiClient(codigo_cliente).get(`${codigo_cliente}/getLastCaixa`)
    if (!reqCaixa.data)
      throw new Error("Servidor indisponível")

    const resCaixa: CaixaProps = reqCaixa.data

    return resCaixa.delivery_web_on === 1 ? true : false
  } catch (err) {
    return false
  }
}

export const loadConfig = async (codigo_cliente: string): Promise<EmpresaProps> => {
  let empresa = {} as EmpresaProps;

  await getApiClient(codigo_cliente)
    .get(`${codigo_cliente}/empresa`)
    .then((res) => {
      if (!res.data)
        throw new Error("Servidor indisponível");

      empresa = res.data as EmpresaProps;
    })
    .catch(() => {
      // alert("Erro ao recuperar configurações da Empresa");
    });

  return empresa;
}

export const loadPromocoes = async (codigoCliente: string): Promise<CupomDto[]> => {
  let itens: CupomDto[] = [];

  await getApiClient(codigoCliente)
    .post(`${codigoCliente}/varifica_promocao`)
    .then(async (res) => {
      if (!res.data) throw new Error("Servidor indisponível");
      itens = res.data;
    })
    .catch((err) => {
      console.log(err.response);
      alert("Erro ao recuperar a lista de promoções. Tente novamente mais tarde.");
    });

  return itens;
}