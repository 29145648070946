import axios from "axios";

export const api = axios.create({
  baseURL: "https://aws.dcifood.com.br/",
  headers: {
    "Authorization": "_garcom_digital_"
  }
})

export const apiAws = axios.create({
  baseURL: "https://aws.dcifood.com.br/",
  // baseURL: "http://api.dcifood.test/",
  headers: {
    "Authorization": "_garcom_digital_"
  }
})