import { faDesktop, faEdit, faExclamationTriangle, faPlus, faPrint, faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { LoadAdm } from "../../components/LoadAdm"
import { LoadLine } from "../../components/LoadLine"
import { useCache } from "../../hooks/useCache"
import Modal from "react-modal"
import "./style.css"
import { ModalControleAddCliente } from "../../components/ModalControleAddCliente"
import { Input } from "../../components/Input"
import { ModalControleRelVendas } from "../../components/ModalControleRelVendas"
import { EmpresaProps, RevendedoresProps, StatusWhatsaap } from "../../global/types"
import { ModalControleEditInfo } from "../../components/ModalControleEditInfo"
import { getApiClient, getApiControleClient } from "../../utils/getApiClient"
import { ModalObservacoes } from "../../components/ModalObservacoes"
import { apiNode } from "../../services/apiControle"
import QRCode from "react-qr-code";
import Lottie from "lottie-react-web"
import WhatsappRobot from "../../assets/whatsapp-robot.json";
import LottieLoad from "../../assets/loading_line.json";


export type IClientesCtrl = {
  id: number
  nome: string
  cod_cliente: string
  id_revendedor: number | null
  mensalidade: number
  revendedor: IInfoRevenda
  observacoes: string | null
  ativo: boolean
  ultPedido: string
  loading: boolean
  erro: boolean
  config?: EmpresaProps
  db_server: string | null
}

export type IInfoRevenda = {
  nome: string | null
  telefone: string | null
  celular: string | null
  email: string | null
}

type IListClientesCtrl = {
  index: number
  list: IClientesCtrl[]
}

const defaultModalStyle = {
  content: {
    padding: "0",
    width: window.innerWidth > 600 ? 600 : window.innerWidth - 30,
    height: window.innerHeight - 50,
    borderRadius: "10px",
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: "0px",
    background: "#f5f6f9"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: "4"
  }
};

export function ControleHome() {
  const navigate = useNavigate()

  const {
    controleUser,
    verificaControleUserLogado,
    socket,
  } = useCache()

  const [listRevendedores, setListRevendedores] = useState<RevendedoresProps[]>([])

  const [editingCliente, setEditingCliente] = useState<IClientesCtrl | undefined>()

  const [loading, setLoading] = useState(true)
  const [txtLoading, setTxtLoading] = useState("")

  const [listClientes, setListClientes] = useState<IListClientesCtrl>({} as IListClientesCtrl)
  const [divOpened, setDivOpened] = useState("")

  const [clientesTotal, setClientesTotal] = useState(0)
  const [clientesAtivos, setClientesAtivos] = useState(0)
  const [clientesBloq, setClientesBloq] = useState(0)

  const [changeCliente, setChangeCliente] = useState<IClientesCtrl>({} as IClientesCtrl)
  const [pesquisa, setPesquisa] = useState("")

  const [filterStatus, setFilterStatus] = useState<"todos" | "liberados" | "bloqueados">("todos")
  const [filterServer, setFilterServer] = useState<"todos" | "01" | "02">("todos")

  const [modalAdd, setModalAdd] = useState(false)
  const [modalRelVendas, setModalRelVendas] = useState(false)
  const [modalOpenEditCliente, setModalOpenEditCliente] = useState(false)
  const [modalObservacoes, setModalObservacoes] = useState(false)

  const [clienteSelected, setClientesSelected] = useState<IClientesCtrl | undefined>(undefined)
  const [relVendaCliente, setRelVendaCliente] = useState("")

  const [statusWhatsaap, setStatusWhatsaap] = useState<StatusWhatsaap | undefined>(undefined)

  const handleOpenCadCliente = (type: "open" | "close", cliente?: IClientesCtrl) => {
    setEditingCliente(cliente ? cliente : undefined)
    setModalAdd(type === "open")
  }

  const handleOpenObservacoes = (type: "open" | "close", cliente?: IClientesCtrl) => {
    setModalObservacoes(!modalObservacoes)
    setClientesSelected(cliente ? cliente : undefined)
  }

  const verificaLogin = useCallback(() => {

    const check = verificaControleUserLogado();

    if (!check)
      navigate("/controle/login");
  }, [])

  const loadClientes = useCallback(async () => {
    setLoading(true)
    setTxtLoading("Carregando lista de Clientes...")

    try {
      // HOSTGATOR - ** MUDAR **
      const reqClientes = await getApiControleClient("hostgator").get("clientes")

      if (!reqClientes.data)
        throw new Error("Servidor indisponível");

      const newList: IClientesCtrl[] = []

      reqClientes.data?.forEach((rs: any) => {
        newList.push({
          ...rs,
          ativo: true,
          ultPedido: "",
          loading: true,
          erro: false,
        })
      })

      setListClientes({
        index: 0,
        list: newList
      })

      setTxtLoading("Carregando lista de Revendedores...")

      // HOSTGATOR - ** MUDAR **
      const reqRevendedores = await getApiControleClient("hostgator").get("revendedores")

      if (!reqRevendedores.data)
        throw new Error("Servidor indisponível");

      const newListRevendedores: RevendedoresProps[] = []

      reqRevendedores.data.forEach((item: RevendedoresProps) => {
        newListRevendedores.push(item)
      })

      setListRevendedores(newListRevendedores)

      setLoading(false)
      setTxtLoading("")

    } catch (err: any) {
      if (err.response?.data?.msg && (err.response.data.msg === "Token inválido" || err.response.data.msg == "Senha incorreta"))
        navigate("/controle/login");
      else if (err.response?.data?.msg)
        alert(err.response.data.msg)
      else
        alert("Erro inesperado. Tente novamente mais tarde")
    }
  }, [])

  const loadInfoCliente = useCallback(async (list: IListClientesCtrl) => {
    const index = list.index
    const cliente = list.list[index]

    const newList: IClientesCtrl[] = []

    try {
      const reqConfig = await getApiClient(cliente.cod_cliente).get(`${cliente.cod_cliente}/empresa`)

      if (!reqConfig.data)
        throw new Error("Erro ao recuperar informações");

      const clienteEnable = reqConfig.data.ativo === 1 ? true : false

      const reqUltPed = await getApiClient(cliente.cod_cliente).get(`${cliente.cod_cliente}/ult_ped`)

      if (!reqUltPed.data)
        throw new Error("Erro ao recuperar informações");

      const clienteUltPed = reqUltPed.data.ult_ped

      list.list.forEach((item, i) => {
        if (i === index)
          newList.push({
            ...item,
            ativo: clienteEnable,
            ultPedido: clienteUltPed,
            loading: false,
            config: reqConfig.data
          })
        else {
          if (changeCliente.id && changeCliente.id === item.id) {
            newList.push({ ...changeCliente })
            setChangeCliente({} as IClientesCtrl)
          } else
            newList.push(item)
        }
      })

      setListClientes({
        index: index + 1,
        list: newList
      })
    } catch (err: any) {
      console.log(err)
      list.list.forEach((item, i) => {
        if (i === index)
          newList.push({ ...item, erro: true, loading: false })
        else
          newList.push(item)
      })
    }

  }, [setListClientes, changeCliente])

  const handleClickSwitch = async (cliente: IClientesCtrl, enable: boolean, index: number) => {
    if (!window.confirm(`Deseja realmente ${enable ? "ativar" : "desativar"} o cliente ${cliente.nome}?`))
      return

    let rsLoad = {} as IClientesCtrl
    const newList: IClientesCtrl[] = []
    listClientes.list.forEach((item, i) => {
      if (i === index) {
        newList.push({ ...item, loading: true })
        rsLoad = { ...item, loading: true }
      } else
        newList.push(item)
    })

    if (listClientes.index < listClientes.list.length) {
      setChangeCliente(rsLoad)
    } else
      setListClientes(e => ({ ...e, list: newList }))

    await getApiClient(cliente.cod_cliente).post(`${cliente.cod_cliente}/empresa_enable_disable`, { ativo: enable })
      .then(res => {
        const newListRes: IClientesCtrl[] = []
        let rs: IClientesCtrl = {} as IClientesCtrl
        listClientes.list.forEach((item, i) => {
          if (i === index) {
            newListRes.push({ ...item, loading: false, erro: false, ativo: res.data.ativo })
            rs = { ...item, loading: false, erro: false, ativo: res.data.ativo }
          } else
            newListRes.push(item)
        })

        if (listClientes.index < listClientes.list.length)
          setChangeCliente(rs)
        else
          setListClientes(e => ({ ...e, list: newListRes }))
      })
      .catch(() => {
        const newListErr: IClientesCtrl[] = []
        let rs: IClientesCtrl = {} as IClientesCtrl

        listClientes.list.forEach((item, i) => {
          if (i === index) {
            newListErr.push({ ...item, loading: false, erro: true })
            rs = { ...item, loading: false, erro: true }
          } else
            newListErr.push(item)
        })

        if (listClientes.index < listClientes.list.length)
          setChangeCliente(rs)
        else
          setListClientes(e => ({ ...e, list: newListErr }))
      })
  }

  const loadTotais = useCallback((list: IListClientesCtrl) => {
    if (!list.list)
      return

    let total = 0,
      ativos = 0,
      bloqueados = 0

    list.list.forEach(rs => {
      total++
      if (!rs.loading && !rs.erro) {
        ativos += rs.ativo ? 1 : 0
        bloqueados += rs.ativo ? 0 : 1
      }
    })

    setClientesAtivos(ativos)
    setClientesBloq(bloqueados)
    setClientesTotal(total)
  }, [])

  const handleAddCliente = async (nome: string, cod_cliente: string, id_revendedor: number | null, mensalidade: number, type: "create" | "update") => {
    setLoading(true)
    setTxtLoading("Cadastrando cliente no painel de controle...")

    setModalAdd(false)

    let dataRequest: any = {
      id_revendedor,
      nome,
      cod_cliente,
      mensalidade,
    }

    if (type === "update")
      dataRequest = {
        id: editingCliente?.id,
        ...dataRequest,
      }

    // HOSTGATOR - ** MUDAR **
    await getApiControleClient("hostgator").post("clientes", dataRequest)
      .then(res => {
        if (!res.data)
          return

        const revendedor: RevendedoresProps | undefined = listRevendedores.find(e => e.id === id_revendedor)

        if (type === "create")
          setListClientes(e => ({
            ...e,
            list: [
              ...e.list,
              {
                id: res.data.id,
                id_revendedor,
                nome,
                cod_cliente,
                mensalidade,
                observacoes: res.data.observacoes,
                db_server: res.data.db_server,
                revendedor: {
                  nome: revendedor ? revendedor.fantasia : null,
                  celular: revendedor ? revendedor.celular : null,
                  email: revendedor ? revendedor.email : null,
                  telefone: revendedor ? revendedor.telefone : null
                },

                ativo: true,
                erro: false,
                loading: true,
                ultPedido: "",
              }
            ]
          }))
        else {
          const newList: IClientesCtrl[] = []

          listClientes.list.forEach(item => {
            if (item.id === editingCliente?.id)
              newList.push({
                ...item,
                id_revendedor,
                nome,
                cod_cliente,
                mensalidade,
              })
            else
              newList.push(item)
          })

          setListClientes(e => ({
            ...e,
            list: newList
          }))

        }
      })
      .catch(err => {
        if (err.response?.data?.msg && (err.response.data.msg === "Token inválido" || err.response.data.msg === "Senha incorreta"))
          navigate("/controle/login");
        else if (err.response?.data?.msg)
          alert(err.response.data.msg)
        else
          alert("Erro ao cadastrar cliente. Tente novamente mais tarde")
      })
      .finally(() => {
        setLoading(false)
        setTxtLoading("")
      })

  }

  const handleOpenRelVendas = (cliente: string) => {
    setModalRelVendas(true)
    setRelVendaCliente(cliente)
  }

  const handleEditCliente = (item: IClientesCtrl) => {
    if (item.config?.fantasia) {
      setClientesSelected(item)
      setModalOpenEditCliente(true)
    }
  }

  const handleUpdateInfoCliente = (item: IClientesCtrl) => {
    const newList: IClientesCtrl[] = []

    listClientes.list.forEach(e => {
      newList.push(e.id === item.id ? item : e)
    });

    setListClientes(e => ({ ...e, list: newList }))
  }

  const loadStatusWhats = async () => {
    try {

      const req = await apiNode.get('wa/getSession');

      if (!req.data)
        throw new Error("Servidor indisponível");

      setStatusWhatsaap(req.data)

      socket.on("sessionWhatsapp", data => {
        setStatusWhatsaap(data)
      })

    } catch (err: any) {
      // setStatusWhatsaap({
      //   connected: false,
      //   loading: false,
      //   qr: "2@xymd2jURNqbDhvhN9yCWdrvVnaxlyKn5QANXREF7OnBch6P5sSWObuH3BY/GgICw/p+3oiisXA48fA==,tKhEpnVgCp/R11MxyGjavDKXgc1/iqbt2rPOw1f/Y0A=,OLx+hcS4TqC2T8Yikm/sZ2JsJoyozr5JA3oFah3Xa0k=,9c62oF/wamQwj4a+6bvlhuCtjc0IjaFu2NRmqD3laJA="
      // })
      if (err.response?.data?.msg)
        alert(err.response.data.msg)
      else
        alert("Erro inesperado ao buscar informações do WhatsApp. Tente novamente mais tarde")
    }
  }

  const handleOpenChamaGarcom = () => {
    const pdfWindow = window.open()

    if (pdfWindow !== null)
      pdfWindow.location.href = "https://chamagarcom.dcifood.com.br/adm"
  }

  useEffect(() => {
    verificaLogin()
  }, [verificaLogin])

  useEffect(() => {
    if (controleUser.id && controleUser.id > 0) {
      loadClientes()
      // loadStatusWhats()
    }
  }, [controleUser, loadClientes])

  useEffect(() => {
    if (listClientes.list?.length > 0 && listClientes.list?.length > listClientes.index)
      loadInfoCliente(listClientes)

    loadTotais(listClientes)
  }, [listClientes])

  return (
    <div id="pgControleHome">

      {loading && (
        <LoadAdm txtLoading={txtLoading} />
      )}

      {!loading && (
        <>
          <div className="dvTotal">
            <h2 style={{ flex: 1, marginLeft: "180px" }}>Controle de Clientes</h2>
            <button className="btnGreen" style={{ width: "180px", alignSelf: "flex-end" }} onClick={() => { handleOpenChamaGarcom() }}>
              <FontAwesomeIcon icon={faDesktop} />
              Chama Garçom
            </button>
          </div>

          <div className="dvTotal">
            <div style={{ fontSize: "13px" }} className={`painel ${filterStatus === "todos" ? "selectedStatus" : "filterStatus"}`} onClick={() => setFilterStatus("todos")}>
              <b>Total de Clientes:</b>
              <i>{clientesTotal}</i>
            </div>

            <div style={{ fontSize: "13px" }} className={`painel ${filterStatus === "liberados" ? "selectedStatus" : "filterStatus"}`} onClick={() => setFilterStatus("liberados")}>
              <b>Ativos:</b>
              <i>{clientesAtivos}</i>
            </div>

            <div style={{ fontSize: "13px" }} className={`painel ${filterStatus === "bloqueados" ? "selectedStatus" : "filterStatus"}`} onClick={() => setFilterStatus("bloqueados")}>
              <b>Bloqueados:</b>
              <i>{clientesBloq}</i>
            </div>
          </div>


          <div className="dvTotal">
            <div
              style={{ fontSize: "13px" }}
              className={`painel ${filterServer === "todos" ? "selectedStatus" : "filterStatus"}`}
              onClick={() => setFilterServer("todos")}
            >
              <p><b>DB Server: </b> Todos</p>
            </div>

            <div
              style={{ fontSize: "13px" }}
              className={`painel ${filterServer === "01" ? "selectedStatus" : "filterStatus"}`}
              onClick={() => setFilterServer("01")}
            >
              <p><b>DB Server 01:</b> {listClientes.list.filter(e => e.db_server === "01").length}</p>
            </div>

            <div
              style={{ fontSize: "13px" }}
              className={`painel ${filterServer === "02" ? "selectedStatus" : "filterStatus"}`}
              onClick={() => setFilterServer("02")}
            >
              <p><b>DB Server 02:</b> {listClientes.list.filter(e => e.db_server === "02").length}</p>
            </div>
          </div>


          <div style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center"
          }}>
            <div style={{}}>
              {statusWhatsaap?.loading && (
                <Lottie options={{ animationData: LottieLoad }} width={100} height={100} />
              )}

              {!statusWhatsaap?.loading && statusWhatsaap?.connected && (
                <Lottie options={{ animationData: WhatsappRobot }} width={100} height={100} />
              )}

              {!statusWhatsaap?.loading && !statusWhatsaap?.connected && statusWhatsaap?.qr && (
                <>
                  <p style={{ fontSize: "16px", fontWeight: "bold", color: "#00a884", textAlign: "center" }}>WhatsApp</p>
                  <QRCode
                    value={statusWhatsaap.qr}
                    size={150}
                  />
                </>
              )}
            </div>

            <div style={{ flex: "1", width: '100%', justifyContent: "end", display: "flex" }}>
              <button className="btnBlue" style={{ width: "180px", alignSelf: "flex-end" }} onClick={() => handleOpenCadCliente("open")}>
                <FontAwesomeIcon icon={faPlus} />
                Adicionar Cliente
              </button>
            </div>

          </div>

          <Input
            mask="none"
            value={pesquisa}
            inputMaskChange={setPesquisa}
            placeholder="Pesquisa por cliente"
            style={{ marginTop: "10px" }}
          />


          {listClientes.list?.length > 0 && listClientes.list.map((item, i) => {

            if (
              item.nome.toLocaleLowerCase().includes(pesquisa.toLocaleLowerCase())
              &&
              (
                filterStatus === "todos"
                || (filterStatus === "liberados" && item.ativo)
                || (filterStatus === "bloqueados" && !item.ativo)
              )
              &&
              (
                filterServer === "todos"
                || (filterServer === item.db_server)
              )
            )
              return (
                <div className="painel" key={`lC-${item.id}`}>

                  {item.loading && (
                    <LoadLine />
                  )}

                  {item.loading && item.erro && (
                    <FontAwesomeIcon icon={faExclamationTriangle} color="#bb2323" style={{ fontSize: "30px" }} />
                  )}

                  {!item.loading && !item.erro && item.ativo && (
                    <FontAwesomeIcon icon={faToggleOn} color="#4ebb56" style={{ fontSize: "30px", cursor: "pointer" }} onClick={() => handleClickSwitch(item, false, i)} />
                  )}

                  {!item.loading && !item.erro && !item.ativo && (
                    <FontAwesomeIcon icon={faToggleOff} color="#bb2323" style={{ fontSize: "30px", cursor: "pointer" }} onClick={() => handleClickSwitch(item, true, i)} />
                  )}

                  <div className="info" style={{ height: divOpened === `${item.id}` ? "211px" : "70px" }}>
                    <div style={{ cursor: "pointer" }} onClick={() => setDivOpened(e => `${item.id}` === e ? "" : `${item.id}`)}>
                      <p>{item.nome} <small>({item.cod_cliente})</small></p>
                      <p style={{ fontSize: "14px" }}>
                        <b>DB Server: </b> {item.db_server}
                      </p>
                      <span>
                        <b>Último pedido em:</b>
                        <i>
                          {item.loading && (" - - - ")}
                          {!item.loading && !item.erro && (item.ultPedido)}
                          {item.erro && ("Erro ao carregar informações ")}
                        </i>
                      </span>
                    </div>

                    <hr />

                    <div className="infoConfig">
                      <span>Responsável: {item.revendedor.nome ? item.revendedor.nome : "Data Control"}</span>
                      <span>Qtd de Mesas: {item.config?.qtd_mesa}</span>
                      <span>Qtd Celulares: {item.config?.qtd_mobile}</span>
                      <div style={{ display: "flex", flexDirection: "row", gap: "5px", width: "100%" }}>
                        <button className="btnOrange" onClick={() => handleEditCliente(item)} disabled={item.config?.qtd_mobile ? false : true}>
                          <FontAwesomeIcon icon={faEdit} />
                          Editar informações
                        </button>

                        <button className="btnBlue" onClick={() => handleOpenRelVendas(item.cod_cliente)}>
                          <FontAwesomeIcon icon={faPrint} />
                          Relatório de Vendas
                        </button>

                        <button className="btnDarkBlue" onClick={() => handleOpenCadCliente("open", item)}>
                          <FontAwesomeIcon icon={faEdit} />
                          Editar Cliente
                        </button>

                        <button className="btnGreen" onClick={() => handleOpenObservacoes('open', item)}>
                          <FontAwesomeIcon icon={faEdit} />
                          Observações
                        </button>
                      </div>
                    </div>

                  </div>
                </div>
              )

          })}

        </>
      )}

      <Modal isOpen={modalAdd} style={{ ...defaultModalStyle, content: { ...defaultModalStyle.content, height: "280px" } }}>
        <ModalControleAddCliente
          closeModal={() => handleOpenCadCliente("close")}
          cadastroCliente={(n, c, i, m, t) => handleAddCliente(n, c, i, m, t)}
          listRevendedores={listRevendedores}
          cliente={editingCliente}
        />
      </Modal>

      <Modal isOpen={modalRelVendas} style={{ ...defaultModalStyle, content: { ...defaultModalStyle.content, height: "280px" } }}>
        <ModalControleRelVendas closeModal={() => setModalRelVendas(false)} empresaSelected={relVendaCliente} />
      </Modal>

      <Modal isOpen={modalOpenEditCliente} style={{ ...defaultModalStyle, content: { ...defaultModalStyle.content, height: "230px" } }}>
        <ModalControleEditInfo
          closeModal={() => setModalOpenEditCliente(false)}
          UpdateInfoCliente={(e) => handleUpdateInfoCliente(e)}
          cliente={clienteSelected}
        />
      </Modal>

      <Modal isOpen={modalObservacoes} style={{ ...defaultModalStyle, content: { ...defaultModalStyle.content, height: "auto", width: "675px" } }}>
        <ModalObservacoes
          closeModal={() => setModalObservacoes(!modalObservacoes)}
          updateInfoCliente={(e) => handleUpdateInfoCliente(e)}
          cliente={clienteSelected}
        />
      </Modal>

    </div>
  )
}