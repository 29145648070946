import { createContext, ReactNode, useState, useEffect } from "react";
import { AES, enc } from "crypto-js";
import { io, Socket } from "socket.io-client";

import {
  GrupoProps,
  ProdutoProps,
  ConfigCoresProps,
  GpAdicionaisProps,
  AddPedidoProps,
  EmpresaProps,
  storageCliente,
  GpObservacoesProps,
  AdmUser,
  CupomDto
} from "../global/types";

import {
  loadGrupos,
  loadProdutos,
  loadConfigCores,
  loadConfig,
  loadPromocoes
} from "../utils/loadCache";
import { getApiAdmClient, getApiControleClient } from "../utils/getApiClient";

type CacheContextType = {
  admUser: AdmUser;
  controleUser: AdmUser;
  promocoes: CupomDto[];
  produtos: ProdutoProps[];
  grupos: GrupoProps[];
  configCores: ConfigCoresProps;
  adicionais: GpAdicionaisProps[];
  observacoes: GpObservacoesProps[];
  pedidos: AddPedidoProps[];
  empresa: EmpresaProps;
  buscaPromocoes: (codigoCliente: string) => Promise<void>;
  buscaProdutos: (codigoCliente: string) => Promise<void>;
  buscaGrupos: (codigoCliente: string) => Promise<void>;
  buscaConfigCores: (codigoCliente: string) => Promise<void>;
  gravaAdicionais: (items: GpAdicionaisProps[]) => void;
  gravaObservacoes: (items: GpObservacoesProps[]) => void;
  gravaPedidos: (items: AddPedidoProps[]) => void;
  refreshPedidos: (key: string) => void;
  clearPedidos: (key: string) => void;
  updatePedidos: (items: AddPedidoProps[]) => void;
  buscaEmpresa: (codigoCliente: string) => Promise<void>;
  gravaCliente: (idCliente: number, telefone: string, nome: string) => void;
  getIdCliente: () => number;
  clearCliente: () => void;
  clearAdmUser: () => void;
  clearControleUser: () => void;
  cliente: storageCliente;
  gravaAdmUser: (data: AdmUser) => void;
  gravaControleUser: (data: AdmUser) => void;
  verificaAdmUserLogado: () => boolean;
  verificaControleUserLogado: () => boolean;
  socket: Socket;
};

type CacheContextProps = {
  children: ReactNode;
}

const secretKey = "2e3c65a31ece6c489f1815037e2477c6d2fc1e09";

export const CacheContext = createContext({} as CacheContextType);

export function CacheContextProvider(props: CacheContextProps) {

  const [promocoes, setPromocoes] = useState<CupomDto[]>([]);
  const [produtos, setProdutos] = useState<ProdutoProps[]>([]);
  const [grupos, setGrupos] = useState<GrupoProps[]>([]);
  const [configCores, setConfigCores] = useState<ConfigCoresProps>({} as ConfigCoresProps);
  const [adicionais, setAdicionais] = useState<GpAdicionaisProps[]>([]);
  const [observacoes, setObservacoes] = useState<GpObservacoesProps[]>([]);
  const [pedidos, setPedidos] = useState<AddPedidoProps[]>([]);
  const [empresa, setEmpresa] = useState<EmpresaProps>({} as EmpresaProps);
  const [codCliente, setCodCliente] = useState<string>("");
  const [cliente, setCliente] = useState<storageCliente>({} as storageCliente);

  const [admUser, setAdmUser] = useState<AdmUser>({} as AdmUser);
  const [controleUser, setControleUser] = useState<AdmUser>({} as AdmUser);

  const socket = io('https://node.dcifood.com.br', { transports: ["websocket"] });

  const buscaPromocoes = async (codigoCliente: string): Promise<void> => {
    const tempPromocoes: CupomDto[] = await loadPromocoes(codigoCliente);
    setPromocoes(tempPromocoes);
  }

  const buscaProdutos = async (codigoCliente: string): Promise<void> => {
    const tempProdutos: ProdutoProps[] = await loadProdutos(codigoCliente);
    setProdutos(tempProdutos);
  }

  const buscaGrupos = async (codigoCliente: string): Promise<void> => {
    const tempGrupos: GrupoProps[] = await loadGrupos(codigoCliente);
    setGrupos(tempGrupos);
  }

  const buscaConfigCores = async (codigoCliente: string): Promise<void> => {
    if (!configCores.nome) {
      const tempConfigCores: ConfigCoresProps = await loadConfigCores(codigoCliente);
      setConfigCores(tempConfigCores);
    }
  }

  const gravaAdicionais = (items: GpAdicionaisProps[]) => {
    setAdicionais((i) => [...i, ...items]);
  }

  const gravaObservacoes = (items: GpObservacoesProps[]) => {
    setObservacoes((i) => [...i, ...items]);
  }

  const gravaPedidos = (items: AddPedidoProps[]) => {
    setPedidos((i) => [...i, ...items]);
  }

  const updatePedidos = (items: AddPedidoProps[]) => {
    setPedidos(items);

    if (items.length === 0)
      localStorage.removeItem(codCliente);
  }

  const refreshPedidos = (key: string) => {
    const storage = localStorage.getItem(key);
    setCodCliente(key);
    if (storage && pedidos.length <= 0)
      gravaPedidos(JSON.parse(storage) as AddPedidoProps[]);
  }

  const buscaEmpresa = async (codigoCliente: string) => {
    if (!empresa.fantasia) {
      const tempEmpresa = await loadConfig(codigoCliente);
      setEmpresa(tempEmpresa);
    }
  }

  const clearPedidos = (key: string) => {
    setPedidos([]);
    localStorage.removeItem(codCliente);
  }

  const clearCliente = () => {
    setCliente({} as storageCliente);
    localStorage.removeItem(`cliente_${codCliente}`);
  }

  const clearAdmUser = () => {
    setAdmUser({} as AdmUser);
    localStorage.removeItem(`dciFood-adm-usuario`);
  }

  const clearControleUser = () => {
    setControleUser({} as AdmUser);
    localStorage.removeItem(`dciFood-controle-usuario`);
  }

  const gravaCliente = (idCliente: number, telefone: string, nome: string) => {
    const idCrypto = AES.encrypt(`${idCliente}`, secretKey).toString();

    setCliente({ idCliente: idCrypto, telefone, nome });
  }

  const getIdCliente = (): number => {

    if (!cliente.idCliente || cliente.idCliente.length === 0)
      return 0;

    const bytes = AES.decrypt(cliente.idCliente, secretKey);
    const idCLiente = bytes.toString(enc.Utf8);

    return parseInt(idCLiente);
  }

  const gravaAdmUser = (data: AdmUser) => {
    setAdmUser(data);
  }

  const gravaControleUser = (data: AdmUser) => {
    setControleUser(data);
  }

  const verificaAdmUserLogado = (): boolean => {
    if (!admUser.id || admUser.id <= 0) {
      const storage = localStorage.getItem(`dciFood-adm-usuario`);

      if (storage) {
        const newAdmUser = JSON.parse(storage) as AdmUser;
        setAdmUser(newAdmUser);
        // HOSTGATOR - ** MUDAR **
        getApiAdmClient("hostgator").defaults.headers.common["Authorization"] = `${newAdmUser.token}`;
      }
      else
        return false;
    }
    return true;
  }

  const verificaControleUserLogado = (): boolean => {
    if (!controleUser.id || controleUser.id <= 0) {
      const storage = localStorage.getItem(`dciFood-controle-usuario`);

      if (storage) {
        const newControleUser = JSON.parse(storage) as AdmUser;
        setControleUser(newControleUser);
        // HOSTGATOR - ** MUDAR **
        getApiControleClient("hostgator").defaults.headers.common["Authorization"] = `${newControleUser.token}`;
      }
      else
        return false;
    }
    return true;
  }

  useEffect(() => {
    if (cliente.telefone && cliente.telefone.length > 0)
      localStorage.setItem(`cliente_${codCliente}`, JSON.stringify(cliente));
  }, [cliente, codCliente]);

  useEffect(() => {
    if (pedidos && pedidos.length > 0)
      localStorage.setItem(codCliente, JSON.stringify(pedidos));
  }, [pedidos, codCliente]);

  useEffect(() => {

    const storage = localStorage.getItem(`cliente_${codCliente}`);

    if (storage)
      setCliente(JSON.parse(storage) as storageCliente);

  }, [codCliente]);

  useEffect(() => {
    if (admUser.id && admUser.id > 0)
      localStorage.setItem(`dciFood-adm-usuario`, JSON.stringify(admUser));
  }, [admUser]);

  useEffect(() => {
    if (controleUser.id && controleUser.id > 0)
      localStorage.setItem(`dciFood-controle-usuario`, JSON.stringify(controleUser));
  }, [controleUser]);

  return (
    <CacheContext.Provider value={{
      promocoes,
      produtos,
      grupos,
      configCores,
      adicionais,
      observacoes,
      pedidos,
      empresa,
      buscaPromocoes,
      buscaProdutos,
      buscaGrupos,
      buscaConfigCores,
      gravaAdicionais,
      gravaObservacoes,
      gravaPedidos,
      refreshPedidos,
      clearPedidos,
      updatePedidos,
      buscaEmpresa,
      gravaCliente,
      getIdCliente,
      clearCliente,
      cliente,
      admUser,
      controleUser,
      gravaAdmUser,
      gravaControleUser,
      clearAdmUser,
      clearControleUser,
      verificaAdmUserLogado,
      verificaControleUserLogado,
      socket,
    }}>
      {props.children}
    </CacheContext.Provider>
  );

}