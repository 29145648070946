import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import { useCache } from "../../hooks/useCache";
import { ItemProduto } from "../ItemProduto";
import { ProdutoProps, TamProdProps, GrupoProps } from "../../global/types";

import "./style.css";

type ParamsProps = {
  tamanho: TamProdProps;
  grupo: GrupoProps;
  closeModal: () => void;
  clickProduto: (produto: ProdutoProps) => void;
};

export function ModalListProdutos({ tamanho, grupo, closeModal, clickProduto }: ParamsProps) {
  const { produtos, configCores } = useCache();

  const [listProdutos, setListProdutos] = useState<ProdutoProps[]>([]);

  const handleClickProduto = (tam: TamProdProps, prod: ProdutoProps, gp: GrupoProps) => {
    clickProduto(prod);
    closeModal();
  };

  useEffect(() => {
    const tempList: ProdutoProps[] = [];

    produtos.filter(i => i.id_grupos === grupo.id).forEach((rs) => {
      tempList.push(rs);
    })

    setListProdutos(tempList);
  }, [tamanho, grupo]);

  return (
    <div id="ModalListProdutos">
      <div className="header" style={{ backgroundColor: configCores?.cor_1, color: configCores?.cor_fonte }}>
        <h3>Escolha o sabor para divisão</h3>
        <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer" }} onClick={closeModal} />
      </div>

      <div className="content">
        <div className="list-produtos-content">
          {listProdutos.map((rsProd) => {
            return (
              rsProd.tamanhos.filter(i => i.id_tamanhos === tamanho.id_tamanhos).map((rsTamanho) => {
                return (
                  <ItemProduto key={`p-${rsProd.id}-${rsTamanho.id}`} grupo={grupo} handleClick={handleClickProduto} produto={rsProd} tamanho={rsTamanho} bgColor="#EEE" />
                );
              })
            );
          })}
        </div>
      </div>
    </div>
  );
}