import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

export function convertToUTC(date: Date): string {
  dayjs.extend(utc);
  // return dayjs(date).utc().local().format('DD/MM/YYYY HH:mm');
  return dayjs(date).utc().local().format('DD/MM/YYYY HH:mm');
}

export function convertDateToBr(date: Date): string {
  dayjs.extend(utc);
  return dayjs(date).utc().local().format('DD/MM/YYYY');
}

export function getHourMin(date: Date): string {
  dayjs.extend(utc);
  return dayjs(date).utc().local().format('HH:mm');
}

export function getMesAno(date: Date): string {
  dayjs.extend(utc);
  return dayjs(date).utc().local().format('MM/YYYY');
}

export function convertToDate(date: string): Date {
  const data = date.split("/");
  return dayjs(`${data[2]}-${data[1]}-${data[0]}`).toDate();
}

export function convertDataHora(date: string): Date {
  const data = date.split(" ")[0].split("/");
  const hora = date.split(" ")[1].split(":");
  return dayjs(`${data[2]}-${data[1]}-${data[0]} ${hora[0]}:${hora[1]}:00`).toDate();
}

export function convertDayJs(date: string): Date {
  return dayjs(date).toDate();
}

export function dateNow(): Date {
  return dayjs().toDate();
}

export function convertToUs(date: string): string {
  const data = date.split("/");
  return dayjs(`${data[2]}-${data[1]}-${data[0]}`).utc().local().format("YYYY-MM-DD");
}

export function addYears(years: number): string {
  const data = dayjs().add(years, "years");
  return data.format("DD/MM/YYYY");
}

export function addMonths(months: number): Date {
  const data = dayjs().add(months, "months").toDate();
  return data;
}

export function compareInDays(start_date: Date, end_date: Date): number {
  const start_date_utc = convertToUTC(start_date);
  const end_date_utc = convertToUTC(end_date);
  return dayjs(end_date_utc).diff(start_date_utc, "days");
}