import './style.css';
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TModalObservacoes } from "./type";
import { Editor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';
import { useRef, useState } from 'react';
import { LoadAdm } from '../LoadAdm';
import { getApiControleClient } from '../../utils/getApiClient';


export function ModalObservacoes({ closeModal, updateInfoCliente, cliente }: TModalObservacoes) {
  const [loading, setLoading] = useState(false)
  const [txtLoading, setTxtLoading] = useState("")

  const editorRef = useRef<TinyMCEEditor | null>(null);

  async function handleSaveObservacao() {
    if (!cliente) return
    
    if (editorRef.current) {

      updateInfoCliente({ ...cliente, observacoes: editorRef.current.getContent() })

      setLoading(true)
      setTxtLoading("Salvando informações")

      await getApiControleClient("hostgator").post(`clientes`, {
        ...cliente,
        observacoes: editorRef.current.getContent(),
      })
      .then(res => {
        if (!res.data)
          throw new Error("Servidor indisponível");
      })
      .catch(err => {
        if (err.response?.data?.msg)
          alert(err.response.data.msg)
        else if (err.message)
          alert(err.message)
        else
          alert("Erro ao salvar dados do cliente. Tente novamente mais tarde")
      })
      .finally(() => {
        setLoading(false)
        setTxtLoading("")
        closeModal()
      })
    }
  }

  return (
    <div id="ModalObservacoes">
      <div className="header">
        <h3>Observações ({cliente?.nome})</h3>
        <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer" }} onClick={closeModal} />
      </div>

      {loading && (
        <LoadAdm txtLoading={txtLoading} options={{ height: 150, width: 150 }} />
      )}

      {!loading && (<div className="content">
        <p style={{ fontWeight: "600" }}>Observação:</p>
        <Editor
          apiKey='a0pfn4fhth137hteyb9tbsfetf2i3h7228ou0mpwuh6y189f'
          onInit={(evt, editor) => editorRef.current = editor}
          init={{
            skin: "snow",
            icons: "thin",
            width: '100%',
            height: 500,
            menubar: true,
            plugins: [
              "advlist autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code fullscreen textcolor ",
              "insertdatetime media table paste code help wordcount",
              "insertdatetime"
            ],
            textcolor_rows: "4",
            toolbar:
              "undo redo | styleselect | fontsizeselect| code | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | insertdatetime"
          }}
          initialValue={cliente?.observacoes || ''}
        />
        <button type="button" className='btn-obs' onClick={handleSaveObservacao}>
          <FontAwesomeIcon icon={faCheck} />
          FINALIZAR
        </button>
      </div>)}
    </div>
  )
}