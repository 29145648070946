import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faShoppingBasket, faExclamationCircle, faPhoneAlt, faMapMarkerAlt, faClock, faCoins, faConciergeBell, faList } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";

import { useCache } from "../../hooks/useCache";
import { VerificaCaixaAberto, VerificaDeliveryWebOnline } from "../../utils/loadCache";
import { maskPhone } from "../../utils/masks";
import { currencyFormatter } from "../../utils/currencyFormatter";

import { Load } from "../../components/Load";
import { ProdutoQtdModal } from "../../components/ProdutoQtdModal";
import { ProdutoDivisorModal } from "../../components/ProdutoDivisorModal";
import { Carrinho } from "../../components/Carrinho";

import "./style.css";
import { CaixaProps, EmpresaParams, GrupoProps, ProdutoProps, TamProdProps } from "../../global/types";
import { ItemProduto } from "../../components/ItemProduto";
import { Input } from "../../components/Input";
import { ModalPedidos } from "../../components/ModalPedidos";
import { ModalLogin } from "../../components/ModalLogin";
// import { api } from "../../services/api";

Modal.setAppElement("#root");

const styleModal = {
  content: {
    padding: "0",
    width: window.innerWidth > 1120 ? 910 : window.innerWidth - 30,
    height: window.innerHeight - 50,
    borderRadius: "10px",
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: "0px"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)"
  }
};

export function Empresa() {
  const params = useParams() as EmpresaParams;
  const {
    buscaProdutos,
    buscaGrupos,
    buscaConfigCores,
    buscaPromocoes,
    promocoes,
    produtos,
    grupos,
    configCores,
    refreshPedidos,
    pedidos,
    buscaEmpresa,
    clearPedidos,
    empresa,
    cliente: clienteStorage,
    getIdCliente,
    gravaCliente
  } = useCache();

  const [loading, setLoading] = useState<boolean>(true);
  const [txtLoading, setTxtLoading] = useState<string>("");
  const [listGrupos, setListGrupos] = useState<GrupoProps[]>([]);
  const [listProdutos, setListProdutos] = useState<ProdutoProps[]>([]);

  const [tamanhoClicado, setTamanhoClicado] = useState<TamProdProps>({} as TamProdProps);
  const [produtoClicado, setProdutoClicado] = useState<ProdutoProps>({} as ProdutoProps);
  const [grupoClicado, setGrupoClicado] = useState<GrupoProps>({} as GrupoProps);

  const [qtdCarrinho, setQtdCarrinho] = useState<number>(0);

  const [modalIsOpenProdQtd, setModalIsOpenProdQtd] = useState<boolean>(false);
  const [modalIsOpenProdDivisor, setModalIsOpenProdDivisor] = useState<boolean>(false);
  const [modalIsOpenCarrinho, setModalIsOpenCarrinho] = useState<boolean>(false);
  const [modalPedidos, setModalPedidos] = useState(false);
  const [modalLogin, setModalLogin] = useState(false);

  const [caixaAberto, setCaixaAberto] = useState<boolean>(false);
  const [pesquisa, setPesquisa] = useState("");

  const firstRun = async () => {
    setLoading(true);

    setTxtLoading("Buscando configurações de Layout da Empresa");
    await buscaConfigCores(params.codCliente);

    setTimeout(async () => {
      setTxtLoading("Buscando lista de Grupos");
      await buscaGrupos(params.codCliente);

      setTxtLoading("Buscando lista de Promoções");
      await buscaPromocoes(params.codCliente);

      setTxtLoading("Buscando lista de Produtos");
      await buscaProdutos(params.codCliente);

      setTxtLoading("Verificando status do Estabelecimento")
      let resCaixaAberto = await VerificaCaixaAberto(params.codCliente)
      if (resCaixaAberto)
        resCaixaAberto = await VerificaDeliveryWebOnline(params.codCliente)

      setCaixaAberto(resCaixaAberto)

      setTxtLoading("Buscando configurações gerais do Estabelecimento");
      await buscaEmpresa(params.codCliente);

      setTxtLoading("Buscando carrinho salvo em cache");
      refreshPedidos(params.codCliente);

      setLoading(false);
      setTxtLoading("");
    }, 1000);
  }

  const AplicaPromocoes = () => {

    const tempListProdutos: ProdutoProps[] = [];

    produtos.forEach(item => {

      let newItem = { ...item };

      promocoes.filter(e => e.local === 0 || e.local === 2).forEach(promo => {

        if (promo.produtos.length > 0) {

          promo.produtos.forEach(prodPromo => {
            let tamProdFilter = newItem.tamanhos.filter(e => e.id == prodPromo.id_produto_tam);
            if (tamProdFilter.length > 0) {
              let tamProd = tamProdFilter[0];
              let desconto = 0;

              if (promo.tipo_desconto == 0) {
                const descPorc = promo.desconto / 100;
                const descProcValor = (tamProd.valor / 100) * descPorc;
                desconto = descProcValor * 100;
              }

              if (promo.tipo_desconto == 1) {
                const descValor = promo.desconto * 100;
                desconto = descValor >= tamProd.valor ? tamProd.valor : descValor;
              }

              if (promo.tipo_desconto == 2) {
                const descValor = promo.desconto * 100;
                desconto = tamProd.valor - descValor;
              }

              tamProd.valorAntes = tamProd.valor;
              tamProd.valor -= desconto;
            }
          })

        } else {

          newItem.tamanhos.forEach(tamProd => {
            let desconto = 0;

            if (promo.tipo_desconto == 0) {
              const descPorc = promo.desconto / 100;
              const descProcValor = (tamProd.valor / 100) * descPorc;
              desconto = descProcValor * 100;
            }

            if (promo.tipo_desconto == 1) {
              const descValor = promo.desconto * 100;
              desconto = descValor >= tamProd.valor ? tamProd.valor : descValor;
            }

            if (promo.tipo_desconto == 2) {
              const descValor = promo.desconto * 100;
              desconto = tamProd.valor - descValor;
            }

            tamProd.valor -= desconto;
          });

        }
      })

      tempListProdutos.push(newItem);
    });

    setListProdutos(tempListProdutos);
  }

  const handleGrupo = (item: GrupoProps) => {
    const tempList: GrupoProps[] = [];
    listGrupos.forEach((rs) => {
      if (rs.id === item.id) {
        const divList = document.getElementById(`list-${item.id}`);
        const divListContent = document.getElementById(`list-content-${item.id}`);
        const icone = document.getElementById(`icon-${item.id}`);

        if (divList && divListContent) {
          divList.style.height = !rs.mostra_prod ? `${(divListContent.clientHeight + 50)}px` : '0px';
          if (icone)
            icone.style.transform = !rs.mostra_prod ? "rotate(90deg)" : "rotate(0deg)";
        }

        rs.mostra_prod = rs.mostra_prod ? false : true;
      }

      tempList.push(rs);
    });
    setListGrupos(tempList);
  }

  const openModalProdQtd = () => {
    setModalIsOpenProdQtd(true);
  }

  const openModalProdDivisor = () => {
    setModalIsOpenProdDivisor(true);
  }

  const closeModalProdQtd = (mostrarFaixa?: boolean) => {
    setModalIsOpenProdQtd(false);

    if (mostrarFaixa)
      showFaixaCliqueAqui();
  }

  const closeModalProdDivisor = (mostrarFaixa?: boolean) => {
    setModalIsOpenProdDivisor(false);

    if (mostrarFaixa)
      showFaixaCliqueAqui();
  }

  const showFaixaCliqueAqui = () => {
    const div = document.getElementById("faixaCliqueAqui");

    if (div) {
      div.style.width = "340px";
      div.style.opacity = "1";

      setTimeout(() => {
        div.style.width = "0px";
        div.style.opacity = "0";
      }, 4000);

    }
  }

  const closeModalCarrinho = (limparCarrinho: boolean = false) => {
    setModalIsOpenCarrinho(false);

    if (limparCarrinho)
      clearPedidos(params.codCliente);
  }

  const handleClickProduto = (tam: TamProdProps, prod: ProdutoProps, gp: GrupoProps) => {
    prod.ingredientes.forEach((rs) => {
      rs.selecionado = true;
    });
    setTamanhoClicado(tam);
    setProdutoClicado(prod);
    setGrupoClicado(gp);

    if (gp.tipo_selec_prod === 2)
      openModalProdDivisor();
    else
      openModalProdQtd();
  }

  const handleCarrinho = () => {
    setModalIsOpenCarrinho(true);
    // showFaixaCliqueAqui();
  }

  const handleVejaPedidos = () => {
    const idCliente = getIdCliente();

    if (idCliente <= 0) {
      setModalLogin(true);
    } else {
      handleModalPedidos();
    }
  }

  const handleModalPedidos = (idCliente?: number, telefone?: string, nome?: string) => {
    setModalLogin(false);
    setModalPedidos(true);

    if (idCliente && telefone && nome) {
      setTimeout(() => {
        gravaCliente(idCliente, telefone, nome);
      }, 500);
    }
  }

  const handlePesqProdGrupo = (idGrupo: number, value: string) => {
    const newListGroup: GrupoProps[] = listGrupos.map(item => {
      if (idGrupo === item.id)
        return { ...item, pesquisa: value };
      else
        return item;
    })

    setListGrupos(newListGroup);

    setTimeout(() => {
      newListGroup.forEach((rs) => {
        if (rs.id === idGrupo) {
          const divList = document.getElementById(`list-${idGrupo}`);
          const divListContent = document.getElementById(`list-content-${idGrupo}`);

          if (divList && divListContent)
            divList.style.height = `${(divListContent.clientHeight + 50)}px`;
        }
      });
    }, 500);
  }

  useEffect(() => {
    firstRun();
  }, []);

  useEffect(() => {
    if (produtos.length > 0)
      AplicaPromocoes();
  }, [produtos]);

  useEffect(() => {
    if (grupos)
      setListGrupos(grupos);
  }, [grupos]);

  useEffect(() => {
    let newQtd = 0;
    pedidos.forEach((rs) => {
      newQtd += rs.qtd / 1000;
    })
    setQtdCarrinho(newQtd);
  }, [pedidos]);

  useEffect(() => {
    if(configCores?.site_novo == 1)
      window.location.href = `https://pedidos.dcifood.com.br/${params.codCliente}`;
  }, [configCores]);

  return (
    <div id="page-empresa" style={{ background: configCores?.cor_fundo, color: configCores?.cor_fonte }}>
      {loading && (
        <Load txtLoading={txtLoading} />
      )}

      {!loading && configCores?.logo && (
        <div>
          <header style={{ background: configCores?.cor_1 }}>
            <div className="content">
              <img src={configCores?.logo} height={100} title="logo" alt="logo" />
              <div className="info">
                <h4>{empresa.fantasia}</h4>
                <p>
                  <FontAwesomeIcon icon={faPhoneAlt} style={{ marginRight: "4px" }} />
                  {empresa.telefone > 0 ? maskPhone(`${empresa.telefone}`) : ""}
                  {empresa.telefone > 0 && empresa.celular > 0 ? " | " : ""}
                  {empresa.celular > 0 ? maskPhone(`${empresa.celular}`) : ""}
                </p>
                <p><FontAwesomeIcon icon={faMapMarkerAlt} style={{ marginRight: "1px" }} /> {configCores.endereco} - {configCores.cidade}</p>
                <p><FontAwesomeIcon icon={faConciergeBell} style={{ marginRight: "5px" }} />Tipos de serviço: {empresa.delivery_modo == 0 ? "Retirada e Delivery" : "Somente Retirada"}</p>
                {empresa.delivery_modo == 0 && (
                  <>
                    <p><FontAwesomeIcon icon={faClock} style={{ marginRight: "5px" }} />Tempo para entrega de {configCores.tempo_entrega}</p>
                    <p><FontAwesomeIcon icon={faClock} style={{ marginRight: "5px" }} />Tempo para retirada de {configCores.tempo_retirada}</p>
                  </>
                )}

                {empresa.delivery_cliente_pedido === 1 && (
                  <button type="button" className="btnVejaPedidos" style={{ backgroundColor: configCores.cor_2, color: configCores?.cor_1 }} onClick={handleVejaPedidos}>
                    <FontAwesomeIcon icon={faList} />
                    <p>VEJA SEUS PEDIDOS</p>
                  </button>
                )}
              </div>
            </div>
          </header>

          <main>

            {!caixaAberto && (
              <div className="aviso">
                <FontAwesomeIcon icon={faExclamationCircle} style={{ fontSize: "60px", color: "#cd240e" }} />
                <div>
                  <h3>Delivery online fechado!</h3>
                  <p>Você poderá navegar normalmente, só não poderá finalizar o pedido.</p>
                </div>
              </div>
            )}

            <Input
              mask="none"
              value={pesquisa}
              inputMaskChange={setPesquisa}
              placeholder="Pesquise o produto em ..." />

            {pesquisa.length > 0 && (
              listProdutos?.filter(i => i.nome.toLowerCase().includes(pesquisa.toLowerCase())).map((rsProd) => {
                const tempGrupo = grupos.find(i => i.id === rsProd.id_grupos);
                if (tempGrupo?.in_app_mesa === 1) {
                  return (
                    rsProd.tamanhos.map((rsTamanho) => {
                      return (
                        <ItemProduto
                          key={`p-${rsProd.id}-${rsTamanho.id}`}
                          grupo={tempGrupo}
                          handleClick={(t, p, g) => handleClickProduto(t, p, g)}
                          produto={rsProd}
                          tamanho={rsTamanho}
                          mostrarGrupo
                        />
                      );
                    })
                  );
                }
              })
            )}

            {pesquisa.length <= 0 && (
              listGrupos?.map((rs) => {
                if (rs.in_app === 1) {
                  return (
                    <div key={`g-${rs.id}`}>
                      <div className="faixa-grupo" style={{ background: configCores?.cor_2 }} onClick={() => { handleGrupo(rs) }}>
                        <FontAwesomeIcon id={`icon-${rs.id}`} style={{ color: configCores?.cor_1 }} icon={faChevronRight} className="icone" />
                        <h3 style={{ color: configCores?.cor_1 }}>{rs.nome}</h3>
                      </div>

                      <div className="lista-produtos" id={`list-${rs.id}`}>

                        <input
                          value={rs.pesquisa ?? ""}
                          className="customInput"
                          onChange={(e) => handlePesqProdGrupo(rs.id, e.target.value)}
                          placeholder={`Pesquise o produto em ${rs.nome}...`} />

                        <div className="list-produtos-content" id={`list-content-${rs.id}`}>
                          {listProdutos?.filter(i => i.id_grupos === rs.id && i.nome.toLowerCase().includes(rs.pesquisa ? rs.pesquisa.toLowerCase() : "")).map((rsProd) => {
                            return (
                              rsProd.tamanhos.map((rsTamanho) => {
                                return (
                                  <ItemProduto
                                    key={`p-${rsProd.id}-${rsTamanho.id}`}
                                    grupo={rs}
                                    handleClick={handleClickProduto}
                                    produto={rsProd}
                                    tamanho={rsTamanho}
                                  />
                                );
                              })
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  );
                }
              })
            )}

            <div className="footer">
              <p><a href="https://datacontrolinformatica.com.br/" target="_blank">Desenvolvido por Data Control Informática</a></p>
              <p>(16) 3262-1365</p>
            </div>

          </main>

          {!modalIsOpenCarrinho && empresa.delivery_cliente_pedido === 1 && (
            <>
              <div id="faixaCliqueAqui" onClick={handleCarrinho}>
                <p>CLIQUE AQUI PARA FINALIZAR O PEDIDO</p>
              </div>
              <div className="carrinho" onClick={handleCarrinho}>
                <p>{qtdCarrinho}</p>
                <FontAwesomeIcon icon={faShoppingBasket} color="#FFF" style={{ fontSize: "35px" }} />
              </div>
            </>
          )}

          <Modal isOpen={modalIsOpenProdQtd} style={styleModal}>
            <ProdutoQtdModal
              tamanho={tamanhoClicado}
              produto={produtoClicado}
              closeModal={closeModalProdQtd}
              grupo={grupoClicado}
              showBtnComprar={empresa.delivery_cliente_pedido === 1}
              caixaAberto={caixaAberto}
            />
          </Modal>

          <Modal isOpen={modalIsOpenProdDivisor} style={styleModal}>
            <ProdutoDivisorModal
              tamanho={tamanhoClicado}
              produto={produtoClicado}
              closeModal={closeModalProdDivisor}
              grupo={grupoClicado}
              showBtnComprar={empresa.delivery_cliente_pedido === 1}
              caixaAberto={caixaAberto}
            />
          </Modal>

          <Modal isOpen={modalIsOpenCarrinho} style={styleModal}>
            <Carrinho
              closeModal={closeModalCarrinho}
              contextPhone={`${clienteStorage.telefone && clienteStorage.telefone.length > 0 ? clienteStorage.telefone : ""}`}
              caixaAberto={caixaAberto}
            />
          </Modal>

          <Modal isOpen={modalLogin} style={styleModal}>
            <ModalLogin closeModal={() => setModalLogin(false)} abreModalPedidos={handleModalPedidos} />
          </Modal>

          <Modal isOpen={modalPedidos} style={{
            content: { ...styleModal.content, width: window.innerWidth > 1120 ? 910 : window.innerWidth - 30, height: window.innerHeight - 50, },
            overlay: { ...styleModal.overlay }
          }}>
            <ModalPedidos closeModal={() => setModalPedidos(false)} />
          </Modal>

        </div>
      )}

    </div>
  );
}