import axios from "axios";

export const apiControle = axios.create({
  baseURL: "https://api.dcifood.com.br/controle/",
});

export const apiControleAws = axios.create({
  baseURL: "https://aws.dcifood.com.br/controle/",
});

export const apiNode = axios.create({
  baseURL: "https://node.dcifood.com.br/",
});