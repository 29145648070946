import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

import { useCache } from "../../hooks/useCache";

import "./style.css";

type Props = {
  btnMais: () => void;
  btnMenos: () => void;
  requestWidth: number;
  limite: number;
  Qtd: number;
};

export function Quantidade({
  btnMais,
  btnMenos,
  requestWidth,
  Qtd,
  limite,
}: Props) {

  const { configCores } = useCache();

  const handlebtnMais = () => {
    if (limite === -1 || Qtd < limite) {
      btnMais();
    }
  };

  const handleBtnMenos = () => {
    if (Qtd > 0) btnMenos();
  };

  return (
    <div className="btn-qtd-container" style={{ width: `${requestWidth}px`, borderColor: configCores.cor_3 ? configCores.cor_3 : "#ff2b08" }}>
      <button onClick={handleBtnMenos} style={{ backgroundColor: configCores.cor_3 ? configCores.cor_3 : "#ff2b08" }}>
        <FontAwesomeIcon icon={faMinus} className="icons" style={{ backgroundColor: configCores.cor_3 ? configCores.cor_3 : "#ff2b08" }} />
      </button>
      <span>{Qtd}</span>
      <button onClick={handlebtnMais} style={{ backgroundColor: configCores.cor_3 ? configCores.cor_3 : "#ff2b08" }}>
        <FontAwesomeIcon icon={faPlus} className="icons" style={{ backgroundColor: configCores.cor_3 ? configCores.cor_3 : "#ff2b08" }} />
      </button>
    </div>
  );
}
