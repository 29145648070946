import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faComments, faMinusCircle } from "@fortawesome/free-solid-svg-icons";

// import { api } from "../../services/api";
import { useCache } from "../../hooks/useCache";
import { currencyFormatter } from "../../utils/currencyFormatter";
import { Load } from "../Load";

import {
  GrupoProps,
  ProdutoProps,
  TamProdProps,
  IngredProdProps,
  EmpresaParams,
  GpAdicionaisProps,
  GpObservacoesProps,
} from "../../global/types/";


import "./style.css";
import { getApiClient } from "../../utils/getApiClient";


type ParamsProps = {
  tamanho: TamProdProps;
  produto: ProdutoProps;
  grupo: GrupoProps;
  indice: number;
  handleChangeIngre: (indice: number, ingr: IngredProdProps[]) => void;
  handleChangeAdc: (indice: number, adc: GpAdicionaisProps[]) => void;
  handleChangeObs: (indice: number, obs: string) => void;
  btnRemover?: boolean;
  handleRemover?: (indice: number) => void;
};

export function ItemProdutoDivisor({ tamanho, produto, indice, btnRemover, handleRemover, handleChangeIngre, handleChangeAdc, handleChangeObs, grupo }: ParamsProps) {
  const {
    configCores,
    adicionais,
    observacoes,
    gravaAdicionais,
    gravaObservacoes
  } = useCache();

  const { codCliente } = useParams() as EmpresaParams;

  const [ingr, setIngr] = useState<IngredProdProps[]>([]);
  const [listAdc, setListAdc] = useState<GpAdicionaisProps[]>([]);
  const [openObs, setOpenObs] = useState<boolean>(false);
  const [openAdc, setOpenAdc] = useState<boolean>(false);
  const [loadAdc, setLoadAdc] = useState<boolean>(false);
  const [observacao, setObservacao] = useState<string>("");

  const [listObs, setListObs] = useState<GpObservacoesProps[]>([]);
  const [loadObs, setLoadObs] = useState<boolean>(false);

  const loadAdicionais = async () => {
    setLoadAdc(true);
    ajustaDivAdc();

    await getApiClient(codCliente)
      .get(`${codCliente}/gp_adicionais?id_grupos=${produto.id_grupos}`)
      .then((res) => {
        if (!res.data) return;

        gravaAdicionais(res.data);
      })
      .catch((err) => {
        alert("Erro ao carregar a lista de adicionais. Tente novamente mais tarde")
      })
      .finally(() => {
        setLoadAdc(false);
      });
  }

  const loadObservacoes = async () => {
    setLoadObs(true);
    ajustaDivObs();

    await getApiClient(codCliente)
      .get(`${codCliente}/gp_observacoes?id_grupos=${produto.id_grupos}`)
      .then((res) => {
        if (!res.data) return;

        const resData = res.data as GpObservacoesProps[];
        const tempListObs: GpObservacoesProps[] = [];

        resData.map((item) => {
          if (item.favorito > 0)
            tempListObs.push(item);
        });

        gravaObservacoes(tempListObs);
      })
      .catch(() => {
        alert("Erro ao carregar a lista de adicionais. Tente novamente mais tarde")
      })
      .finally(() => {
        setLoadObs(false);
      })
  }

  const handleAdc = (item: GpAdicionaisProps) => {
    const tempAdc: GpAdicionaisProps[] = [];

    listAdc.map((rs) => {
      if (rs.id === item.id)
        rs.qtd_selecionada = rs.qtd_selecionada && rs.qtd_selecionada > 0 ? 0 : 1;

      tempAdc.push(rs);
    });

    setListAdc(tempAdc);
    handleChangeAdc(indice, tempAdc);
  };

  const handleObs = (item: GpObservacoesProps) => {
    const tempObs: GpObservacoesProps[] = [];

    listObs.forEach((rs) => {
      if (rs.id === item.id)
        rs.selecionado = !rs.selecionado;

      tempObs.push(rs);
    });

    setListObs(tempObs);
  }

  const handleIngrediente = (item: IngredProdProps) => {
    const tempIngre: IngredProdProps[] = [];

    ingr.forEach((rs) => {
      tempIngre.push({ ...rs, selecionado: rs.id === item.id ? !rs.selecionado : rs.selecionado });
    });

    setIngr(tempIngre);
    handleChangeIngre(indice, tempIngre);
  }

  const handleBtnObs = () => {
    if (openAdc)
      handleBtnAdc();

    setOpenObs((i) => !i);

    if (!observacoes || observacoes.filter(i => i.id_grupos === produto.id_grupos).length <= 0)
      loadObservacoes();

    const container = document.getElementById(`containerObs${indice}`);
    const content = document.getElementById(`contentObs${indice}`);

    if (container && content) {
      container.style.height = !openObs ? `${(content.clientHeight < 50 ? 240 : content.clientHeight + 15)}px` : "0px";
      container.style.opacity = !openObs ? "1" : "0";
    }

  }

  const handleBtnAdc = () => {
    if (openObs)
      handleBtnObs();

    setOpenAdc((i) => !i);

    if (!adicionais || adicionais.filter(i => i.id_grupos === produto.id_grupos).length <= 0)
      loadAdicionais();

    const container = document.getElementById(`containerAdc${indice}`);
    const content = document.getElementById(`contentAdc${indice}`);

    if (container && content) {
      container.style.height = !openAdc ? `${(content.clientHeight < 50 ? 240 : content.clientHeight + 15)}px` : "0px";
      container.style.opacity = !openAdc ? "1" : "0";
    }
  }

  const ajustaDivAdc = () => {
    const container = document.getElementById(`containerAdc${indice}`);
    const content = document.getElementById(`contentAdc${indice}`);

    if (container && content && openAdc) {
      container.style.height = content.clientHeight < 50 ? "240px" : `${(content.clientHeight)}px`;
      container.style.opacity = "1";
    }
  }

  const ajustaDivObs = () => {
    const container = document.getElementById(`containerObs${indice}`);
    const content = document.getElementById(`contentObs${indice}`);

    if (container && content && openObs) {
      container.style.height = content.clientHeight < 50 ? "240px" : `${content.clientHeight}px`;
      container.style.opacity = "1";
    }
  }

  const verifyAdc = () => {
    if (listAdc.length <= 0) {
      const tempListAdc: GpAdicionaisProps[] = [];

      adicionais?.filter(i => i.id_grupos === produto.id_grupos).map((rs) => {
        tempListAdc.push({ ...rs, qtd_selecionada: 0 });
      });

      setListAdc(tempListAdc);
    }
  }

  const verifyObs = () => {
    if (listObs.length <= 0) {
      const tempListObs: GpObservacoesProps[] = [];

      observacoes?.filter(i => i.id_grupos === produto.id_grupos).map((rs) => {
        tempListObs.push({ ...rs, selecionado: false });
      });

      setListObs(tempListObs);
    }
  }

  useEffect(() => {
    let tempObs = observacao;

    listObs.filter(i => i.selecionado).forEach((rs) => {
      tempObs += tempObs.length > 0 ? `, ${rs.descricao}` : rs.descricao;
    });

    handleChangeObs(indice, tempObs);
  }, [observacao, indice, listObs]);

  useEffect(() => {
    if (listAdc.length <= 0) {
      const tempListAdc: GpAdicionaisProps[] = [];

      adicionais?.filter(i => i.id_grupos === produto.id_grupos).map((rs) => {
        tempListAdc.push({ ...rs, qtd_selecionada: 0 });
      });

      setListAdc(tempListAdc);
    }
  }, [adicionais]);

  useEffect(() => {
    if (listObs.length <= 0) {
      const tempListObs: GpObservacoesProps[] = [];

      observacoes?.filter(i => i.id_grupos === produto.id_grupos).map((rs) => {
        tempListObs.push({ ...rs, selecionado: false });
      });

      setListObs(tempListObs);
    }
  }, [observacoes]);

  useEffect(() => {
    setIngr(produto.ingredientes);
    setListAdc([]);
    setListObs([]);
  }, [produto]);

  useEffect(() => {
    ajustaDivAdc();
  }, [listAdc]);

  useEffect(() => {
    ajustaDivObs();
  }, [listObs]);

  useEffect(() => {
    verifyAdc();
    verifyObs();
    handleBtnObs();
  }, []);

  return (
    <div className="ItemProdDiv">
      <div className="img-container">
        <div className="img" style={{ backgroundImage: produto.img.length > 0 ? `url("${produto.img}")` : 'url("https://www.dcifood.com.br/api/logos/sem_imagem.jpg")' }} />
      </div>
      <div className="info">

        <span className="infoSabor">SABOR {indice + 1}</span>

        <h3 style={{ color: configCores?.cor_3, textAlign: "center" }}>
          {produto.nome} - {currencyFormatter(produto.tamanhos.filter(i => i.id_tamanhos === tamanho.id_tamanhos)[0].valor / 100)}
        </h3>
        {produto.ingredientes.length > 0 && (
          <>
            <p>Ingredientes: <small>Desative as opções abaixo para retirar o ingrediente</small></p>
            <div className="listIngredientes">
              {ingr.map((rs) => {
                return (
                  <label key={`i-${rs.id}`} className="ck-container">{rs.ingrediente}
                    <input
                      id={`ing-${rs.id_ingredientes}`}
                      type="checkbox"
                      name="ck-ingredientes"
                      value={rs.ingrediente}
                      checked={rs.selecionado}
                      onChange={() => { handleIngrediente(rs) }} />
                    <span className="checkmark" />
                  </label>
                );
              })}
            </div>
          </>
        )}

        {grupo.observacao && grupo.observacao.length > 0 && (
          <>
            <p>Observação:</p>
            <label>{grupo.observacao}</label>
          </>
        )}

        <div className="containerBtn">
          <button style={{ backgroundColor: "#e67e22" }} onClick={handleBtnAdc}>
            <FontAwesomeIcon icon={faPlus} />
            Adicionar Ingredientes
          </button>

          <button style={{ backgroundColor: "#004276" }} onClick={handleBtnObs}>
            <FontAwesomeIcon icon={faComments} />
            Observações
          </button>

          {btnRemover && (
            <button style={{ backgroundColor: "#a40000" }} onClick={() => handleRemover ? handleRemover(indice) : {}}>
              <FontAwesomeIcon icon={faMinusCircle} />
              REMOVER SABOR
            </button>
          )}
        </div>

        <div id={`containerAdc${indice}`} className="containerAdc">
          <div id={`contentAdc${indice}`} className="contentAdc">
            <h4>Adicionais</h4>
            <div style={{ padding: "0px 15px" }}>
              {loadAdc && (
                <Load txtLoading="Carregando lista de adicionais..." options={{ width: 120, height: 120 }} />
              )}

              {!loadAdc && (
                listAdc.map((rs) => {
                  return (
                    <label key={`adc-${rs.id}`} className="ck-container">
                      {rs.ingrediente} - {currencyFormatter(rs.valor / 100)}
                      <input
                        id={`ing-${rs.id_ingredientes}`}
                        type="checkbox"
                        name="ck-adc"
                        value={rs.ingrediente}
                        checked={rs.qtd_selecionada && rs.qtd_selecionada > 0 ? true : false}
                        onChange={() => handleAdc(rs)}
                      />
                      <span className="checkmark" />
                    </label>
                  );
                })
              )}

            </div>

          </div>
        </div>

        <div id={`containerObs${indice}`} className="containerObs">
          <div id={`contentObs${indice}`} className="contentObs">
            <h4>Observações</h4>

            <div style={{ padding: "15px" }}>
              {loadObs && (
                <div style={{ marginBottom: "25px" }}>
                  <Load txtLoading="Carregando lista de observações..." options={{ width: 120, height: 120 }} />
                </div>
              )}

              {!loadObs && (
                listObs.map((rs) => (
                  <label key={`adc-${rs.id}`} className="ck-container">
                    {rs.descricao}
                    <input
                      id={`obs-${rs.id}`}
                      type="checkbox"
                      name="ck-adc"
                      value={rs.descricao}
                      checked={rs.selecionado}
                      onChange={() => handleObs(rs)}
                    />
                    <span className="checkmark" />
                  </label>
                ))
              )}

              <textarea
                placeholder="Digite aqui sua observação"
                rows={5}
                value={observacao}
                onChange={e => setObservacao(e.target.value)}
              />
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}