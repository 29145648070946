import { KeyboardEvent, useState } from "react";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Input } from "../Input";

import "./style.css";

type ParamsProps = {
  closeModal: () => void;
  confirma: (nome: string) => void;
}

export function ModalNome({ closeModal, confirma }: ParamsProps) {

  const [nome, setNome] = useState("");

  const handleFinalizar = () => {
    if (nome.length < 3)
      return alert("Informe seu nome");

    if (!window.confirm("Deseja realmente finalizar seu pedido?"))
      return;

    confirma(nome);
  }

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter")
      handleFinalizar();
  }

  return (
    <div id="ModalNome">
      <div className="header">
        <h3>Informe seu Nome</h3>
        <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer" }} onClick={closeModal} />
      </div>

      <div className="content">
        <p style={{ fontWeight: "600" }}>Nome:</p>
        <Input
          mask="none"
          value={nome}
          inputMaskChange={setNome}
          placeholder="Digite seu Nome"
          onKeyUp={handleKeyUp}
        />
        <button type="button" onClick={handleFinalizar}>
          <FontAwesomeIcon icon={faCheck} />
          FINALIZAR
        </button>
      </div>

    </div>
  )
}