import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faQuestion, faShoppingCart, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import {
  GrupoProps,
  ProdutoProps,
  TamProdProps,
  EmpresaParams,
  ListProdutosDivisao,
  IngredProdProps,
  GpAdicionaisProps,
  GpEspecialProps,
  GpEspecProdProps,
  AddPedidoProps,
  DivSaboresProps
} from "../../global/types/";

import { useCache } from "../../hooks/useCache";
import Modal from "react-modal";

import "./style.css";
import { Load } from "../Load";
import { ItemProdutoDivisor } from "../ItemProdutoDivisor";
import { ModalListProdutos } from "../ModalListProdutos";
import { currencyFormatter } from "../../utils/currencyFormatter";
import { Quantidade } from "../Quantidade";
import { getApiClient } from "../../utils/getApiClient";

type ParamsProps = {
  tamanho: TamProdProps;
  produto: ProdutoProps;
  grupo: GrupoProps;
  closeModal: (mostrarFaixa?: boolean) => void;
  showBtnComprar?: boolean;
  caixaAberto?: boolean;
};

const styleModal = {
  content: {
    padding: "0",
    width: window.innerWidth > 1120 ? 910 : window.innerWidth - 30,
    height: window.innerHeight - 50,
    borderRadius: "10px",
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)"
  }
};

export function ProdutoDivisorModal({ tamanho, produto, grupo, closeModal, showBtnComprar = true, caixaAberto = true }: ParamsProps) {
  const { configCores, gravaPedidos, empresa } = useCache();
  const { codCliente } = useParams() as EmpresaParams;

  const [gpEspec, setGpEspec] = useState<GpEspecialProps[]>([]);
  const [loadingGpEspec, setLoadingGpEspec] = useState(false);
  const [listProdSelected, setListProdSelected] = useState<ListProdutosDivisao[]>([]);
  const [listSabores, setListSabores] = useState<JSX.Element[]>([]);

  const [modalProdutos, setModalProdutos] = useState(false);

  const loadGpEspec = async () => {
    setLoadingGpEspec(true);
    await getApiClient(codCliente)
      .get(`${codCliente}/grupos_gp_espec?id_grupo=${grupo.id}`)
      .then((res) => {
        if (!res.data)
          return;

        setGpEspec(res.data);
      })
      .catch((err) => {
        alert("Erro ao carregar a lista de acompanhamentos.");
      })
      .finally(() => {
        setLoadingGpEspec(false);
      });
  };

  const handleClickProdutoDiv = (prod: ProdutoProps) => {
    const tempIngre: IngredProdProps[] = [];

    prod.ingredientes.forEach((rs) => {
      tempIngre.push({ ...rs, selecionado: true });
    });
   

    setListProdSelected((e) => [
      ...e,
      {
        adc: [],
        obs: "",
        ingr: tempIngre,
        produto: {...prod, ingredientes: tempIngre}
      }
    ]);
  };

  const handleRemoveProduto = (indice: number) => {
    if (!window.confirm("Deseja realmente remover este item?"))
      return;

    const temp: ListProdutosDivisao[] = [];

    listProdSelected.map((rs, i) => {
      if (i != indice)
        temp.push(rs);
    });

    setListProdSelected(temp);
  };

  const handleChangeIngre = (indice: number, ingr: IngredProdProps[]) => {
    const temp: ListProdutosDivisao[] = [];

    listProdSelected.map((rs, i) => {
      if (i === indice) {
        temp.push({ ...rs, ingr });
      } else {
        temp.push(rs);
      }
    });

    setListProdSelected(temp);
  };

  const handleChangeAdc = (indice: number, adc: GpAdicionaisProps[]) => {
    const temp: ListProdutosDivisao[] = [];

    listProdSelected.map((rs, i) => {
      if (i === indice) {
        temp.push({ ...rs, adc });
      } else {
        temp.push(rs);
      }
    });

    setListProdSelected(temp);
  };

  const handleChangeObs = (indice: number, obs: string) => {
    const temp: ListProdutosDivisao[] = [];

    listProdSelected.map((rs, i) => {
      if (i === indice) {
        temp.push({ ...rs, obs });
      } else {
        temp.push(rs);
      }
    });

    setListProdSelected(temp);
  };

  const handleBtnMais = (gpClicked: GpEspecialProps, prod: GpEspecProdProps) => {
    const tempList: GpEspecialProps[] = [];

    gpEspec.map((rs) => {
      let Total = 0;

      rs.produtos
        .filter((i) => i.qtd_selecionada && i.qtd_selecionada > 0)
        .map((item) => {
          Total += item.qtd_selecionada ? item.qtd_selecionada : 0;
        });

      const newLista = rs.produtos.map((item) => {
        item.qtd_selecionada = item.qtd_selecionada ? item.qtd_selecionada : 0;
        prod.qtd_selecionada = prod.qtd_selecionada ? prod.qtd_selecionada : 0;

        if (
          rs.id === gpClicked.id &&
          item.id === prod.id &&
          (prod.limite === 0 || prod.limite > prod.qtd_selecionada) &&
          (gpClicked.limite == 0 || Total < gpClicked.limite)
        ) {
          return { ...item, qtd_selecionada: item.qtd_selecionada + 1 };
        } else {
          return item;
        }
      });

      tempList.push({ ...rs, produtos: newLista });
    });

    setGpEspec(tempList);
  }

  const handleBtnMenos = (gpClicked: GpEspecialProps, prod: GpEspecProdProps) => {
    const tempList: GpEspecialProps[] = [];

    gpEspec.map((rs) => {
      const newLista = rs.produtos.map((item) => {
        item.qtd_selecionada = item.qtd_selecionada ? item.qtd_selecionada : 0;

        if (rs.id === gpClicked.id && item.id === prod.id) {
          return { ...item, qtd_selecionada: item.qtd_selecionada - 1 };
        } else {
          return item;
        }
      });

      tempList.push({ ...rs, produtos: newLista });
    });

    setGpEspec(tempList);
  }

  const handleComprar = () => {
    const gpEspecSelected: GpEspecialProps[] = [];
    const divSabores: DivSaboresProps[] = [];
    
    let valorTotal = tamanho.valor;

    if(empresa.prod_comb_valor == 1)
      valorTotal = 0

    let valorAdc = 0;
    let nomeProduto = "";

    let txtObsPrimProd = "";

    const txtDiv = listProdSelected.length > 1 ? `1/${listProdSelected.length}` : "";

    nomeProduto = `${grupo.nome} - ${tamanho.tamanho}:\n`;

    for (let i = 0; i < listProdSelected.length; i++) {
      const item: ListProdutosDivisao = listProdSelected[i];
      const prodTam = item.produto.tamanhos.filter(x => x.id_tamanhos === tamanho.id_tamanhos)[0];
      
      if(empresa.prod_comb_valor == 1)
        valorTotal += prodTam.valor / listProdSelected.length;
      else
        valorTotal = valorTotal < prodTam.valor ? prodTam.valor : valorTotal;

      nomeProduto += i == 0 ? ` ${txtDiv} ${item.produto.nome}` : ` \n\n ${txtDiv} ${item.produto.nome}`;

      let txtAdc = "";
      let txtObs = item.obs;

      item.adc.filter(x => x.qtd_selecionada && x.qtd_selecionada > 0).map((rsAdc) => {
        valorAdc += rsAdc.valor / listProdSelected.length;
        txtAdc += txtAdc.length > 0 ? ", " : "";
        txtAdc += `    ${rsAdc.ingrediente}`;
      });

      item.ingr.filter(x => !x.selecionado).map((rsIngr) => {
        if (i === 0)
          txtObsPrimProd += txtObsPrimProd.length > 0 ? ", sem " + rsIngr.ingrediente : "sem " + rsIngr.ingrediente;

        txtObs += txtObs.length > 0 ? ", sem " + rsIngr.ingrediente : "sem " + rsIngr.ingrediente;
      });

      if (txtAdc.length > 0)
        nomeProduto += `\n    Adicionais:\n${txtAdc}`;

      if (txtObs.length > 0)
        nomeProduto += `\n    Observações:\n${txtObs}`;

      if (i > 0) {
        divSabores.push({
          id: prodTam.id,
          adicionais: item.adc.filter(x => x.qtd_selecionada && x.qtd_selecionada > 0),
          observacoes: [],
          observacoes_input: txtObs
        });
      }
    }

    valorTotal += valorAdc;

    let errorGpEspecMinimo:string | null = null;
    gpEspec.map((rs) => {
      let selecteds = 0;
      let checkQtdSelect = 0;
      const gpProds: GpEspecProdProps[] = [];

      rs.produtos.filter(i => i.qtd_selecionada > 0).map((item) => {
        selecteds++;
        gpProds.push(item);
        valorTotal += item.valor * item.qtd_selecionada;
        checkQtdSelect += item.qtd_selecionada;
      });

      if(rs.minimo >checkQtdSelect)
        errorGpEspecMinimo = `Quantidade mínima do acompanhamento "${rs.nome}" não atingida`;
      
      if (selecteds > 0)
      gpEspecSelected.push({ ...rs, produtos: gpProds });
    });
  
    if(errorGpEspecMinimo != null)
      return alert(errorGpEspecMinimo);

    let txtGpEspec = "\n";
    gpEspecSelected.map((item) => {
      txtGpEspec += `\n    ${item.nome}:\n`;
      let txtProdGpEspec = "";

      item.produtos.map((itemProd) => {
        txtProdGpEspec += txtProdGpEspec.length > 0 ? ", " : "    ";
        const qtd = itemProd.qtd_selecionada ? itemProd.qtd_selecionada : 0;

        if (qtd > 1) {
          txtProdGpEspec += `${qtd}x ${itemProd.nome}`;
        } else {
          txtProdGpEspec += `${itemProd.nome}`;
        }
      });
      txtGpEspec += txtProdGpEspec;
    });

    nomeProduto += txtGpEspec;

    const reqObs = listProdSelected[0].obs.length > 0 ? `${listProdSelected[0].obs}, ${txtObsPrimProd}` : txtObsPrimProd;

    const ped: AddPedidoProps = {
      id_produtos_tam: tamanho.id,
      id_grupos: produto.id_grupos,
      qtd: 1000,
      observacoes: divSabores.length == 0 ? reqObs : "",
      adicionais: [],

      div_sabores: divSabores,
      adicionais_individual: listProdSelected[0].adc.filter(i => i.qtd_selecionada && i.qtd_selecionada > 0),
      observacoes_individual: [],
      obs_input_individual: divSabores.length > 0 ? reqObs : "",

      gp_gratis: [],
      gp_espec: gpEspecSelected,
      valor: valorTotal,
      valor_editavel: valorTotal,
      nomeProduto,
      img: produto.img.length > 0 ? produto.img : "https://www.dcifood.com.br/api/logos/sem_imagem.jpg"
    }

    const listNewPedido: AddPedidoProps[] = [];
    listNewPedido.push(ped);
    gravaPedidos(listNewPedido);
    closeModal(true);
  }

  useEffect(() => {
    const tempList: ListProdutosDivisao[] = [];

    tempList.push({
      produto,
      adc: [],
      ingr: produto.ingredientes,
      obs: ""
    });

    setListProdSelected(tempList);

  }, [produto]);

  useEffect(() => {
    const tempListSab: JSX.Element[] = [];

    if (listProdSelected.length > 0) {
      for (let i = 1; i < tamanho.sabores; i++) {
        if (listProdSelected[i]) {
          tempListSab.push(<>
            <ItemProdutoDivisor
              grupo={grupo}
              produto={listProdSelected[i].produto}
              tamanho={tamanho}
              indice={i}
              btnRemover
              handleRemover={handleRemoveProduto}
              handleChangeIngre={handleChangeIngre}
              handleChangeAdc={handleChangeAdc}
              handleChangeObs={handleChangeObs}
            />
            <hr />
          </>);
        } else {
          tempListSab.push(<>
            <div className="content" style={{ cursor: "pointer", minHeight: "0px", justifyContent: "center", alignItems: "center", background: "#ff8203", margin: "0 10px", borderRadius: "7px", padding: "10px 0" }} onClick={() => setModalProdutos(true)}>
              <div>
                <FontAwesomeIcon icon={faQuestion} color="#FFF" style={{ fontSize: "80px", marginRight: "20px" }} />
              </div>
              <div className="info">
                <h3 style={{ color: "#FFF" }}>
                  CLIQUE AQUI CASO QUEIRA ADICIONAR <br />{i + 1} SABORES
                </h3>
              </div>
            </div>
            <hr />
          </>);
        }
      }
    }

    setListSabores(tempListSab);
  }, [listProdSelected, tamanho]);

  useEffect(() => {
    loadGpEspec();
  }, []);

  return (
    <div id="ModalProdutoDivisor">
      <div className="header" style={{ backgroundColor: configCores.cor_1 ? configCores.cor_1 : "#ff2b08", color: configCores.cor_fonte ? configCores.cor_fonte : "#FFF" }}>
        <h3>{grupo.nome} {tamanho.sabores > 1 ? ` - ESCOLHA ATÉ ${tamanho.sabores} SABORES` : ""}</h3>
        <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer" }} onClick={() => closeModal()} />
      </div>

      <div style={{ overflow: "auto", height: window.innerHeight - 120 }}>
        {listProdSelected[0] && (
          <>
            <ItemProdutoDivisor
              grupo={grupo}
              produto={listProdSelected[0].produto}
              tamanho={tamanho}
              indice={0}
              handleChangeIngre={handleChangeIngre}
              handleChangeAdc={handleChangeAdc}
              handleChangeObs={handleChangeObs}
            />
            <hr />
          </>
        )}

        {listSabores.map((rs, i) => {
          return (
            <div key={`ls-${i}`}>
              {rs}
            </div>
          );
        })}

        {loadingGpEspec && (
          <Load txtLoading="Carregando acompanhamentos..." options={{ width: 120, height: 120 }} />
        )}

        {!loadingGpEspec && (
          <div className="listGpEspec">
            {gpEspec.map((rs) => {
              return (
                <div key={`gpe-${rs.id}`}>
                  <h3>{rs.descricao}{rs.minimo > 0 ? ` - Mínimo: ${rs.minimo}` : ""}{rs.limite > 0 ? ` - Limite: ${rs.limite}` : ""} </h3>

                  {rs.produtos.map((item) => (
                    <div className="GpEspec" key={`gpel-${item.id}`}>
                      <Quantidade
                        Qtd={item.qtd_selecionada ? item.qtd_selecionada : 0}
                        limite={item.limite > 0 ? item.limite : -1}
                        requestWidth={100}
                        btnMais={() => { handleBtnMais(rs, item) }}
                        btnMenos={() => { handleBtnMenos(rs, item) }}
                      />

                      <p className="infoGpEspec">
                        {item.nome} {item.limite > 0 ? ` - Limite: ${item.limite}` : ""}
                        <span><br />{currencyFormatter(item.valor / 100)}</span>
                      </p>
                    </div>
                  ))}

                  <hr />
                </div>
              );
            })}
          </div>
        )}

        {showBtnComprar && caixaAberto && (
          <div style={{ width: "100%", textAlign: "center" }} onClick={handleComprar}>
            <button className="btnComprar">
              <FontAwesomeIcon icon={faShoppingCart} style={{ marginRight: "5px" }} />
              Comprar
            </button>
          </div>
        )}

        {!caixaAberto && (
          <div className="aviso">
            <FontAwesomeIcon icon={faExclamationCircle} style={{ fontSize: "60px", color: "#cd240e" }} />
            <div>
              <h3>Delivery online fechado!</h3>
              <p>Você poderá navegar normalmente, só não poderá realizar compras.</p>
            </div>
          </div>
        )}

      </div>

      <Modal isOpen={modalProdutos} style={styleModal}>
        <ModalListProdutos closeModal={() => setModalProdutos(false)} grupo={grupo} tamanho={tamanho} clickProduto={handleClickProdutoDiv} />
      </Modal>

    </div>
  );
}