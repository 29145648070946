import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { MesasProps } from "../../global/types";

import "./style.css";

type ParamsProps = {
  closeModal: () => void;
  comandaSelected: (idMesa: number | null) => void;
  list: MesasProps[];
};

export function ModalComandas({ closeModal, comandaSelected, list }: ParamsProps) {

  return (
    <div id="ModalComandas">

      <div className="header">
        <h3>Escolha sua Comanda</h3>
        <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer" }} onClick={closeModal} />
      </div>

      <div className="content">

        {list.map((item) => (
          <div key={`${item.id}`} className="box" onClick={() => { comandaSelected(item.id) }}>
            <p>{item.mesa}</p>
            <p>{item.nome}</p>
          </div>
        ))}

      </div>

    </div>
  );
}