import { useEffect, useState } from "react";

import { AddPedidoProps } from "../../global/types";
import { currencyFormatter } from "../../utils/currencyFormatter";
import { Quantidade } from "../Quantidade";

import "./style.css";

type ParamsProps = {
  data: AddPedidoProps;
  keyIndex: number;
  btnMenos: (i: number) => void;
  btnMais: (i: number) => void;
}

export function ItemCarrinho({ data, keyIndex, btnMais, btnMenos }: ParamsProps) {
  const [item, setItem] = useState<AddPedidoProps>({} as AddPedidoProps);
  const [key, setKey] = useState<number>(0);

  useEffect(() => {
    setItem(data);
  }, [data]);

  useEffect(() => {
    setKey(keyIndex);
  }, [keyIndex]);

  return (
    <>
      <div className="itemCarrinhoContainer">
        <div className="img" style={{ backgroundImage: `url("${item.img}")` }} />
        <div className="content">
          <h4>{item.nomeProduto}</h4>
        </div>
        <div className="qtdAndPrice">
          <Quantidade Qtd={item.qtd ? item.qtd / 1000 : 1} btnMais={() => btnMais(key)} btnMenos={() => btnMenos(key)} limite={-1} requestWidth={120} />
          <p>{currencyFormatter(item.valor / 100)}</p>
        </div>
      </div>
      <hr />
    </>
  );
}