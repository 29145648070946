import Lottie from "lottie-react-web"

import adm_loading from "../../assets/adm_loading.json";

import "./style.css";

type OptionProps = {
  width: number;
  height: number;
}

type ParamsProps = {
  txtLoading: string;
  options?: OptionProps;
}

export function LoadAdm({ txtLoading, options }: ParamsProps) {
  return (
    <div id="comp-load">
      <Lottie options={{ animationData: adm_loading }} width={options ? options.width : 300} height={options ? options.height : 300} />
      <span>{txtLoading}</span>
    </div>
  );
}