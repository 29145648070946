import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart, faList, faPhoneAlt, faMapMarkerAlt, faClock, faCoins, faConciergeBell } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";

import { useCache } from "../../hooks/useCache";
import { VerificaCaixaAberto } from "../../utils/loadCache";
import { maskPhone } from "../../utils/masks";
import { currencyFormatter } from "../../utils/currencyFormatter";
import { Load } from "../../components/Load";

import { ModalLogin } from "../../components/ModalLogin";
import { ModalPedidos } from "../../components/ModalPedidos";

import { EmpresaParams } from "../../global/types";

import "./style.css";

Modal.setAppElement("#root");

const styleModal = {
  content: {
    padding: "0",
    width: 350,
    height: 300,
    borderRadius: "10px",
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)"
  }
};

export function EmpresaHome() {
  const navigate = useNavigate();
  const { codCliente } = useParams() as EmpresaParams;
  const { configCores, buscaConfigCores, getIdCliente, refreshPedidos, gravaCliente, buscaEmpresa, empresa } = useCache();

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");
  const [modalLogin, setModalLogin] = useState(false);
  const [modalPedidos, setModalPedidos] = useState(false);

  const [caixaAberto, setCaixaAberto] = useState<boolean>(false);

  const firstRun = async () => {
    setLoading(true);

    refreshPedidos(codCliente);

    setTxtLoading("Buscando configurações de Layout da Empresa");
    await buscaConfigCores(codCliente);

    setTimeout(async () => {
      setTxtLoading("Verificando status do Estabelecimento")
      setCaixaAberto(await VerificaCaixaAberto(codCliente));
  
      setTxtLoading("Buscando configurações gerais do Estabelecimento");
      await buscaEmpresa(codCliente);
  
      setLoading(false);
      setTxtLoading("");
    }, 1000);
  }

  const handleVejaPedidos = () => {
    const idCliente = getIdCliente();

    if (idCliente <= 0) {
      setModalLogin(true);
    } else {
      handleModalPedidos();
    }
  }

  const handleModalPedidos = (idCliente?: number, telefone?: string, nome?: string) => {
    setModalLogin(false);
    setModalPedidos(true);

    if (idCliente && telefone && nome) {
      setTimeout(() => {
        gravaCliente(idCliente, telefone, nome);
      }, 500);
    }
  }

  useEffect(() => {
    if(configCores?.site_novo == 1)
      window.location.href = `https://pedidos.dcifood.com.br/${codCliente}`;
  }, [configCores]);

  useEffect(() => {
    firstRun();
  }, []);

  return (
    <div id="page-empresa-home" style={{ background: configCores?.cor_fundo, color: configCores?.cor_fonte }}>
      {loading && (
        <Load txtLoading={txtLoading} />
      )}

      {!loading && configCores?.logo && (
        <div>
          <header>
            <img src={configCores?.logo} width={150} title="logo" alt="logo" />
          </header>

          <main>

            <h4>Bem vindo ao {empresa.fantasia}</h4>
            <h4>Estamos {caixaAberto ? "Aberto" : "Fechados"}</h4>

            <button type="button" style={{ backgroundColor: configCores.cor_2, color: configCores?.cor_1 }} onClick={() => { navigate(`/novoPedido/${codCliente}`) }}>
              <FontAwesomeIcon icon={faShoppingCart} />
              <p>{empresa.delivery_cliente_pedido === 1 ? "FAÇA SEU PEDIDO ONLINE" : "VEJA NOSSO CARDÁPIO"}</p>
            </button>

            {empresa.delivery_cliente_pedido === 1 && (
              <button type="button" style={{ backgroundColor: configCores.cor_2, color: configCores?.cor_1 }} onClick={handleVejaPedidos}>
                <FontAwesomeIcon icon={faList} />
                <p>VEJA SEUS PEDIDOS</p>
              </button>
            )}

            <p>
              <FontAwesomeIcon icon={faPhoneAlt} style={{ marginRight: "4px" }} />
              {empresa.telefone > 0 ? maskPhone(`${empresa.telefone}`) : ""}
              {empresa.telefone > 0 && empresa.celular > 0 ? " | " : ""}
              {empresa.celular > 0 ? maskPhone(`${empresa.celular}`) : ""}
            </p>
            <p><FontAwesomeIcon icon={faMapMarkerAlt} style={{ marginRight: "1px" }} /> {configCores.endereco} - {configCores.cidade}</p>
            <p><FontAwesomeIcon icon={faConciergeBell} style={{ marginRight: "5px" }} />Tipos de serviço: {empresa.delivery_modo == 0 ? "Retirada e Delivery" : "Somente Retirada"}</p>
            {empresa.delivery_modo == 0 && (
              <>
                <p><FontAwesomeIcon icon={faClock} style={{ marginRight: "5px" }} />Tempo para entrega de {configCores.tempo_entrega}</p>
                <p><FontAwesomeIcon icon={faClock} style={{ marginRight: "5px" }} />Tempo para retirada de {configCores.tempo_retirada}</p>
              </>
            )}

            <div className="painel">{configCores.painel_inicial}</div>

          </main>
        </div>
      )}

      <Modal isOpen={modalLogin} style={styleModal}>
        <ModalLogin closeModal={() => setModalLogin(false)} abreModalPedidos={handleModalPedidos} />
      </Modal>

      <Modal isOpen={modalPedidos} style={{
        content: { ...styleModal.content, width: window.innerWidth > 1120 ? 910 : window.innerWidth - 30, height: window.innerHeight - 50, },
        overlay: { ...styleModal.overlay }
      }}>
        <ModalPedidos closeModal={() => setModalPedidos(false)} />
      </Modal>

    </div>
  );
}