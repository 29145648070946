import "./style.css"
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { Input } from "../Input"
import { RevendedoresProps } from "../../global/types"
import { IClientesCtrl } from "../../pages/ControleHome"
import { currencyFormatter } from "../../utils/currencyFormatter"

type IProps = {
  closeModal: () => void
  cadastroCliente: (nome: string, cod_cliente: string, id_revendedor: number | null, mensalidade: number, type: "create" | "update") => void,
  listRevendedores: RevendedoresProps[]
  cliente?: IClientesCtrl
}

export function ModalControleAddCliente({ closeModal, cadastroCliente, listRevendedores, cliente }: IProps) {

  const [nome, setNome] = useState(cliente ? cliente.nome : "")
  const [codCliente, setCodCliente] = useState(cliente ? cliente.cod_cliente : "")
  const [idRevendedor, setIdRevendedor] = useState(cliente ? `${cliente.id_revendedor}` : "0")
  const [mensalidade, setMensalidade] = useState(
    cliente ?
      currencyFormatter(cliente.mensalidade, {
        significantDigits: 2,
        thousandsSeparator: ".",
        decimalSeparator: ",",
        symbol: ""
      })
      :
      "")

  const handleSalvar = () => {
    const revendedor = parseInt(idRevendedor) > 0 ? parseInt(idRevendedor) : null
    const valorMensalidade = parseFloat(`${mensalidade}`.replace(".", "").replace(",", "."))

    cadastroCliente(nome, codCliente, revendedor, valorMensalidade, cliente ? "update" : "create")
  }

  return (
    <div id="mdCadastroCliente">
      <div className="header">
        <h3>Cadasto de Cliente</h3>
        <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer" }} onClick={closeModal} />
      </div>

      <div className="content">

        <div>
          <span>
            <p style={{ fontWeight: "600" }}>Nome:</p>
            <Input
              mask="none"
              value={nome}
              inputMaskChange={setNome}
              placeholder="Nome do Cliente"
            />
          </span>

          <span>
            <p style={{ fontWeight: "600" }}>Código do Cliente:</p>
            <Input
              mask="none"
              value={codCliente}
              inputMaskChange={setCodCliente}
              placeholder="Nome do Cliente"
            />
          </span>
        </div>
        <div>
          <span>
            <p style={{ fontWeight: "600" }}>Revendedor</p>
            <select value={idRevendedor} onChange={(e) => setIdRevendedor(e.target.value)}>
              <option value="0">Data Control</option>
              {listRevendedores.map(item => (
                <option value={`${item.id}`} key={`$sl-r-{item.id}`}>{item.fantasia}</option>
              ))}
            </select>
          </span>

          <span>
            <p style={{ fontWeight: "600" }}>Mensalidade</p>
            <Input
              mask="currency"
              value={mensalidade}
              inputMaskChange={setMensalidade}
              placeholder="Valor da Mensalidade"
            />
          </span>
        </div>

        <button type="button" onClick={() => handleSalvar()}>
          <FontAwesomeIcon icon={faCheck} />
          SALVAR
        </button>

      </div>

    </div>
  )
}