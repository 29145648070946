import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faBookOpen, faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";

import { VerificaCaixaAberto } from "../../utils/loadCache";
import { useCache } from "../../hooks/useCache";
import { MesasProps } from "../../global/types";
import { Load } from "../../components/Load";
// import { api } from "../../services/api";

import "./style.css";
import { ModalComandas } from "../../components/ModalComandas";
import { NrMesaDecript } from "../../utils/nrMesaDecript";
import { ModalDetalhesComanda } from "../../components/ModalDetalhesComanda";
import Lottie from "lottie-react-web";
import ThreeDotLoading from "../../assets/ThreeDotLoading.json";
import { getApiClient } from "../../utils/getApiClient";
import { apiChamaGarcom } from "../../services/apiChamaGarcom";

type INavigateProps = {
  codCliente: string;
  urlNrMesa: string;
}

Modal.setAppElement("#root");

const styleModal = {
  content: {
    padding: "0",
    width: window.innerWidth > 1120 ? 910 : window.innerWidth - 30,
    height: 300,
    borderRadius: "10px",
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: "0px"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)"
  }
};

const styleModalDetalhesComanda = {
  content: { ...styleModal.content, height: window.innerHeight - 50 },
  overlay: { ...styleModal.overlay }
};

export function ChamaGarcom() {
  const navigate = useNavigate();
  const { codCliente, urlNrMesa } = useParams() as INavigateProps;

  const { buscaEmpresa, empresa, configCores, buscaConfigCores } = useCache();

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [loadBtnFecharComanda, setLoadBtnFecharComanda] = useState(false);
  const [loadBtnChamaGarcom, setLoadBtnChamaGarcom] = useState(false);

  const [caixaAberto, setCaixaAberto] = useState<boolean>(false);
  const [listComandas, setListComandas] = useState<MesasProps[]>([]);

  const [modalComandas, setModalComandas] = useState(false);
  const [modalFechamentoComandas, setModalFechamentoComandas] = useState(false);

  const [modalDetalhesComanda, setModalDetalhesComanda] = useState(false);
  const [comandaSelected, setComandaSelected] = useState<MesasProps>({} as MesasProps);

  const [nrMesa, setNrMesa] = useState("0");

  const firstRun = async () => {
    setLoading(true);

    setTxtLoading("Carregando informações da Empresa...");
    await buscaConfigCores(codCliente);

    setTimeout(async () => {
      await buscaEmpresa(codCliente);
  
      setTxtLoading("Verificando status do Estabelecimento")
      setCaixaAberto(await VerificaCaixaAberto(codCliente));
  
      setLoading(false);
      setTxtLoading("");
    }, 1000);
  }

  const handleChamaGarcom = async (idMesa: number | null = null) => {
    console.log(empresa.cod_chama_garcom);

    if (loadBtnChamaGarcom)
      return;

    setLoadBtnChamaGarcom(true);

    if (idMesa === null) {
      await getApiClient(codCliente).get(`${codCliente}/mesas`)
        .then((result) => {
          if (!result.data)
            throw new Error("Não foi possível recuperar a lista de comandas da sua mesa. Tente novamente mais tarde.");

          const list = result.data.list as MesasProps[];
          const listNrMesa = list.filter(i => i.mesa === parseInt(nrMesa));

          if (listNrMesa.length === 1)
            handleChamaGarcom(listNrMesa[0].id);
          else {
            setListComandas(listNrMesa);
            setModalComandas(true);
          }

          setLoadBtnChamaGarcom(false);
        })
        .catch(() => {
          alert("Erro ao recuperar a lista de comandas para sua mesa. Tente novamente mais tarde.");
        })
        .finally(() => { })
    } else {
      setModalComandas(false);
      setLoadBtnChamaGarcom(true);
      await getApiClient(codCliente).post(`${codCliente}/chama_garcom_expo`, {
        "id_mesa": idMesa,
        "nr_mesa": nrMesa
      })
        .then(() => {
          alert("Notificações enviadas com sucesso");
          setLoadBtnChamaGarcom(false);
        })
        .catch(() => {
          alert("Erro ao chamar os garçons. Tente novamente mais tarde.");
        })
        .finally(() => {
          setLoading(false);
          setTxtLoading("");
        })
    }

    if (empresa.cod_chama_garcom) {
      await apiChamaGarcom.post("empresas/emitNotification", {
        codAcesso: empresa.cod_chama_garcom,
        number: nrMesa
      })
    }

  }

  const handleFecharComanda = async (idMesa: number | null = null) => {
    if (loadBtnFecharComanda)
      return;

    setLoadBtnFecharComanda(true);

    if (idMesa === null) {
      await getApiClient(codCliente).get(`${codCliente}/mesas`)
        .then((result) => {
          if (!result.data)
            throw new Error("Não foi possível recuperar a lista de comandas da sua mesa. Tente novamente mais tarde.");

          const list = result.data.list as MesasProps[];
          const listNrMesa = list.filter(i => i.mesa === parseInt(nrMesa));

          if (listNrMesa.length === 1) {
            // handleFecharComanda(listNrMesa[0].id);
            setComandaSelected(listNrMesa[0]);
            setModalDetalhesComanda(true);
          } else {
            setLoadBtnFecharComanda(false);
            setListComandas(listNrMesa);
            setModalFechamentoComandas(true);
          }

        })
        .catch(() => {
          // err.response.data.message
          alert("Erro ao recuperar a lista de comandas para sua mesa. Tente novamente mais tarde.");
        })
        .finally(() => { })
    } else {
      setModalFechamentoComandas(false);
      setLoadBtnFecharComanda(false);

      let tempComanda: MesasProps = {} as MesasProps;
      listComandas.map((item) => {
        if (item.id === idMesa)
          tempComanda = item;
      });

      if (tempComanda.id) {
        setComandaSelected(tempComanda);
        setModalDetalhesComanda(true);
      }
    }
  }

  const handleCloseModalFechamento = () => {
    setModalDetalhesComanda(false)
    setLoadBtnFecharComanda(false);
  }

  useEffect(() => {
    firstRun();
  }, []);

  useEffect(() => {
    if (empresa.qtd_mesa && empresa.qtd_mesa > 0) {
      const decripted = NrMesaDecript(urlNrMesa, empresa.qtd_mesa);
      setNrMesa(`${decripted}`);
    }
  }, [empresa]);

  useEffect(() => {
    if(configCores?.site_novo == 1)
      window.location.href = `https://pedidos.dcifood.com.br/mesas/${urlNrMesa}/${codCliente}`;
  }, [configCores]);

  return (
    <div id="page-chama-garcom">
      {loading && (
        <Load txtLoading={txtLoading} />
      )}

      {!loading && (
        <div>
          <header>
            {empresa?.logo?.substring(empresa.logo.length - 14) !== "sem_imagem.jpg" && (
              <img src={empresa.logo} width={200} title="logo" alt="logo" />
            )}
            <h4>ESTAMOS {caixaAberto ? "ABERTO" : "FECHADOS"}</h4>
          </header>

          <div className="painel">
            <h4>BEM VINDO AO <br /> {empresa?.fantasia?.toUpperCase()}</h4>
            <p>MESA: {nrMesa}</p>
            <h4>ESCOLHA UMA DAS OPÇÕES ABAIXO: </h4>
          </div>

          <main>

            {configCores.btn_chama_garcom > 0 && (
              <button type="button" style={{ backgroundColor: "#ff2b08" }} onClick={() => handleChamaGarcom()}>
                {loadBtnChamaGarcom && (
                  <Lottie options={{ animationData: ThreeDotLoading }} height={57} />
                )}

                {!loadBtnChamaGarcom && (
                  <>
                    <FontAwesomeIcon icon={faBell} style={{ fontSize: "30px" }} />
                    <p>CHAMAR GARÇOM</p>
                  </>
                )}
              </button>
            )}

            <button type="button" style={{ backgroundColor: "#ff2b08" }} onClick={() => navigate(`/mesas/${urlNrMesa}/${codCliente}/cardapio`)}>
              <FontAwesomeIcon icon={faBookOpen} style={{ fontSize: "30px" }} />
              <p>{empresa.qr_code_cliente_pedido === 1 ? "FAÇA SEU PEDIDO" : "VEJA O CARDÁPIO"}</p>
            </button>


            {configCores.btn_ver_consumo > 0 && (
              <button type="button" style={{ backgroundColor: "#ffca01", color: "#281b1b" }} onClick={() => handleFecharComanda()}>
                {loadBtnFecharComanda && (
                  <Lottie options={{ animationData: ThreeDotLoading }} height={57} />
                )}

                {!loadBtnFecharComanda && (
                  <>
                    <FontAwesomeIcon icon={faFileInvoiceDollar} style={{ fontSize: "30px" }} />
                    <p>VER CONSUMO</p>
                  </>
                )}
              </button>
            )}

            <p style={{ fontSize: "12px", marginTop: "40px", color: "#6b6b6e", textAlign: "center" }}>Sistema Desenvolvido por: <br /> Data Control Informática - (16)99716-6880</p>

          </main>

        </div>
      )}

      <Modal isOpen={modalComandas} style={styleModal}>
        <ModalComandas closeModal={() => setModalComandas(false)} comandaSelected={(e) => handleChamaGarcom(e)} list={listComandas} />
      </Modal>

      <Modal isOpen={modalFechamentoComandas} style={styleModal}>
        <ModalComandas closeModal={() => setModalFechamentoComandas(false)} comandaSelected={(e) => handleFecharComanda(e)} list={listComandas} />
      </Modal>

      <Modal isOpen={modalDetalhesComanda} style={styleModalDetalhesComanda}>
        <ModalDetalhesComanda closeModal={() => handleCloseModalFechamento()} comanda={comandaSelected} />
      </Modal>

    </div>
  )
}