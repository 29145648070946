import { KeyboardEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import bgLogin from "../../assets/bg_login.jpg";
import { Input } from "../../components/Input";
import { Load } from "../../components/Load";
import { AdmUser } from "../../global/types";
import { useCache } from "../../hooks/useCache";
import { getApiAdmClient } from "../../utils/getApiClient";
// import { apiAdm } from "../../services/apiAdm";

import "./style.css";

export function AdmLogin() {
  const navigate = useNavigate();

  const { gravaAdmUser } = useCache();

  const [usuario, setUsuario] = useState<string>("");
  const [senha, setSenha] = useState<string>("");

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const handleEnviar = async () => {

    if (!usuario || usuario?.length < 3)
      return alert("Usuário não informado");

    if (!senha || senha?.length < 3)
      return alert("Senha não informado");

    setLoading(true);
    setTxtLoading("Verificando usuaário e senha.");
    let liberado = false;

    // HOSTGATOR - ** MUDAR **
    await getApiAdmClient("hostgator")
      .post("login", {
        usuario,
        senha
      })
      .then((response) => {
        if (!response.data)
          throw new Error("Erro de comunicação com o servidor. Tente novamente mais tarde");

        const userDados = response.data as AdmUser;

        liberado = true;
        // HOSTGATOR - ** MUDAR **
        getApiAdmClient("hostgator").defaults.headers.common['Authorization'] = `${response.data.token}`;

        gravaAdmUser(userDados);
      })
      .catch((err) => {
        if (err.response.data.msg)
          alert(err.response.data.msg);
        else
          alert("Erro ao efetuar login. Tente novamente mais tarde");
      })
      .finally(() => {
        setLoading(false);
        if (liberado)
          navigate('/adm');
      });
  }

  const handleKeySenha = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter")
      handleEnviar();
  }

  return (
    <div id="loginPage" style={{ background: `url(${bgLogin})` }}>
      <div className="form">
        {loading && (
          <Load txtLoading={txtLoading} options={{ width: 100, height: 100 }} />
        )}

        {!loading && (
          <>
            <span>Usuário:</span>
            <Input
              mask="none"
              value={usuario}
              inputMaskChange={setUsuario}
              placeholder="Seu usuário"
            />

            <span>Senha:</span>
            <Input
              mask="none"
              value={senha}
              inputMaskChange={setSenha}
              placeholder="Sua senha"
              type="password"
              onKeyUp={handleKeySenha}
            />

            <button onClick={handleEnviar}>ENVIAR</button>
          </>
        )}

      </div>
    </div>
  );
}