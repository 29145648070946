import { faPrint, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { dateNow, getMesAno } from "../../utils/dateProvider";
import { Input } from "../Input";
import { AdmUserEmpresas } from "../../global/types";
import "./style.css";
import { Load } from "../Load";
import { getApiClient } from "../../utils/getApiClient";
// import { api } from "../../services/api";

type IProps = {
  closeModal: () => void;
  empresas: AdmUserEmpresas[];
}

export function ModalAdmRelVendasMensal({ closeModal, empresas }: IProps) {

  const [empresaSelected, setEmpresaSelected] = useState(empresas.length > 0 ? empresas[0].codCliente : "")
  const [anoMes, setAnoMes] = useState(getMesAno(dateNow()));
  const [tipo, setTipo] = useState("Mesas");

  const [loading, setLoading] = useState(false);

  const handleImprimir = async () => {
    setLoading(true);

    try {

      const splitAnoMes = anoMes.split("/");

      const formData = new FormData();
      formData.append("mes", splitAnoMes[0]);
      formData.append("ano", splitAnoMes[1]);
      formData.append("tipo", tipo);

      const response = await getApiClient(empresaSelected).post(`${empresaSelected}/rel-mensal`, formData, {
        responseType: "arraybuffer",
        headers: { Accept: "application/pdf" }
      });

      const file = new Blob([response.data], { type: 'application/pdf' })

      const fileURL = URL.createObjectURL(file)

      const pdfWindow = window.open()

      if (pdfWindow !== null)
        pdfWindow.location.href = fileURL

    } catch (err) {
      console.log(err);
      alert("Erro ao gerar relatório. Tente novamente mais tarde.")
    }

    setLoading(false);
  }

  return (
    <div id="ModalAdmRelVendasMensal">
      <div className="header">
        <h3>Relatório Mensal</h3>
        <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer" }} onClick={closeModal} />
      </div>

      {loading && (
        <Load txtLoading="Gerando Relatório..." options={{ height: 150, width: 150 }} />
      )}



      {!loading && (
        <div className="content">
          <p style={{ fontWeight: "600" }}>Empresa:</p>
          <select value={empresaSelected} onChange={(e) => setEmpresaSelected(e.target.value)}>
            {empresas && empresas.length > 0 && empresas.map(item => (
              <option key={item.codCliente} value={item.codCliente}>{item.apelido}</option>
            ))}
          </select>

          <p style={{ fontWeight: "600" }}>Mês referente:</p>
          <Input
            mask="mes_ano"
            value={anoMes}
            inputMaskChange={setAnoMes}
            placeholder="mm/aaaa"
            maxLength={7}
          />

          <p style={{ fontWeight: "600" }}>Tipo:</p>
          <select value={tipo} onChange={(e) => setTipo(e.target.value)}>
            <option value="Mesas">Mesas</option>
            <option value="Delivery">Delivery</option>
          </select>
          <button type="button" onClick={handleImprimir}>
            <FontAwesomeIcon icon={faPrint} />
            IMPRIMIR
          </button>
        </div>
      )}


    </div>
  );
}