import md5 from "md5";

export const NrMesaDecript = (hash: string, totalMesas: number): number => {
  let nrMesa = 0;
  let i = 1;
  while (i <= totalMesas) {
    if (md5(`${i}`) === hash)
      nrMesa = i;

    i++;
  }

  return nrMesa;
}