import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileInvoiceDollar, faTimes } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";

// import { api } from "../../services/api";
import { useCache } from "../../hooks/useCache";
import { currencyFormatter } from "../../utils/currencyFormatter";
import { MesaDetalhesPedidosProps, MesaDetalhesProps, MesasProps } from "../../global/types";
import { ItemProdutoComanda } from "../ItemProdutoComanda";
import { Load } from "../Load";

import "./style.css";
import { ModalDivisaoComanda } from "../ModalDivisaoComanda";
import Lottie from "lottie-react-web";
import ThreeDotLoading from "../../assets/ThreeDotLoading.json";
import { getApiClient } from "../../utils/getApiClient";

type ParamsProps = {
  closeModal: () => void;
  comanda: MesasProps;
};

type INavigateProps = {
  codCliente: string;
}

const styleModal = {
  content: {
    padding: "0",
    width: 350,
    height: 285,
    borderRadius: "10px",
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)"
  }
};

export function ModalDetalhesComanda({ closeModal, comanda }: ParamsProps) {
  const { empresa, configCores } = useCache();
  const { codCliente } = useParams() as INavigateProps;

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [loadingFechamento, setLoadingFechamento] = useState(false);

  const [listPedidos, setListPedidos] = useState<MesaDetalhesPedidosProps[]>([]);
  const [detalhes, setDetalhes] = useState<MesaDetalhesProps>({} as MesaDetalhesProps)
  const [valorPago, setValorPago] = useState(0);
  const [valorTotal, setValorTotal] = useState(0);

  const [modalDivComanda, setModalDivComanda] = useState(false);
  const [visibleBtnFechaComanda, setVisibleBtnFechaComanda] = useState(false);

  const loadDetalhes = async () => {
    setLoading(true);
    setTxtLoading("Carregando detalhes da comanda");

    await getApiClient(codCliente).get(`${codCliente}/mesas?id=${comanda.id}`)
      .then((result) => {
        if (!result.data) return;

        const data: MesaDetalhesProps = result.data;

        let vPago = 0;
        data.pagamentos?.map((item) => {
          vPago += item.valor;
        });

        let vTotal = 0;
        const tempListPedidos: MesaDetalhesPedidosProps[] = [];
        data.pedidos?.map((item) => {
          vTotal += (item.qtd / 1000) * item.valor;
          if (tempListPedidos.filter(i => i.id_tamanho === item.id_tamanho && i.valor === item.valor && !i.has_comb).length > 0) {
            const tempItem = tempListPedidos.filter(i => i.id_tamanho === item.id_tamanho && i.valor === item.valor && !i.has_comb)[0];
            tempListPedidos[tempListPedidos.indexOf(tempItem)].qtd += item.qtd;
          } else
            tempListPedidos.push(item);
        });

        setVisibleBtnFechaComanda(comanda.status === 1);
        setListPedidos(tempListPedidos);
        setValorTotal(vTotal);
        setValorPago(vPago);
        setDetalhes(result.data);
      })
      .catch((err) => {
        alert("Erro ao carregar os detalhes da Comanda. Tenten novamente mais tarde");
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
      })
  }

  const handleFecharComanda = async (divisao: number) => {
    setLoadingFechamento(true);
    setModalDivComanda(false);

    let sucess = false;
    await getApiClient(codCliente).get(`${codCliente}/mesa_fechamento?id=${comanda.id}&divisao=${divisao}`)
      .then((result) => {
        if (!result.data)
          return;

        sucess = true;
        alert("Comanda fechada com sucesso. Aguarde o garçom para finalizar o pagamento.");
      })
      .catch(() => {
        alert("Erro ao tentar realizar o fechamento da comanda. Tente novamente mais tarde");
      })
      .finally(() => {
        if (sucess)
          setVisibleBtnFechaComanda(false);
      })
  }

  const handleBtnFechamento = () => {
    if (loadingFechamento)
      return;

    setModalDivComanda(true);
  }

  useEffect(() => {
    if (comanda.id)
      loadDetalhes();
  }, [comanda]);

  return (
    <div id="ModalDetalhesComanda">
      <div className="header">
        <h3>{comanda?.mesa} - {comanda?.nome}</h3>
        <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer" }} onClick={closeModal} />
      </div>

      <div className="content">

        {loading && (
          <Load txtLoading={txtLoading} />
        )}

        {!loading && (
          <>
            <div className="tableHeader">
              <p style={{ width: '100%' }}>Produto</p>
              <p>Qtd</p>
              <p style={{ width: "100px", textAlign: "right" }}>V. Total</p>
            </div>
            {listPedidos.map((rs, i) => (
              <div className="tableRow" key={`${rs.id}-${i}`}>
                <ItemProdutoComanda pedido={rs} />
              </div>
            ))}
            <div className="tableFooter">
              <p style={{ width: "100%", textAlign: "right" }}>Total Consumido: {currencyFormatter(valorTotal / 100)}</p>
              <p style={{ width: "100%", textAlign: "right" }}>Valor Pago: {currencyFormatter(valorPago / 100)}</p>
              <p style={{ width: "100%", textAlign: "right", marginTop: "10px" }}>Total em Aberto: {currencyFormatter(comanda?.valor_aberto / 100)}</p>
              <p style={{ width: "100%", textAlign: "right" }}>Serviço: {currencyFormatter((comanda?.valor_aberto * (empresa.taxa_servico / 100)) / 100)}</p>
              <p style={{ width: "100%", textAlign: "right" }}>Total: {currencyFormatter(((comanda?.valor_aberto * (empresa.taxa_servico / 100)) + comanda?.valor_aberto) / 100)}</p>
            </div>

            {visibleBtnFechaComanda && configCores.btn_fechar_comanda == 1 && (
              <button onClick={() => handleBtnFechamento()} style={{ padding: loadingFechamento ? "0px" : "12px 20px" }}>
                {loadingFechamento && (
                  <Lottie options={{ animationData: ThreeDotLoading }} height={46} />
                )}
                {!loadingFechamento && (
                  <>
                    <FontAwesomeIcon icon={faFileInvoiceDollar} />
                    FECHAR COMANDA
                  </>
                )}
              </button>
            )}

          </>
        )}

      </div>

      <Modal isOpen={modalDivComanda} style={styleModal}>
        <ModalDivisaoComanda closeModal={() => setModalDivComanda(false)} confirma={(e) => handleFecharComanda(e)} total={((comanda?.valor_aberto * (empresa.taxa_servico / 100)) + comanda?.valor_aberto) / 100} />
      </Modal>

    </div >
  );
}