import { useState, KeyboardEvent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";

import { useCache } from "../../hooks/useCache";
import { Input } from "../Input";

import "./style.css";

type ParamsProps = {
  total: string;
  closeModal: () => void;
  confirma: (formaPgto: number, txtTroco?: string) => void;
}

export function ModalTroco({ closeModal, confirma, total }: ParamsProps) {
  const { configCores } = useCache();

  const [troco, setTroco] = useState("");

  const handleFinalizar = () => {
    if (window.confirm("Deseja realmente finalizar o pagamento de seu pedido por Dinheiro?")) {
      if (troco.length > 0)
        confirma(1, troco.includes(",") ? troco : troco + ",00");
      else
        confirma(1);
    }
  }

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter")
      handleFinalizar();
  }

  return (
    <div id="ModalTroco">
      <div className="header" style={{ backgroundColor: configCores?.cor_1, color: configCores?.cor_fonte }}>
        <h3>Pagamento em Dinheiro</h3>
        <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer" }} onClick={closeModal} />
      </div>

      <div className="content">
        <p style={{ fontWeight: "600" }}>TOTAL A PAGAR: {total}</p>
        <p>Deixe vazio ou zerado para informar que não será necessário o envio de troco.</p>
        <Input
          mask="currency"
          value={troco}
          inputMaskChange={setTroco}
          placeholder="Troco para..."
          onKeyUp={handleKeyUp}
        />
        <button type="button" onClick={handleFinalizar}>
          <FontAwesomeIcon icon={faCheck} />
          FINALIZAR
        </button>
      </div>

    </div>
  );
}