import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faPlus, faComments, faShoppingCart, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import {
  GrupoProps,
  ProdutoProps,
  TamProdProps,
  IngredProdProps,
  EmpresaParams,
  GpAdicionaisProps,
  AddPedidoProps,
  AddPedidoAdcProps,
  GpObservacoesProps,
  GpEspecialProps,
  GpEspecProdProps
} from "../../global/types/";
// import { api } from "../../services/api";

import { Quantidade } from "../Quantidade";
import { Load } from "../Load";

import { useCache } from "../../hooks/useCache";
import { currencyFormatter } from "../../utils/currencyFormatter";

import "./style.css";
import { getApiClient } from "../../utils/getApiClient";

type ParamsProps = {
  tamanho: TamProdProps;
  produto: ProdutoProps;
  grupo: GrupoProps;
  closeModal: (mostrarFaixa?: boolean) => void;
  showBtnComprar?: boolean;
  caixaAberto?: boolean;
};

export function ProdutoQtdModal({ tamanho, produto, grupo, closeModal, showBtnComprar = true, caixaAberto = true }: ParamsProps) {
  const {
    configCores,
    adicionais,
    observacoes,
    gravaAdicionais,
    gravaObservacoes,
    gravaPedidos,
  } = useCache();

  const { codCliente } = useParams() as EmpresaParams;

  const [gpEspec, setGpEspec] = useState<GpEspecialProps[]>([]);
  const [ingr, setIngr] = useState<IngredProdProps[]>([]);
  const [loadingGpEspec, setLoadingGpEspec] = useState(false);
  const [listAdc, setListAdc] = useState<GpAdicionaisProps[]>([]);
  const [listObs, setListObs] = useState<GpObservacoesProps[]>([]);
  const [qtd, setQtd] = useState<number>(1);
  const [openObs, setOpenObs] = useState<boolean>(false);
  const [openAdc, setOpenAdc] = useState<boolean>(false);
  const [loadAdc, setLoadAdc] = useState<boolean>(false);
  const [loadObs, setLoadObs] = useState<boolean>(false);
  const [observacao, setObservacao] = useState<string>("");

  const viewIngrediente = (item: IngredProdProps, tamanho: TamProdProps) => {
    const ingrediente = tamanho.qtdIngredientes.find(e => e.id_ingredientes === item.id_ingredientes)?.qtd_utilizada
    const txtQtd = ingrediente && ingrediente > 1000 ? `${ingrediente / 1000}x ` : '';
    return `${txtQtd}${item.ingrediente}`;
  }

  const loadGpEspec = async () => {
    setLoadingGpEspec(true);
    await getApiClient(codCliente)
      .get(`${codCliente}/grupos_gp_espec?id_grupo=${grupo.id}`)
      .then((res) => {
        if (!res.data)
          return;

        setGpEspec(res.data);
      })
      .catch((err) => {
        alert("Erro ao carregar a lista de acompanhamentos.");
      })
      .finally(() => {
        setLoadingGpEspec(false);
      });
  };

  const loadAdicionais = async () => {
    setLoadAdc(true);
    ajustaDivAdc();

    await getApiClient(codCliente)
      .get(`${codCliente}/gp_adicionais?id_grupos=${produto.id_grupos}`)
      .then((res) => {
        if (!res.data) return;

        gravaAdicionais(res.data);
      })
      .catch((err) => {
        alert("Erro ao carregar a lista de adicionais. Tente novamente mais tarde")
      })
      .finally(() => {
        setLoadAdc(false);
      });
  }

  const loadObservacoes = async () => {
    setLoadObs(true);
    ajustaDivObs();

    await getApiClient(codCliente)
      .get(`${codCliente}/gp_observacoes?id_grupos=${produto.id_grupos}`)
      .then((res) => {
        if (!res.data) return;

        const resData = res.data as GpObservacoesProps[];
        const tempListObs: GpObservacoesProps[] = [];

        resData.map((item) => {
          if (item.favorito > 0)
            tempListObs.push(item);
        });

        gravaObservacoes(tempListObs);
      })
      .catch(() => {
        alert("Erro ao carregar a lista de adicionais. Tente novamente mais tarde")
      })
      .finally(() => {
        setLoadObs(false);
      })
  }

  const handleIngrediente = (item: IngredProdProps) => {
    const tempIngre: IngredProdProps[] = [];

    ingr.forEach((rs) => {
      if (rs.id === item.id)
        rs.selecionado = !rs.selecionado;

      tempIngre.push(rs);
    });

    setIngr(tempIngre);
  }

  const handleAdc = (item: GpAdicionaisProps, tipo: "mais" | "menos") => {
    const tempAdc: GpAdicionaisProps[] = [];

    listAdc.forEach((rs) => {
      if (rs.id === item.id) {
        if (tipo === "menos" && (rs.qtd_selecionada === undefined || rs.qtd_selecionada <= 0))
          rs.qtd_selecionada = rs.qtd_selecionada
        else if (tipo === "mais")
          rs.qtd_selecionada = rs.qtd_selecionada ? rs.qtd_selecionada + 1 : 1
        else
          rs.qtd_selecionada = rs.qtd_selecionada ? rs.qtd_selecionada - 1 : 0
        // rs.qtd_selecionada = rs.qtd_selecionada && rs.qtd_selecionada > 0 ? 0 : 1;
      }

      tempAdc.push(rs);
    });

    setListAdc(tempAdc);
  }

  /*
    const handleAdc = (item: GpAdicionaisProps) => {
      const tempAdc: GpAdicionaisProps[] = [];
  
      listAdc.forEach((rs) => {
        if (rs.id === item.id)
          rs.qtd_selecionada = rs.qtd_selecionada && rs.qtd_selecionada > 0 ? 0 : 1;
  
        tempAdc.push(rs);
      });
  
      setListAdc(tempAdc);
    };*/

  const handleObs = (item: GpObservacoesProps) => {
    const tempObs: GpObservacoesProps[] = [];

    listObs.forEach((rs) => {
      if (rs.id === item.id)
        rs.selecionado = !rs.selecionado;

      tempObs.push(rs);
    });

    setListObs(tempObs);
  }

  const handleBtnMais = () => {
    setQtd((i) => i + 1);
  };

  const handleBtnMenos = () => {
    setQtd((i) => i - 1);
  };

  const handleBtnObs = () => {
    if (openAdc)
      handleBtnAdc();

    if (!observacoes || observacoes.filter(i => i.id_grupos === produto.id_grupos).length <= 0)
      loadObservacoes();

    const container = document.getElementById("containerObs");
    const content = document.getElementById("contentObs");

    if (container && content) {
      container.style.height = !openObs ? `${content.clientHeight}px` : "0px";
      container.style.opacity = !openObs ? "1" : "0";
    }

    setOpenObs((i) => !i);
  }

  const handleBtnAdc = () => {
    if (openObs)
      handleBtnObs();

    if (!adicionais || adicionais.filter(i => i.id_grupos === produto.id_grupos).length <= 0)
      loadAdicionais();

    const container = document.getElementById("containerAdc");
    const content = document.getElementById("contentAdc");

    if (container && content) {
      container.style.height = !openAdc ? `${(content.clientHeight < 50 ? 240 : content.clientHeight + 15)}px` : "0px";
      container.style.opacity = !openAdc ? "1" : "0";
    }

    setOpenAdc((i) => !i);
  }

  const handleComprar = () => {
    const gpEspecSelected: GpEspecialProps[] = [];
    const listNewPedido: AddPedidoProps[] = [];
    const adcIngr: AddPedidoAdcProps[] = [];

    let nomeProduto = `${tamanho.tamanho}: ${produto.nome}`;
    let valorTotal = tamanho.valor * qtd;
    let Obs = observacao;

    if (qtd <= 0)
      return alert("Produto com a quantidade zerada.");

    ingr.filter(i => !i.selecionado).forEach((rs) => {
      Obs += Obs.length > 0 ? ", sem " + rs.ingrediente : "sem " + rs.ingrediente;
    });

    let txtAdc = "";
    listAdc.filter(i => i.qtd_selecionada && i.qtd_selecionada > 0).forEach((rs) => {
      const qtdSelected = rs.qtd_selecionada ? rs.qtd_selecionada : 0

      valorTotal += (rs.valor * qtdSelected) * qtd;

      txtAdc += txtAdc.length > 0 ? ", " : "";
      txtAdc += `    ${rs.ingrediente}`;

      adcIngr.push({
        id_gp_adicionais: rs.id,
        qtd: qtdSelected,
        valor: rs.valor * qtdSelected,
        ingrediente: rs.ingrediente
      });
    });

    if (txtAdc.length > 0)
      nomeProduto += `\n    Adicionais:\n${txtAdc}`;

    let txtObs = Obs;
    let txtObsNomeProduto = Obs;
    listObs.filter(i => i.selecionado).forEach((rs) => {
      let tempObs = rs.descricao;
      if (rs.apelido_impressao && rs.apelido_impressao !== null && rs.apelido_impressao.length > 0)
        tempObs = rs.apelido_impressao;

      txtObs += txtObs.length > 0 ? ", " : "";
      txtObs += `    ${tempObs}`;

      txtObsNomeProduto += txtObsNomeProduto.length > 0 ? ", " : "";
      txtObsNomeProduto += `    ${rs.descricao}`;
    });

    if (txtObs.length > 0)
      nomeProduto += `\n    Observações:\n${txtObsNomeProduto}`;



    /*
     **************
     **************
     **************
     */

    let errorGpEspecMinimo:string | null = null;
    gpEspec.map((rs) => {
      let selecteds = 0;
      let checkQtdSelect = 0;
      const gpProds: GpEspecProdProps[] = [];

      rs.produtos.filter(i => i.qtd_selecionada > 0).map((item) => {
        selecteds++;
        gpProds.push(item);
        valorTotal += item.valor * item.qtd_selecionada;
        checkQtdSelect += item.qtd_selecionada;
      });

      if(rs.minimo >checkQtdSelect)
        errorGpEspecMinimo = `Quantidade mínima do acompanhamento "${rs.nome}" não atingida`;

      if (selecteds > 0)
        gpEspecSelected.push({ ...rs, produtos: gpProds });
    });
  
    if(errorGpEspecMinimo != null)
      return alert(errorGpEspecMinimo);

    let txtGpEspec = "\n";
    gpEspecSelected.map((item) => {
      txtGpEspec += `\n    ${item.nome}:\n`;
      let txtProdGpEspec = "";

      item.produtos.map((itemProd) => {
        txtProdGpEspec += txtProdGpEspec.length > 0 ? ", " : "    ";
        const qtd = itemProd.qtd_selecionada ? itemProd.qtd_selecionada : 0;

        if (qtd > 1) {
          txtProdGpEspec += `${qtd}x ${itemProd.nome}`;
        } else {
          txtProdGpEspec += `${itemProd.nome}`;
        }
      });
      txtGpEspec += txtProdGpEspec;
    });

    nomeProduto += txtGpEspec;


    /*
     ***********
     *** FIM ***
     ***********
     */


    const ped: AddPedidoProps = {
      id_produtos_tam: tamanho.id,
      id_grupos: produto.id_grupos,
      qtd: qtd * 1000,
      observacoes: txtObs,
      adicionais: adcIngr,

      div_sabores: [],
      adicionais_individual: [],
      observacoes_individual: [],
      obs_input_individual: "",

      gp_gratis: [],
      gp_espec: gpEspecSelected,
      valor: valorTotal,
      valor_editavel: valorTotal / qtd,
      nomeProduto,
      img: produto.img.length > 0 ? produto.img : "https://www.dcifood.com.br/api/logos/sem_imagem.jpg"
    }

    listNewPedido.push(ped);
    gravaPedidos(listNewPedido);
    closeModal(true);
  }

  const ajustaDivAdc = () => {
    const container = document.getElementById("containerAdc");
    const content = document.getElementById("contentAdc");

    if (container && content && openAdc) {
      container.style.height = content.clientHeight < 50 ? "240px" : `${(content.clientHeight)}px`;
      container.style.opacity = "1";
    }
  }

  const ajustaDivObs = () => {
    const container = document.getElementById("containerObs");
    const content = document.getElementById("contentObs");

    if (container && content && openObs) {
      container.style.height = content.clientHeight < 50 ? "240px" : `${content.clientHeight}px`;
      container.style.opacity = "1";
    }
  }

  const handleBtnMaisGpEsp = (gpClicked: GpEspecialProps, prod: GpEspecProdProps) => {
    const tempList: GpEspecialProps[] = [];

    gpEspec.map((rs) => {
      let Total = 0;

      rs.produtos
        .filter((i) => i.qtd_selecionada && i.qtd_selecionada > 0)
        .map((item) => {
          Total += item.qtd_selecionada ? item.qtd_selecionada : 0;
        });

      const newLista = rs.produtos.map((item) => {
        item.qtd_selecionada = item.qtd_selecionada ? item.qtd_selecionada : 0;
        prod.qtd_selecionada = prod.qtd_selecionada ? prod.qtd_selecionada : 0;

        if (
          rs.id === gpClicked.id &&
          item.id === prod.id &&
          (prod.limite === 0 || prod.limite > prod.qtd_selecionada) &&
          (gpClicked.limite == 0 || Total < gpClicked.limite)
        ) {
          return { ...item, qtd_selecionada: item.qtd_selecionada + 1 };
        } else {
          return item;
        }
      });

      tempList.push({ ...rs, produtos: newLista });
    });

    setGpEspec(tempList);
  }

  const handleBtnMenosGpEsp = (gpClicked: GpEspecialProps, prod: GpEspecProdProps) => {
    const tempList: GpEspecialProps[] = [];

    gpEspec.map((rs) => {
      const newLista = rs.produtos.map((item) => {
        item.qtd_selecionada = item.qtd_selecionada ? item.qtd_selecionada : 0;

        if (rs.id === gpClicked.id && item.id === prod.id) {
          return { ...item, qtd_selecionada: item.qtd_selecionada - 1 };
        } else {
          return item;
        }
      });

      tempList.push({ ...rs, produtos: newLista });
    });

    setGpEspec(tempList);
  }

  useEffect(() => {
    setIngr(produto.ingredientes);
    setListAdc([]);
    setListObs([]);
    setQtd(1);
    handleBtnObs();
  }, [produto]);

  useEffect(() => {
    if (listAdc.length <= 0) {
      const tempListAdc: GpAdicionaisProps[] = [];

      adicionais?.filter(i => i.id_grupos === produto.id_grupos).forEach((rs) => {
        rs.qtd_selecionada = 0;
        tempListAdc.push(rs);
      });

      setListAdc(tempListAdc);
    }
  }, [adicionais]);

  useEffect(() => {
    if (listObs.length <= 0) {
      const tempListObs: GpObservacoesProps[] = [];

      observacoes?.filter(i => i.id_grupos === produto.id_grupos).forEach((rs) => {
        rs.selecionado = false;
        tempListObs.push(rs);
      });

      setListObs(tempListObs);
    }
  }, [observacoes]);

  useEffect(() => {
    ajustaDivAdc();
  }, [listAdc]);

  useEffect(() => {
    ajustaDivObs();
  }, [listObs]);

  useEffect(() => {
    loadGpEspec();
  }, []);

  return (
    <div id="ModalProdutoQtd">
      <div className="header" style={{ backgroundColor: configCores.cor_1 ? configCores.cor_1 : "#ff2b08", color: configCores.cor_fonte ? configCores.cor_fonte : "#FFF" }}>
        <h3>{grupo.nome}</h3>
        <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer" }} onClick={() => closeModal()} />
      </div>
      <div className="content">
        <div className="img-container">
          <div className="img" style={{ backgroundImage: produto.img.length > 0 ? `url("${produto.img}")` : 'url("https://www.dcifood.com.br/api/logos/sem_imagem.jpg")' }} />
          <Quantidade Qtd={qtd} btnMais={handleBtnMais} btnMenos={handleBtnMenos} limite={-1} requestWidth={120} />
        </div>
        <div className="info">
          <h3 style={{ color: configCores?.cor_3 }}>{produto.nome} - {currencyFormatter(tamanho.valor / 100)} </h3>
          {produto.ingredientes.length > 0 && (
            <>
              <p>Ingredientes: <small>Desative as opções abaixo para retirar o ingrediente</small></p>
              <div className="listIngredientes">
                {ingr.map((rs) => {
                  return (
                    <label key={`i-${rs.id}`} className="ck-container">
                      {viewIngrediente(rs, tamanho)}
                      <input
                        id={`ing-${rs.id_ingredientes}`}
                        type="checkbox"
                        name="ck-ingredientes"
                        value={rs.ingrediente}
                        checked={rs.selecionado}
                        onChange={() => { handleIngrediente(rs) }} />
                      <span className="checkmark" />
                    </label>
                  );
                })}
              </div>
            </>
          )}

          {grupo.observacao && grupo.observacao.length > 0 && (
            <>
              <p>Observação:</p>
              <label>{grupo.observacao}</label>
            </>
          )}

          <div className="containerBtn">
            <button className="btnObsAdc" style={{ backgroundColor: "#e67e22" }} onClick={handleBtnAdc}>
              <FontAwesomeIcon icon={faPlus} />
              Adicionar Ingredientes
            </button>
            <button className="btnObsAdc" style={{ backgroundColor: "#004276" }} onClick={handleBtnObs}>
              <FontAwesomeIcon icon={faComments} />
              Observações
            </button>
          </div>

          <div id="containerAdc">
            <div id="contentAdc">
              <h4>Adicionais</h4>
              <div style={{ padding: "0px 15px" }}>
                {loadAdc && (
                  <Load txtLoading="Carregando lista de adicionais..." options={{ width: 120, height: 120 }} />
                )}

                {!loadAdc && (
                  listAdc.map((rs) => {
                    return (
                      <div key={`adc-${rs.id}`} className="adcItemContainer">
                        <Quantidade Qtd={rs.qtd_selecionada ? rs.qtd_selecionada : 0} btnMais={() => handleAdc(rs, "mais")} btnMenos={() => handleAdc(rs, "menos")} limite={-1} requestWidth={120} />
                        <span className="itemName">
                          {rs.ingrediente} - {currencyFormatter(rs.valor / 100)}
                        </span>
                      </div>
                    );
                  })
                )}

              </div>

            </div>
          </div>

          <div id="containerObs">
            <div id="contentObs">
              <h4>Observações</h4>
              <div style={{ padding: "15px" }}>
                {loadObs && (
                  <div style={{ marginBottom: "25px" }}>
                    <Load txtLoading="Carregando lista de observações..." options={{ width: 120, height: 120 }} />
                  </div>
                )}

                {!loadObs && (
                  listObs.map((rs) => (
                    <label key={`adc-${rs.id}`} className="ck-container">
                      {rs.descricao}
                      <input
                        id={`obs-${rs.id}`}
                        type="checkbox"
                        name="ck-adc"
                        value={rs.descricao}
                        checked={rs.selecionado}
                        onChange={() => handleObs(rs)}
                      />
                      <span className="checkmark" />
                    </label>
                  ))
                )}

                <textarea
                  placeholder="Digite aqui sua observação"
                  rows={5}
                  value={observacao}
                  onChange={e => setObservacao(e.target.value)}
                />
              </div>
            </div>
          </div>

          {loadingGpEspec && (
            <Load txtLoading="Carregando acompanhamentos..." options={{ width: 120, height: 120 }} />
          )}

          {!loadingGpEspec && (
            <div className="listGpEspec">
              {gpEspec.map((rs) => {
                return (
                  <div key={`gpe-${rs.id}`}>
                    <hr />
                    <h3>{rs.descricao}{rs.minimo > 0 ? ` - Mínimo: ${rs.minimo}` : ""}{rs.limite > 0 ? ` - Limite: ${rs.limite}` : ""} </h3>

                    {rs.produtos.map((item) => (
                      <div className="GpEspec" key={`gpel-${item.id}`}>
                        <Quantidade
                          Qtd={item.qtd_selecionada ? item.qtd_selecionada : 0}
                          limite={item.limite > 0 ? item.limite : -1}
                          requestWidth={100}
                          btnMais={() => { handleBtnMaisGpEsp(rs, item) }}
                          btnMenos={() => { handleBtnMenosGpEsp(rs, item) }}
                        />

                        <p className="infoGpEspec">
                          {item.nome} {item.limite > 0 ? ` - Limite: ${item.limite}` : ""}
                          <span><br />{currencyFormatter(item.valor / 100)}</span>
                        </p>
                      </div>
                    ))}

                  </div>
                );
              })}
              <hr />
            </div>
          )}

          {showBtnComprar && caixaAberto && (
            <button className="btnComprar" onClick={handleComprar}>
              <FontAwesomeIcon icon={faShoppingCart} />
              Comprar
            </button>
          )}

          {!caixaAberto && (
            <div className="aviso">
              <FontAwesomeIcon icon={faExclamationCircle} style={{ fontSize: "60px", color: "#cd240e" }} />
              <div>
                <h3>Delivery online fechado!</h3>
                <p>Você poderá navegar normalmente, só não poderá realizar compras.</p>
              </div>
            </div>
          )}

        </div>
      </div>
    </div>
  );
}