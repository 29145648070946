import { AxiosInstance } from "axios"
import { apiAws } from "../services/api"
import { apiAdmAws } from "../services/apiAdm"
import { apiControleAws } from "../services/apiControle"

export const getApiClient = (_: string): AxiosInstance => {
  return apiAws
}

export const getApiAdmClient = (_: string): AxiosInstance => {
  return apiAdmAws
}

export const getApiControleClient = (_: string): AxiosInstance => {
  return apiControleAws
}