import Lottie from "lottie-react-web"

import loading_line from "../../assets/loading_line.json";

import "./style.css";

type OptionProps = {
  width: number;
  height: number;
}

type ParamsProps = {
  options?: OptionProps;
}

export function LoadLine({ options }: ParamsProps) {
  return (
    <div id="comp-load-line">
      <Lottie options={{ animationData: loading_line }} width={options ? options.width : 50} height={options ? options.height : 50} />
    </div>
  );
}