import { useEffect, useState } from "react";
import { MesaDetalhesPedidosProps } from "../../global/types";
import { currencyFormatter } from "../../utils/currencyFormatter";

import "./style.css";

type ParamsProps = {
  pedido: MesaDetalhesPedidosProps;
}

export function ItemProdutoComanda({ pedido }: ParamsProps) {

  const [nomeProduto, setNomeProduto] = useState("");

  useEffect(() => {
    if (!pedido.id)
      return;

    if (pedido.has_comb) {
      const [tamanho, produtos] = pedido.produto.split("::");

      let tempNome = "";
      produtos.split("||")?.map((item) => {
        tempNome += `${item} \n`;
      });

      setNomeProduto(`${tamanho} \n ${tempNome}`);
    } else
      setNomeProduto(`${pedido.produto} ${pedido.tamanho}`);

  }, [pedido]);

  return (
    <>
      <div className="itemProdutoComanda">
        <div className="contentItem">
          <p style={{ width: "100%" }}>{nomeProduto}</p>
          <p>{pedido.qtd / 1000}</p>
          <p style={{ width: "100px", textAlign: "right" }}>{currencyFormatter((pedido.valor * (pedido.qtd / 1000)) / 100)}</p>
        </div>
      </div>
      <hr />
    </>
  );
}