import React, { useState } from "react"
import { IClientesCtrl } from "../../pages/ControleHome"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons"
import { LoadAdm } from "../LoadAdm"
import { Input } from "../Input"
// import { api } from "../../services/api"

import "./style.css"
import { EmpresaProps } from "../../global/types"
import { getApiClient } from "../../utils/getApiClient"

type IProps = {
  closeModal: () => void
  UpdateInfoCliente: (item: IClientesCtrl) => void
  cliente: IClientesCtrl | undefined
}

export function ModalControleEditInfo({ closeModal, cliente, UpdateInfoCliente }: IProps) {

  const [loading, setLoading] = useState(false)
  const [txtLoading, setTxtLoading] = useState("")

  const [mesas, setMesas] = useState(cliente?.config?.qtd_mesa ? `${cliente?.config?.qtd_mesa}` : "")
  const [celulares, setCelulares] = useState(cliente?.config?.qtd_mobile ? `${cliente?.config?.qtd_mobile}` : "")

  const handleSalve = async () => {
    if (!cliente)
      return

    setLoading(true)
    setTxtLoading("Salvando informações")

    await getApiClient(cliente?.cod_cliente).post(`${cliente?.cod_cliente}/saveInfoControle`, {
      qtd_mesa: mesas,
      qtd_mobile: celulares
    })
      .then(res => {
        if (!res.data)
          throw new Error("Servidor indisponível");

        const newConfig: EmpresaProps = cliente.config ? { ...cliente.config, qtd_mesa: parseInt(mesas), qtd_mobile: parseInt(celulares) } : {} as EmpresaProps

        UpdateInfoCliente({ ...cliente, config: newConfig })
      })
      .catch(err => {
        if (err.response?.data?.msg)
          alert(err.response.data.msg)
        else if (err.message)
          alert(err.message)
        else
          alert("Erro ao salvar dados do cliente. Tente novamente mais tarde")
      })
      .finally(() => {
        setLoading(false)
        setTxtLoading("")
        closeModal()
      })
  }

  return (
    <div id="mdControleEditInfo">
      <div className="header">
        <h3>Editar Informações</h3>
        <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer" }} onClick={closeModal} />
      </div>

      {loading && (
        <LoadAdm txtLoading={txtLoading} options={{ height: 150, width: 150 }} />
      )}

      <div className="content">

        {!loading && cliente?.config?.fantasia && (
          <>
            <h3 style={{ width: "100%" }}>{cliente.nome}</h3>
            <div>
              <span>
                <p style={{ fontWeight: "600" }}>Mesas:</p>
                <Input
                  mask="none"
                  value={mesas}
                  inputMaskChange={setMesas}
                  type="tel"
                />
              </span>
              <span>
                <p style={{ fontWeight: "600" }}>Celulares:</p>
                <Input
                  mask="none"
                  value={celulares}
                  inputMaskChange={setCelulares}
                  type="tel"
                />
              </span>
            </div>

            <button type="button" onClick={() => handleSalve()}>
              <FontAwesomeIcon icon={faSave} />
              SALVAR
            </button>
          </>
        )}

      </div>

    </div>
  )
}