import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyBs3OfwLBaHWpb5_nzlPmb3QASZkoJGTcw",
  authDomain: "dcifood.firebaseapp.com",
  databaseURL: "https://dcifood.firebaseio.com",
  projectId: "dcifood",
  storageBucket: "dcifood.appspot.com",
  messagingSenderId: "88571540384",
  appId: "1:88571540384:web:7729066e8d333b29feeac5",
  measurementId: "G-Y2LHHZ0EV6"
};

const firebase = initializeApp(firebaseConfig);
const database = getDatabase(firebase);
export default database;