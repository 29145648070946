import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { currencyFormatter } from "../../utils/currencyFormatter";
import { Quantidade } from "../Quantidade";

import "./style.css";

type ParamsProps = {
  total: number;
  closeModal: () => void;
  confirma: (dividir: number) => void;
}

export function ModalDivisaoComanda({ total, closeModal, confirma }: ParamsProps) {
  const [qtd, setQtd] = useState(1);

  const handleFinalizar = () => {
    if (!window.confirm("Deseja realmente fechar nessa comanda?"))
      return;

    if (qtd > 0)
      confirma(qtd);
  }

  const handleBtnMais = () => {
    setQtd((e) => e + 1);
  }

  const handleBtnMenos = () => {
    if (qtd > 1)
      setQtd((e) => e - 1);
  }

  return (
    <div id="ModalModalDivisaoComanda">
      <div className="header">
        <h3>FECHAMENTO DE COMANDA</h3>
        <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer" }} onClick={closeModal} />
      </div>

      <div className="content">
        <p style={{ fontWeight: "600" }}>TOTAL A PAGAR: {currencyFormatter(total)}</p>
        <p style={{ marginBottom: "10px" }}>Dividir comanda para quantas pessoas?</p>

        <Quantidade Qtd={qtd} btnMais={() => handleBtnMais()} btnMenos={() => handleBtnMenos()} limite={-1} requestWidth={120} />
        <p>{currencyFormatter(total / qtd)} por pessoa</p>

        <button type="button" onClick={handleFinalizar} className="btnEnviar">
          <FontAwesomeIcon icon={faCheck} />
          ENVIAR
        </button>
      </div>

    </div>
  )
}