import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faSave } from "@fortawesome/free-solid-svg-icons"

import { Load } from "../Load";
import { Input } from "../Input";
import { useCache } from "../../hooks/useCache";
import { BairroProps, EmpresaParams, EnderecoClienteProps } from "../../global/types";
// import { api } from "../../services/api";

import "./style.css";
import { getApiClient } from "../../utils/getApiClient";

type ParamsProps = {
  confirma: (idNewEndereco: number) => void;
  closeModal: () => void;
  idCliente: number;
  nomeCliente: string;
}

export function NovoEndereco({ closeModal, idCliente, confirma, nomeCliente }: ParamsProps) {
  const { configCores, empresa } = useCache();
  const { codCliente } = useParams() as EmpresaParams;

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [endereco, setEndereco] = useState("");
  const [numero, setNumero] = useState("");
  const [bairro, setBairro] = useState("");
  const [complemento, setComplemento] = useState("");
  const [idBairro, setIdBairro] = useState("");

  const [listBairros, setListBairros] = useState<BairroProps[]>([])

  const [mostraTxtBairo, setMostraTxtBairro] = useState(true);
  const [mostraCbBairro, setMostraCbBairro] = useState(false);

  const handleCadastrar = async () => {
    if (endereco.length === 0 || numero.length === 0)
      return alert("Preencha todos os campos obrigatórios");

    if (empresa.dif_entrega_bairro === 1 && idBairro.length === 0) {
      alert("Selecione um Bairro")
      return
    }

    if (empresa.dif_entrega_bairro === 0 && bairro.length === 0) {
      alert("Preencha o Bairro")
      return
    }

    const newEndereco: EnderecoClienteProps = {
      id: idCliente,
      endereco: endereco,
      numero: parseInt(numero),
      bairro: bairro,
      complemento: complemento,
      padrao: 0,
      id_bairro: idBairro.length > 0 ? parseInt(idBairro) : 0
    };

    setLoading(true);
    setTxtLoading("Salvando endereço...");
    let idNewEndereco = -1;
    await getApiClient(codCliente)
      .post(`${codCliente}/clientes_endereco`, { ...newEndereco })
      .then((res) => {
        if (!res.data) throw new Error("Servidor indisponível");

        setEndereco("");
        setNumero("");
        setBairro("");
        setComplemento("");
        idNewEndereco = res.data.id;
      })
      .catch(() => {
        alert("Erro ao cadastrar endereço. Tente novamente mais tarde");
      })
      .finally(() => {
        setLoading(false);
        confirma(idNewEndereco);
      });
  }

  const loadBairros = useCallback(async () => {
    setLoading(true)
    setTxtLoading("Carregando lista de bairros...")

    await getApiClient(codCliente).get(`${codCliente}/bairros`)
      .then((res) => {
        if (!res.data)
          throw new Error("Servidor indisponível");

        setListBairros(res.data)
      })
      .catch((err) => {
        if (err.response?.data?.msg)
          alert(err.response.data.msg);
        else if (err.message)
          alert(err.message);
        else
          alert("Erro ao carregar lista de bairros. Tente novamente mais tarde");
      })
      .finally(() => {
        setLoading(false)
        setTxtLoading("")
      })

  }, [])

  useEffect(() => {
    if (empresa.dif_entrega_bairro === 1) {
      setMostraCbBairro(true)
      setMostraTxtBairro(false)
      loadBairros()
    } else {
      setMostraTxtBairro(true)
      setMostraCbBairro(false)
    }
  }, [loadBairros])

  return (
    <div id="ModalNovoEndereco">
      <div className="header" style={{ backgroundColor: configCores?.cor_1, color: configCores?.cor_fonte }}>
        <h3>Novo Endereço</h3>
        <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer" }} onClick={closeModal} />
      </div>

      <div className="content">

        {loading && (
          <Load txtLoading={txtLoading} options={{ width: 120, height: 120 }} />
        )}

        {!loading && (
          <>
            <p>Endereço:</p>
            <Input
              value={endereco}
              mask="none"
              maxLength={100}
              placeholder="Digite o Endereço"
              inputMaskChange={setEndereco}
            />

            <p>Número:</p>
            <Input
              value={numero}
              mask="none"
              maxLength={100}
              placeholder="Digite o Número"
              inputMaskChange={setNumero}
              type="number"
              min={0}
            />

            <p>Bairro:</p>
            {mostraTxtBairo && (
              <Input
                value={bairro}
                mask="none"
                maxLength={100}
                placeholder="Digite o Bairro"
                inputMaskChange={setBairro}
              />
            )}

            {mostraCbBairro && (
              <select value={idBairro} onChange={(e) => setIdBairro(e.target.value)}>
                <option value={0}></option>
                {listBairros.map(item => (
                  <option
                    key={`${item.id}`}
                    value={`${item.id}`}
                  >
                    {item.nome}
                  </option>
                ))}
              </select>
            )}

            <p>Complemento:</p>
            <Input
              value={complemento}
              mask="none"
              maxLength={100}
              placeholder="Digite o Complemento"
              inputMaskChange={setComplemento}
            />

            <button type="button" onClick={handleCadastrar}>
              <FontAwesomeIcon icon={faSave} />
              CADASTRAR
            </button>
          </>
        )}

      </div>

    </div>
  );
}