import { KeyboardEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faPaperPlane, faTimes } from "@fortawesome/free-solid-svg-icons";
import { dynamicSort } from "../../utils/dynamicSort";
import { Load } from "../../components/Load";
import { LoadAdm } from "../../components/LoadAdm";
import { AdmUserEmpresas, AdmUserEmpresasMesas, AtendimentosGarcomProps, ChatProps, DeliveryProps, FirebaseDelivery, FirebaseMesas } from "../../global/types";
import { useCache } from "../../hooks/useCache";
// import { api } from "../../services/api";
// import { apiAdm } from "../../services/apiAdm";
import { currencyFormatter } from "../../utils/currencyFormatter";
import bgChat from "../../assets/bg_chat.jpg";
import { dateNow, convertToUTC, convertToUs, getHourMin, convertDayJs } from "../../utils/dateProvider";
import Modal from "react-modal";

import dbFirebase from "../../services/firebase";
import { ref, set, push, remove, onChildAdded, } from "firebase/database";

import "./style.css";
import { ModalAdmRelVendasMensal } from "../../components/ModalAdmRelVendasMensal";
import { ModalAdmRelVendasProdutos } from "../../components/ModalAdmRelVendasProdutos";
import { ModalAdmRelVendas } from "../../components/ModalAdmRelVendas";
import { getApiAdmClient, getApiClient } from "../../utils/getApiClient";

type IListEmpresas = {
  index: number;
  indexChanges: number;
  list: AdmUserEmpresas[];
  recursivo: boolean;
  fbChange: IChanges[];
}

type IChanges = {
  tipo: string;
  codCliente: string;
  changes: FirebaseMesas | FirebaseDelivery;
}

type IChatMsgs = {
  msgs: ChatProps[];
}

const styleModal = {
  content: {
    padding: "0",
    width: 400,
    height: 360,
    borderRadius: "10px",
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)"
  }
};

export function AdmHome() {
  const navigate = useNavigate();

  const { admUser, verificaAdmUserLogado } = useCache();

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [firstRun, setFirstRun] = useState(true);

  const [listEmpresas, setListEmpresas] = useState<IListEmpresas>({ index: 0, list: [], recursivo: true, indexChanges: 0, fbChange: [] });
  const [inputChatMsg, setInputChatMsg] = useState<string[]>([]);

  const [modalRelVendasMensal, setModalRelVendasMensal] = useState(false);
  const [modalRelVendasProdutos, setModalRelVendasProdutos] = useState(false);
  const [modalRelVendas, setModalRelVendas] = useState(false);

  const initializeFirebase = () => {
    if (!firstRun) return;

    listEmpresas.list.forEach((item, i) => {
      const dbRealTimeMesas = ref(dbFirebase, item.codCliente);
      const dbRealTimeDelivery = ref(dbFirebase, `${item.codCliente}_delivery`);
      const dbRealTimeChat = ref(dbFirebase, `${item.codCliente}_chat`);

      onChildAdded(dbRealTimeMesas, (snapshot) => {
        const data = snapshot.val() as FirebaseMesas;
        fbAtt(i, data, "mesas");
      });

      onChildAdded(dbRealTimeDelivery, (snapshot) => {
        const data = snapshot.val();

        fbAtt(i, data, "delivery");

        remove(ref(dbFirebase, `${item.codCliente}_delivery/${snapshot.key}`));
      })

      onChildAdded(dbRealTimeChat, (snapshot) => {
        const data = snapshot.val();

        newMsg(i, data);
      })

    });

    setFirstRun(false);
  }

  const fbAtt = (indexEmpresa: number, item: FirebaseMesas, tipo: string) => {
    const newItem: IChanges = {
      codCliente: listEmpresas.list[indexEmpresa].codCliente,
      changes: item,
      tipo
    };

    const empresa = listEmpresas.list.find(i => i.codCliente == listEmpresas.list[indexEmpresa].codCliente);
    const dateItem = new Date(item.data_hora);

    if (!empresa) return;
    if (dateItem < empresa.ultAttMesa) return;

    setListEmpresas(e => ({ ...e, fbChange: ([...e.fbChange, newItem]) }));
  }

  const applyChangesDelivery = async () => {
    const mudancas = listEmpresas.fbChange[listEmpresas.indexChanges];
    const empresa = listEmpresas.list.find(i => i.codCliente == mudancas.codCliente);
    const item = mudancas.changes as FirebaseDelivery;
    const dateItem = new Date(item.data_hora);

    const newListEmpresas: AdmUserEmpresas[] = [];
    const newEmpresaDelivery: DeliveryProps[] = [];

    if (!empresa) return;
    if (dateItem < empresa.ultAttMesa) return;

    let
      addTelefone = 0,
      addWeb = 0,
      addEntrega = 0,
      addRetirada = 0,
      addValorTelefone = 0,
      addValorWeb = 0,
      addValorEntrega = 0,
      addValorRetirada = 0;

    if (item.acao === "add") {
      await getApiClient(empresa.codCliente).get(`${empresa.codCliente}/delivery?id=${item.id_delivery}`)
        .then((response) => {
          const newDelivery = response.data as DeliveryProps;
          const total = (parseFloat(`${newDelivery.total}`) + parseFloat(`${newDelivery.taxa_entrega}`)) / 100;

          addTelefone = newDelivery.by_web === 0 ? 1 : 0;
          addWeb = newDelivery.by_web;
          addEntrega = newDelivery.id_clientes_enderecos > 0 ? 1 : 0;
          addRetirada = newDelivery.id_clientes_enderecos > 0 ? 0 : 1;

          addValorTelefone = newDelivery.by_web === 0 ? total : 0;
          addValorWeb = newDelivery.by_web === 1 ? total : 0;
          addValorEntrega = newDelivery.id_clientes_enderecos > 0 ? total : 0;
          addValorRetirada = newDelivery.id_clientes_enderecos > 0 ? 0 : total;

          newEmpresaDelivery.push(newDelivery);
          empresa.delivery?.forEach(rs => {
            newEmpresaDelivery.push(rs);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (item.acao === "edit") {
      empresa.delivery?.forEach(async (rs) => {
        newEmpresaDelivery.push({
          ...rs,
          status: parseInt(`${rs.id}`) === parseInt(`${item.id_delivery}`) ? item.status : rs.status,
          motivo: parseInt(`${rs.id}`) === parseInt(`${item.id_delivery}`) ? item.motivo : rs.motivo
        });
      });
    }

    listEmpresas.list.forEach(rsList => {
      if (empresa.codCliente === rsList.codCliente) {
        newListEmpresas.push({
          ...rsList,
          delivery: newEmpresaDelivery,
          ultAttMesa: dateItem,
          qtdDeliveryTelefone: parseInt(`${rsList.qtdDeliveryTelefone}`) + parseInt(`${addTelefone}`),
          qtdDeliveryWeb: parseInt(`${rsList.qtdDeliveryWeb}`) + parseInt(`${addWeb}`),
          qtdDeliveryEntrega: parseInt(`${rsList.qtdDeliveryEntrega}`) + parseInt(`${addEntrega}`),
          qtdDeliveryRetirada: parseInt(`${rsList.qtdDeliveryRetirada}`) + parseInt(`${addRetirada}`),
          totalDeliveryTelefone: parseFloat(`${rsList.totalDeliveryTelefone}`) + parseFloat(`${addValorTelefone}`),
          totalDeliveryWeb: parseFloat(`${rsList.totalDeliveryWeb}`) + parseFloat(`${addValorWeb}`),
          totalDeliveryEntrega: parseFloat(`${rsList.totalDeliveryEntrega}`) + parseFloat(`${addValorEntrega}`),
          totalDeliveryRetirada: parseFloat(`${rsList.totalDeliveryRetirada}`) + parseFloat(`${addValorRetirada}`),
        });
      } else {
        newListEmpresas.push(rsList);
      }
    })

    setListEmpresas(e => ({
      ...e,
      list: newListEmpresas,
      indexChanges: e.indexChanges + 1
    }));
  }

  const applyChangesMesas = () => {
    const mudancas = listEmpresas.fbChange[listEmpresas.indexChanges];
    const empresa = listEmpresas.list.find(i => i.codCliente == mudancas.codCliente);
    const item = mudancas.changes as FirebaseMesas;
    const dateItem = new Date(item.data_hora);

    if (!empresa) return;
    if (dateItem < empresa.ultAttMesa) return;

    let totalConsumo = 0;
    const newEmpresaMeses: AdmUserEmpresasMesas[] = [];
    const newListEmpresas: AdmUserEmpresas[] = [];

    let mesaEncontrada = false;
    let mesaConcluida = 0;
    let newPgtos = 0;

    empresa.mesas?.forEach((rs) => {
      if (parseInt(`${rs.id}`) === parseInt(`${item.id_mesa}`)) {
        mesaEncontrada = true;
        if (item.status >= 4) {
          mesaConcluida = item.status === 4 ? 1 : 0;
          newPgtos += item.status === 4 ? parseFloat(`${item.valor_pgto}`) : 0;
        } else {
          let valorAberto = parseFloat(`${rs.valor_aberto}`);

          valorAberto += parseFloat(`${item.total_pedido}`);
          valorAberto -= parseFloat(`${item.valor_pgto}`);

          newPgtos += parseFloat(`${item.valor_pgto}`);

          totalConsumo += parseFloat(`${valorAberto}`) / 100;
          newEmpresaMeses.push({ ...rs, valor_aberto: valorAberto, status: item.status, nome: item.nome, mesa: item.mesa });
        }
      } else {
        totalConsumo += parseFloat(`${rs.valor_aberto}`) / 100;
        newEmpresaMeses.push(rs);
      }
    });

    if (!mesaEncontrada && item.status < 4) {
      totalConsumo += parseFloat(`${item.total_pedido}`) / 100;
      newEmpresaMeses.push({
        id: item.id_mesa,
        hora_abertura: "",
        gd_solicitacoes: 0,
        mesa: item.mesa,
        nome: item.nome,
        status: item.status,
        valor_aberto: item.total_pedido
      });
    }

    listEmpresas.list.forEach((rsList) => {
      if (empresa.codCliente === rsList.codCliente) {
        const newListAtendimentos: AtendimentosGarcomProps[] = [];
        rsList.atendimentos?.forEach((rsAt) => {
          if (rsAt.nome === item.funcionario)
            newListAtendimentos.push({
              ...rsAt,
              qtd: item.gp_pedidos && item.gp_pedidos !== null ? parseFloat(`${rsAt.qtd}`) + 1 : rsAt.qtd
            });
          else
            newListAtendimentos.push(rsAt);
        });

        newListEmpresas.push({
          ...rsList,
          mesasCarregadas: true,
          mesas: newEmpresaMeses,
          totalMesaConsumo: totalConsumo,
          ultAttMesa: dateItem,
          qtdMesaConcluida: rsList.qtdMesaConcluida ? rsList.qtdMesaConcluida + mesaConcluida : 0,
          totalMesaConcluida: rsList.totalMesaConcluida ? rsList.totalMesaConcluida + newPgtos : 0,
          atendimentos: newListAtendimentos
        });
      } else
        newListEmpresas.push(rsList);
    });

    setListEmpresas(e => ({
      ...e,
      list: newListEmpresas,
      indexChanges: e.indexChanges + 1
    }));
  }

  const verificaLogin = () => {
    const check = verificaAdmUserLogado();

    if (!check)
      navigate("/adm/login");
  }

  const loadEmpresas = async () => {
    setLoading(true);
    setTxtLoading("Carregando Empresas...");
    document.title = `dciFood - ${admUser.nome}`;

    try {
      // HOSTGATOR - ** MUDAR **
      const requestEmpresas = await getApiAdmClient("hostgator").post("empresas", { idUsuario: admUser.id })

      if (!requestEmpresas.data) throw new Error("Erro ao carregar informações do servidor");

      const newList: AdmUserEmpresas[] = [];
      const newListInputChatMsg: string[] = [];
      const newListMsgs: IChatMsgs[] = [];

      requestEmpresas.data.forEach((item: any) => {
        newList.push({ ...item, ultAttMesa: new Date() });
        newListInputChatMsg.push("");
        newListMsgs.push({ msgs: [] });
      });

      setListEmpresas({ index: 0, list: newList, recursivo: true, fbChange: [], indexChanges: 0 });
      setInputChatMsg(newListInputChatMsg);
      setLoading(false);
      setTxtLoading("");
    } catch (err: any) {
      console.log(err.response);
      if (err.response?.data?.msg && err.response.data.msg === "Token inválido" || err.response.data.msg == "Senha incorreta")
        navigate("/adm/login");
      else
        alert(`Erro ao carregar Empresa. Tente novamente mais tarde`);
    }
  };

  const loadInformacoes = async () => {

    const empresa = listEmpresas.list[listEmpresas.index];

    try {

      const newListEmpresas: AdmUserEmpresas[] = [];
      const newEmpresaMeses: AdmUserEmpresasMesas[] = [];
      const newEmpresaDelivery: DeliveryProps[] = [];

      if (!empresa.mesasCarregadas) {
        const mesas = await getApiClient(empresa.codCliente).get(`${empresa.codCliente}/mesas`);

        if (!mesas.data) throw new Error("Erro ao recuperar resposta do servidor.");

        let totalMesas = 0;
        mesas.data.list.forEach((rs: AdmUserEmpresasMesas) => {
          totalMesas += rs.valor_aberto / 100;
          if (rs.id > 0)
            newEmpresaMeses.push(rs);
        });

        listEmpresas.list.forEach((rsList) => {
          if (empresa.codCliente === rsList.codCliente)
            newListEmpresas.push({ ...rsList, mesasCarregadas: true, mesas: newEmpresaMeses, totalMesaConsumo: totalMesas, ultAttMesa: new Date() });
          else
            newListEmpresas.push(rsList);
        });

        setListEmpresas({
          index: listEmpresas.index,
          list: newListEmpresas,
          recursivo: listEmpresas.recursivo,
          fbChange: [],
          indexChanges: 0
        });
      } else if (!empresa.deliveryCarregados) {
        const delivery = await getApiClient(empresa.codCliente).get(`${empresa.codCliente}/delivery`);

        if (!delivery.data) throw new Error("Erro ao recuperar resposta do servidor.");

        let
          totalDelivery = 0,
          qtdRetirada = 0,
          qtdEntrega = 0,
          qtdTelefone = 0,
          qtdWeb = 0,
          totalTelefone = 0,
          totalWeb = 0,
          totalRetirada = 0,
          totalEntrega = 0;

        delivery.data.forEach((rs: DeliveryProps) => {
          const total = (parseFloat(`${rs.total}`) + parseFloat(`${rs.taxa_entrega}`)) / 100;

          totalDelivery += total;

          qtdRetirada += rs.endereco === "Retirada no Estabelecimento" ? 1 : 0;
          qtdEntrega += rs.endereco === "Retirada no Estabelecimento" ? 0 : 1;
          qtdTelefone += rs.by_web === 1 ? 0 : 1;
          qtdWeb += rs.by_web === 1 ? 1 : 0;

          totalTelefone += rs.by_web == 1 ? 0 : total;
          totalWeb += rs.by_web == 1 ? total : 0;
          totalRetirada += rs.endereco === "Retirada no Estabelecimento" ? total : 0;
          totalEntrega += rs.endereco === "Retirada no Estabelecimento" ? 0 : total;

          newEmpresaDelivery.push(rs);
        });

        listEmpresas.list.forEach((rsList) => {
          if (empresa.codCliente === rsList.codCliente)
            newListEmpresas.push({
              ...rsList,
              deliveryCarregados: true,
              delivery: newEmpresaDelivery,
              totalDelivery,
              qtdDeliveryEntrega: qtdEntrega,
              qtdDeliveryRetirada: qtdRetirada,
              qtdDeliveryWeb: qtdWeb,
              qtdDeliveryTelefone: qtdTelefone,
              totalDeliveryEntrega: totalEntrega,
              totalDeliveryRetirada: totalRetirada,
              totalDeliveryTelefone: totalTelefone,
              totalDeliveryWeb: totalWeb
            });
          else
            newListEmpresas.push(rsList);
        });

        setListEmpresas({
          index: listEmpresas.index,
          list: newListEmpresas,
          recursivo: listEmpresas.recursivo,
          fbChange: [],
          indexChanges: 0,
        });
      } else if (!empresa.totaisCarregados) {
        const totais = await getApiClient(empresa.codCliente).get(`${empresa.codCliente}/mesas_totais_adm`);

        if (!totais.data) throw new Error("Erro ao recuperar resposta do servidor.");

        listEmpresas.list.forEach((rsList) => {
          if (empresa.codCliente === rsList.codCliente)
            newListEmpresas.push({
              ...rsList,
              totaisCarregados: true,
              atendimentos: totais.data.atendimentos,
              totalMesaConcluida: totais.data.mesasTotalConcluido,
              qtdMesaConcluida: totais.data.qtdMesasConcluidas
            });
          else
            newListEmpresas.push(rsList);
        });

        setListEmpresas({
          index: listEmpresas.recursivo ? listEmpresas.index + 1 : listEmpresas.list.length,
          list: newListEmpresas,
          recursivo: listEmpresas.recursivo,
          fbChange: [],
          indexChanges: 0
        });
      }

    } catch (err: any) {
      if (err.response?.data?.msg) {
        alert(err.response.data.msg);
        if (err.response.data.msg === "Caixa fechado") {
          const newListEmpresas: AdmUserEmpresas[] = [];

          listEmpresas.list.forEach((rsList) => {
            if (empresa.codCliente === rsList.codCliente)
              newListEmpresas.push({
                ...rsList,
                mesasCarregadas: true,
                deliveryCarregados: true,
                totaisCarregados: true,
                ultAttMesa: new Date()
              });
            else
              newListEmpresas.push(rsList);
          });

          setListEmpresas({
            index: listEmpresas.recursivo ? listEmpresas.index + 1 : listEmpresas.list.length,
            list: newListEmpresas,
            recursivo: listEmpresas.recursivo,
            fbChange: [],
            indexChanges: 0
          });
        }
      } else
        alert(`Erro ao carregar dados da empresa ${empresa.apelido}`);
    }
  };

  const handleChageInputMsg = (index: number, value: string) => {
    const newList: string[] = [];

    inputChatMsg.forEach((item, i) => {
      if (i === index)
        newList.push(value);
      else
        newList.push(item);
    });

    setInputChatMsg(newList);
  };

  const handleSendMessage = async (index: number) => {
    const empresa = listEmpresas.list[index];
    const mensagem = inputChatMsg[index].trim();
    const data_hora = `${convertToUs(convertToUTC(dateNow()))} ${getHourMin(dateNow())}:00`

    if (mensagem.length <= 0) return;

    const newList: string[] = [];
    inputChatMsg.forEach((item, i) => {
      if (i === index)
        newList.push("");
      else
        newList.push(item);
    });
    setInputChatMsg(newList);

    const dbRealTimeChat = push(ref(dbFirebase, `${empresa.codCliente}_chat`));
    await set(dbRealTimeChat, {
      user: admUser.nome,
      data_hora,
      mensagem
    });
  };

  const newMsg = (index: number, item: ChatProps) => {
    const divContext = document.getElementById(`messages-chat-${index}`);

    if (!divContext) return;

    const doc = document.createElement("div");
    doc.className = item.user === admUser.nome ? "myMsg" : "otherMsg";

    const span = document.createElement("span");
    span.textContent = `${item.user !== admUser.nome ? `${item.user} - ` : ""} ${getHourMin(convertDayJs(item.data_hora))}`;

    const p = document.createElement("p");
    p.textContent = item.mensagem;

    doc.appendChild(span);
    doc.appendChild(p);

    divContext.appendChild(doc);

    divContext.scrollTop = divContext.scrollHeight;
  };

  const handleKeyUp = (i: number, e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter")
      handleSendMessage(i);
  };

  const handleOpenMenu = () => {
    const menu = document.getElementById("admMenu");
    if (!menu) return;

    menu.style.left = "0px";
  };

  const handleCloseMenu = () => {
    const menu = document.getElementById("admMenu");
    if (!menu) return;

    menu.style.left = "-300px";
  };

  useEffect(() => {
    verificaLogin();
  }, []);

  useEffect(() => {
    if (admUser.id && admUser.id > 0)
      loadEmpresas();
  }, [admUser]);

  useEffect(() => {
    if (listEmpresas.list && listEmpresas.list.length > 0 && listEmpresas.list.length > listEmpresas.index)
      loadInformacoes();
    else if (listEmpresas.fbChange.length > 0 && listEmpresas.fbChange.length > listEmpresas.indexChanges) {
      const tipo = listEmpresas.fbChange[listEmpresas.indexChanges].tipo;
      if (tipo === "mesas")
        applyChangesMesas();
      else
        applyChangesDelivery();
    }
    else if (listEmpresas.list && listEmpresas.list.length > 0)
      initializeFirebase();
  }, [listEmpresas]);

  return (
    <div id="admHomePage">
      {loading && (
        <Load txtLoading={txtLoading} options={{ width: 300, height: 300 }} />
      )}

      {!loading && (
        <>
          <div id="admMenu">
            <div className="divClose">
              <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer" }} onClick={() => handleCloseMenu()} />
            </div>
            <h4>Relatórios</h4>
            <span onClick={() => setModalRelVendasMensal(true)}>Vendas Mensal</span>
            <span onClick={() => setModalRelVendas(true)}>Vendas</span>
            <span onClick={() => setModalRelVendasProdutos(true)}>Vendas de Produtos</span>
          </div>

          <FontAwesomeIcon icon={faBars} style={{ fontSize: "25px", marginBottom: "15px", cursor: "pointer" }} onClick={() => handleOpenMenu()} />

          {listEmpresas.list.map((item, i) => (
            <div key={`${item.id}`} className="contentEmpresa">
              <div className="tab">
                <h3>{item.apelido}</h3>
              </div>
              <div className="context">

                <div className="mesas">
                  <h4>Mesas</h4>

                  {!item.mesasCarregadas && (
                    <LoadAdm txtLoading="" options={{ width: 100, height: 100 }} />
                  )}

                  {item.mesasCarregadas && (
                    <>
                      <div className="mesasContent">
                        {item.mesas && item.mesas.sort(dynamicSort("mesa")).map((rs) => (
                          <div className="squareMesa" style={
                            {
                              background: `${rs.status === 1 ? "#dc3545" :
                                rs.status === 2 ? "#ffc107" :
                                  rs.status === 3 ? "#6c757d" :
                                    rs.status === 4 ? "#ffffff" :
                                      rs.status === 5 ? "#dc3545" :
                                        "#c0c0c0"
                                }`
                            }
                          } key={`m-${rs.id}`}>
                            <p>{rs.mesa}</p>
                            <span>{rs.nome}</span>
                            {currencyFormatter(rs.valor_aberto / 100)}
                          </div>
                        ))}
                      </div>
                      <div style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                        padding: "5px 10px",
                        textAlign: "center",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}>
                        <span style={{ flex: "1" }}>Concluídas: ({item.qtdMesaConcluida ? item.qtdMesaConcluida : " - "}) {item.totalMesaConcluida ? currencyFormatter(item.totalMesaConcluida / 100) : " - "}</span>
                        <span style={{ flex: "1" }}>Em Consumo: ({item.mesas ? item.mesas.length : " - "}) {item.totalMesaConsumo ? currencyFormatter(item.totalMesaConsumo) : " - "}</span>
                      </div>
                    </>
                  )}
                </div>

                <div className="delivery">
                  <h4>Delivery</h4>

                  {!item.deliveryCarregados && (
                    <LoadAdm txtLoading="" options={{ width: 100, height: 100 }} />
                  )}

                  {item.deliveryCarregados && (
                    <div className="deliveryContent">
                      <div className="tableDelivery">
                        <div className="header">
                          <span style={{ flex: 1 }}>Hora</span>
                          <span style={{ flex: 2 }}>Cliente</span>
                          <span style={{ flex: 2 }}>Endereço</span>
                          <span style={{ flex: 1 }}>Valor</span>
                          <span style={{ flex: 1 }}>Status</span>
                        </div>
                        <div className="body">
                          {item.delivery && item.delivery.map((rs) => (
                            <div key={`d-${rs.id}`}>
                              <div className="rowDelivery" style={
                                {
                                  background: `${rs.status === 0 ? "#94ffd4" :
                                    rs.status === 1 ? "#faebb0" :
                                      rs.status === 2 ? "#e1e0de" :
                                        rs.status === 3 ? "#ffc100" :
                                          rs.status === 4 ? "#83c3d7" :
                                            rs.status === 5 ? "#ffa6b7" : ""
                                    }`
                                }
                              }>
                                <span style={{ flex: 1 }}>{rs.hora}</span>
                                <span style={{ flex: 2 }}>{rs.cliente}</span>
                                <span style={{ flex: 2 }}>{rs.endereco}</span>
                                <span style={{ flex: 1 }}>{currencyFormatter((parseFloat(`${rs.total}`) + parseFloat(`${rs.taxa_entrega}`)) / 100)}</span>
                                <span style={{ flex: 1 }}>
                                  {
                                    rs.status === 0 ? "Realizado" :
                                      rs.status === 1 ? "Em Produção" :
                                        rs.status === 2 ? "Aguardando Entregador" :
                                          rs.status === 3 ? "Saiu para Entrega" :
                                            rs.status === 4 ? "Entregue" :
                                              rs.status === 5 ? "Cancelado" : ""
                                  }
                                </span>
                              </div>

                              {rs.status === 5 && (
                                <div className="rowDelivery" style={{ background: "#ffa6b7", padding: "3px 0", justifyContent: "center" }}>
                                  MOTIVO: {rs.motivo}
                                </div>
                              )}

                            </div>
                          ))}
                        </div>
                        <div className="footer">
                          <span>Pedidos: {item.delivery ? item.delivery.length : "-"}</span>
                          <span>Total: {item.totalDelivery ? currencyFormatter(item.totalDelivery) : "-"}</span>
                        </div>
                      </div>
                    </div>
                  )}

                </div>

                <div className="segundRow">
                  <div className="totais">
                    {!item.totaisCarregados && (
                      <LoadAdm txtLoading="" options={{ width: 100, height: 100 }} />
                    )}

                    {item.totaisCarregados && (
                      <>
                        <div className="totaisContent">
                          <h4>Atendimentos Garçons</h4>
                          {item.atendimentos && item.atendimentos.map((rs) => (
                            <div className="totaisRow" key={`a-${rs.nome}`}>
                              <span style={{ flex: 1 }}>{rs.nome}</span>
                              <span>{rs.qtd}</span>
                            </div>
                          ))}
                        </div>
                        <div className="totaisContent">
                          <h4>Delivery</h4>
                          <div className="totaisRow">
                            <span style={{ flex: 1 }}>Telefone/WhatsApp</span>
                            <span>{currencyFormatter(item.totalDeliveryTelefone)} ({item.qtdDeliveryTelefone})</span>
                          </div>
                          <div className="totaisRow">
                            <span style={{ flex: 1 }}>Web</span>
                            <span>{currencyFormatter(item.totalDeliveryWeb)} ({item.qtdDeliveryWeb})</span>
                          </div>
                          <div className="totaisRow">
                            <span style={{ flex: 1 }}>Entrega</span>
                            <span>{currencyFormatter(item.totalDeliveryEntrega)} ({item.qtdDeliveryEntrega})</span>
                          </div>
                          <div className="totaisRow">
                            <span style={{ flex: 1 }}>Retirada</span>
                            <span>{currencyFormatter(item.totalDeliveryRetirada)} ({item.qtdDeliveryRetirada})</span>
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  {item.totaisCarregados && (
                    <div className="chat">
                      <h4>Chat Online</h4>
                      <div className="chatContext" style={{ background: `url(${bgChat})`, backgroundSize: "cover" }}>
                        <div className="messages" id={`messages-chat-${i}`}></div>
                        <div className="inputMsg">
                          <input
                            value={inputChatMsg[i]}
                            onChange={(e) => handleChageInputMsg(i, e.target.value)}
                            onKeyUp={(e) => handleKeyUp(i, e)}
                          />
                          <FontAwesomeIcon icon={faPaperPlane} className="btnSend" onClick={() => handleSendMessage(i)} />
                        </div>
                      </div>
                    </div>
                  )}
                </div>

              </div>

            </div>
          ))}
        </>
      )}

      <Modal isOpen={modalRelVendasMensal} style={styleModal}>
        <ModalAdmRelVendasMensal closeModal={() => setModalRelVendasMensal(false)} empresas={listEmpresas.list} />
      </Modal>

      <Modal isOpen={modalRelVendasProdutos} style={styleModal}>
        <ModalAdmRelVendasProdutos closeModal={() => setModalRelVendasProdutos(false)} empresas={listEmpresas.list} />
      </Modal>

      <Modal isOpen={modalRelVendas} style={styleModal}>
        <ModalAdmRelVendas closeModal={() => setModalRelVendas(false)} empresas={listEmpresas.list} />
      </Modal>

    </div>
  );
}