import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faSignOutAlt, faGlobe, faBuilding } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";

import { useCache } from "../../hooks/useCache";
import { maskPhone } from "../../utils/masks";
import { Load } from "../../components/Load";
import { ModalDetalhes } from "../ModalDetalhes";

import "./style.css";
import { EmpresaParams, DeliveryProps } from "../../global/types";
// import { api } from "../../services/api";
import { currencyFormatter } from "../../utils/currencyFormatter";
import { getApiClient } from "../../utils/getApiClient";

type ParamsProps = {
  closeModal: () => void;
};

const status = ["Pedido Realizado", "Em Produção", "Aguardando Entregador", "Entregando/Retirada", "Finalizado", "Cancelado"];

const styleModal = {
  content: {
    padding: "0",
    width: window.innerWidth > 1120 ? 910 : window.innerWidth - 30,
    height: window.innerHeight - 50,
    borderRadius: "10px",
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)"
  }
};

export function ModalPedidos({ closeModal }: ParamsProps) {
  const { configCores, getIdCliente, cliente, clearCliente } = useCache();
  const { codCliente } = useParams() as EmpresaParams;

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");
  const [modalDetalhe, setModalDetalhe] = useState(false);
  const [listaDelivery, setListaDelivery] = useState<DeliveryProps[]>([]);
  const [deliverySelected, setDeliverySelected] = useState<DeliveryProps>({} as DeliveryProps);

  const refreshLista = async () => {
    setLoading(true);
    setTxtLoading("Carregando lista de pedidos...");

    const idCliente = getIdCliente();

    if (idCliente <= 0) {
      setTxtLoading("");
      setLoading(false);
      return;
    }

    await getApiClient(codCliente)
      .get(`${codCliente}/delivery?id_clientes=${idCliente}&limit=30`)
      .then((res) => {
        if (!res.data)
          throw new Error("Servidor indisponível");

        setListaDelivery(res.data);
      })
      .catch(() => {
        alert("Erro ao carregar lista de pedidos. Tente novamente mais tarde");
      })
      .finally(() => {
        setTxtLoading("");
        setLoading(false);
      });
  }

  const handleSair = () => {

    if (!window.confirm("Deseja realmente sair da sua conta?"))
      return;

    clearCliente();
    closeModal();
  };

  const handleDelivery = (item: DeliveryProps) => {
    setDeliverySelected(item);
    setModalDetalhe(true);
  }

  useEffect(() => {
    refreshLista();
  }, [cliente]);

  return (
    <div id="modal-pedidos">
      <div className="header" style={{ backgroundColor: configCores?.cor_1, color: configCores?.cor_fonte }}>
        <h3>Lista de Pedidos</h3>
        <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer" }} onClick={closeModal} />
      </div>

      <div className="content">

        {loading && (
          <Load txtLoading={txtLoading} options={{ width: 120, height: 120 }} />
        )}

        {!loading && (
          <>
            <p>
              Cliente: {cliente.nome} ( {cliente.telefone ? maskPhone(cliente.telefone) : ""} )
              <FontAwesomeIcon icon={faSignOutAlt} style={{ marginLeft: "5px", cursor: "pointer", marginTop: "2px" }} onClick={handleSair} />
            </p>

            <div className="headerList" style={{ backgroundColor: configCores?.cor_1, color: configCores?.cor_fonte }}>
              <p style={{ width: "25px" }}></p>
              <p style={{ flex: "1" }}>Data/Hora</p>
              <p style={{ flex: "1", textAlign: "right" }}>Valor</p>
              <p style={{ flex: "2" }}>Status</p>
            </div>
            <div className="contentList" style={{ borderColor: configCores?.cor_1 }}>
              {listaDelivery.map((rs) => (
                <div className="itemList" key={`llist-${rs.id}`} onClick={() => handleDelivery(rs)}>
                  <p style={{ width: "25px" }}>
                    <FontAwesomeIcon icon={rs.by_web === 1 ? faGlobe : faBuilding} style={{ fontSize: "18px" }} />
                  </p>
                  <p style={{ flex: "1" }}>{rs.data_hora}</p>
                  <p style={{ flex: "1", textAlign: "right" }}>{currencyFormatter((rs.total / 100) + (rs.taxa_entrega / 100))}</p>
                  <p style={{ flex: "2" }}>{status[rs.status]}</p>
                </div>
              ))}
            </div>

          </>
        )}
      </div>

      <Modal isOpen={modalDetalhe} style={styleModal}>
        <ModalDetalhes closeModal={() => setModalDetalhe(false)} delivery={deliverySelected} />
      </Modal>

    </div>
  );
}